import { Query } from "@lookiero/messaging.js";

class ViewProductById extends Query {
  public readonly productId: string;

  public constructor(productId: string) {
    super();
    this.productId = productId;
  }

  public messageName(): string {
    return "ViewProductById";
  }
}

export default ViewProductById;
