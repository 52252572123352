import FashionModel, { FashionModelAttributes } from "@src/core/projection/fashionModel/model/FashionModel";

type FashionModelDto = {
  id: string;
  name: string;
  attributes: {
    height?: number;
    weight?: number;
    braSize?: string;
    upperSize?: string;
    bottomSize?: string;
    hipOutline?: number;
    chestOutline?: number;
    waistOutline?: number;
    shoes?: number;
  };
};

const FashionModelFromDto = (fashionModelDto: FashionModelDto): FashionModel => ({
  id: fashionModelDto.id,
  name: fashionModelDto.name,
  attributes: fashionModelDto.attributes as FashionModelAttributes,
});

export { FashionModelFromDto as FashionModelDtoToFashionModel };
