import { DomainEvent } from "@lookiero/messaging.js";

class AvailabilityEdited extends DomainEvent {
  public transactionId: string;
  public channelName: string;

  public constructor(transactionId: string, channelName: string) {
    super();
    this.transactionId = transactionId;
    this.channelName = channelName;
  }

  public messageName(): string {
    return "AvailabilityEdited";
  }
}

export default AvailabilityEdited;
