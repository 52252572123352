import Brand from "@src/core/projection/brand/model/Brand";
import BrandView from "@src/core/projection/brand/model/BrandView";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import HttpClient from "../../../../../shared/delivery/application/HttpClient";

class HttpBrandView implements BrandView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Brand[]> {
    try {
      const response = await this.httpClient.post("/search-brands", { per_page: -1 });

      if (!response.ok) {
        throw new Error("obtener las marcas del servicio");
      }

      const { result } = await response.json();

      return result ? result.map((brand: Brand) => new Brand(brand.id, brand.origin, brand.name)) : [];
    } catch (error) {
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener las marcas del servicio");
    }
  }
}

export default HttpBrandView;
