import { useCallback } from "react";
import { useDispatch } from "@lookiero/messaging.js";
import Logout from "@src/core/domain/employee/command/logout/Logout";

interface UserLogoutFunctionResult {
  logout: () => void;
}

interface UserLogoutFunction {
  (): UserLogoutFunctionResult;
}

const useLogout: UserLogoutFunction = () => {
  const dispatch = useDispatch();

  const logout = useCallback(async () => {
    await dispatch(new Logout());
  }, [dispatch]);

  return { logout };
};

export default useLogout;
