import { EventBus } from "@lookiero/messaging.js";
import FashionLabsModel from "@src/core/domain/fashionLabsModel/model/FashionLabsModel";
import FashionLabsModelRepository from "@src/core/domain/fashionLabsModel/model/FashionLabsModelRepository";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import {
  createFashionLabsModelDtoFromFashionLabsModel,
  editFashionLabsModelDtoFromFashionLabsModel,
} from "./requestBuilder";

class HttpFashionLabsModelRepository implements FashionLabsModelRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async get(modelId: string): Promise<FashionLabsModel | undefined> {
    try {
      const response = await this.httpClient.post("/view-bundle-by-id", { id: modelId });

      if (!response.ok) {
        throw new Error("obtener el id de la modelo");
      }

      const { result } = await response.json();

      return new FashionLabsModel(
        result.id,
        result.name,
        result.attributes.map(
          (attribute: {
            height: string;
            weight: string;
            braSize: string;
            upperSize: string;
            bottomSize: string;
            hipOutline: string;
            chestOutline: string;
            waistOutline: string;
            shoes: string;
          }) => ({
            height: attribute.height,
            weight: attribute.weight,
            braSize: attribute.braSize,
            upperSize: attribute.upperSize,
            bottomSize: attribute.bottomSize,
            hipOutline: attribute.hipOutline,
            chestOutline: attribute.chestOutline,
            waistOutline: attribute.waistOutline,
            shoes: attribute.shoes,
          }),
        ),
      );
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener el id de la modelo");
    }
  }

  public async create(model: FashionLabsModel): Promise<void> {
    try {
      const createFashionLabsmodelDto = createFashionLabsModelDtoFromFashionLabsModel(model);

      const response = await this.httpClient.post("/create-fashion-labs-model", createFashionLabsmodelDto);

      if (!response.ok) {
        throw new Error("crear modelo de fashion labs en el servicio");
      }

      this.eventBus.publish(model.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("crear producto de fashion labs en el servicio");
    }
  }

  public async edit(model: FashionLabsModel): Promise<void> {
    try {
      const editFashionLabsProductDto = editFashionLabsModelDtoFromFashionLabsModel(model);

      const response = await this.httpClient.post("/edit-fashion-labs-model", editFashionLabsProductDto);

      if (!response.ok) {
        throw new Error("actualizar datos modelo de fashion labs en el servicio");
      }

      this.eventBus.publish(model.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("actualizar datos modelo de fashion labs en el servicio");
    }
  }
}

export default HttpFashionLabsModelRepository;
