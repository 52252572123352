import { CommandHandler } from "@lookiero/messaging.js";
import FashionLabsProductVariantRepository from "../../model/FashionLabsProductVariantRepository";
import EditFashionLabsProductVariant from "./EditFashionLabsProductVariant";

class EditFashionLabsProductVariantHandler implements CommandHandler<EditFashionLabsProductVariant> {
  private repository: FashionLabsProductVariantRepository;

  public constructor(repository: FashionLabsProductVariantRepository) {
    this.repository = repository;
  }

  public async execute(command: EditFashionLabsProductVariant): Promise<void> {
    command.productVariants.forEach(async (variant) => {
      const productVariant = await this.repository.getById(variant.id);

      if (!productVariant) {
        throw new Error(`No hay ningun producto con el id ${variant.id}`);
      }

      productVariant.edit(
        variant.season,
        variant.publishable,
        variant.barcode,
        variant.reference,
        variant.color,
        variant.size,
        variant.media,
        variant.fashionModel,
        variant.sellingPriceEur,
        variant.sellingPriceGbp,
        variant.sellingPriceSek,
        variant.sellingPriceChf,
      );

      return this.repository.editVariant(productVariant);
    });
  }
}

export default EditFashionLabsProductVariantHandler;
