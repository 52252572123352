import { EventBus } from "@lookiero/messaging.js";
import CoreError from "@src/core/domain/error/model/CoreError";
import ErrorRepository from "@src/core/domain/error/model/ErrorRepository";

class InMemoryErrorRepository implements ErrorRepository {
  private eventBus!: EventBus;

  public async throw(error: CoreError): Promise<void> {
    try {
      this.eventBus.publish(error.recordedEvents());
    } catch (error) {
      console.log(error);
      throw new Error("error lanzando errores"); // ¯\_(ツ)_/¯
    }
  }
}

export default InMemoryErrorRepository;
