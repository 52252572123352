import React, { createContext, FC, MouseEvent, ReactNode, useCallback, useContext, useRef, useState } from "react";
import { useClickAway } from "react-use";
import classNames from "classnames";

import "./avatarDropdown.css";
import { Icon, IconVariant } from "@lookiero/react-ui-kit";

interface AvatarDropDownContextResult {
  readonly openMenu: boolean;
  readonly onMenuToggled: (event: MenuToggle) => void;
  readonly menuRef: React.MutableRefObject<null>;
}

interface AvatarDropDownContext {
  readonly children: ReactNode;
}

type MenuToggle = Event | MouseEvent<HTMLElement>;

const AvatarDropdownContext = createContext<AvatarDropDownContextResult | undefined>(undefined);

const AvatarDropdown: FC<AvatarDropDownContext> = ({ children }) => {
  const menuRef = useRef(null);
  const [openMenu, toggleMenu] = useState<boolean>(false);

  const onMenuToggled = useCallback((event: MenuToggle) => {
    event.stopPropagation();
    toggleMenu((openMenu) => !openMenu);
  }, []);

  useClickAway(menuRef, (event) => {
    if (openMenu) {
      onMenuToggled(event);
    }
  });

  const value = {
    menuRef,
    openMenu,
    onMenuToggled,
  };

  return <AvatarDropdownContext.Provider value={value}>{children}</AvatarDropdownContext.Provider>;
};

const Avatar: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="avatar-container__icon">{children}</div>;
};

const Toggle: FC<{ children: ReactNode }> = ({ children }) => {
  const { openMenu, onMenuToggled } = useContext(AvatarDropdownContext) as AvatarDropDownContextResult;
  return (
    <div className={classNames("avatar-container", { "avatar-container--opened": openMenu })} onClick={onMenuToggled}>
      <Avatar>{children}</Avatar>
      <Icon variant={IconVariant.MINOR_DROPDOWN} />
    </div>
  );
};

const Menu: FC<{ children: ReactNode }> = ({ children }) => {
  const { menuRef, openMenu } = useContext(AvatarDropdownContext) as AvatarDropDownContextResult;

  return (
    <div className={classNames("avatar-menu", { "avatar-menu--opened": openMenu })} ref={menuRef}>
      {children}
    </div>
  );
};

export { AvatarDropdown, Avatar, Menu, Toggle };
