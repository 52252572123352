import {
  PagedProductCriteria,
  ProductCriteria,
  ProductsCriteriaAvailability,
} from "@src/core/projection/product/model/ProductView";
import toArray from "@src/shared/dataTransformer/toArray";
import stringToBoolean from "@src/shared/dataTransformer/stringToBoolean";

type CountProductsRequest = {
  readonly date: string;
  readonly groups: string[] | undefined;
  readonly markets: string[] | undefined;
  readonly families: string[] | undefined;
  readonly brands: string[] | undefined;
  readonly sizes: string[] | undefined;
  readonly colors: string[] | undefined;
  readonly seasons: string[] | undefined;
  readonly featureValues: string[] | undefined;
  readonly brandOrigins: string[] | undefined;
  readonly visibility: string | undefined;
  readonly reference: string[] | undefined;
  readonly hasStock: boolean | undefined;
};

type ListProductsRequest = CountProductsRequest & {
  readonly page: number;
  readonly perPage: number;
};

type ProductsAvailabilityRequest = {
  readonly groups: string[] | undefined;
  readonly families: string[] | undefined;
  readonly brands: string[] | undefined;
  readonly sizes: string[] | undefined;
  readonly colors: string[] | undefined;
  readonly seasons: string[] | undefined;
  readonly featureValues: string[] | undefined;
  readonly brandOrigins: string[] | undefined;
  readonly visibility: string | undefined;
  readonly reference: string[] | undefined;
  readonly hasStock: string | undefined;
};

const createListProductsRequestFromCriteria = (criteria: PagedProductCriteria): ListProductsRequest => {
  const {
    groups,
    market,
    families,
    brands,
    sizes,
    colors,
    seasons,
    date,
    page,
    perPage,
    featureValues,
    brandOrigins,
    visibility,
    hasStock,
    reference,
  } = criteria;
  return {
    date: date,
    page: page,
    perPage: perPage,
    groups: toArray(groups),
    markets: toArray(market),
    families: toArray(families),
    brands: toArray(brands),
    sizes: toArray(sizes),
    colors: toArray(colors),
    seasons: toArray(seasons),
    featureValues: toArray(featureValues),
    brandOrigins: toArray(brandOrigins),
    visibility,
    hasStock: hasStock ? stringToBoolean(hasStock) : undefined,
    reference: toArray(reference),
  };
};

const createCountProductsRequestFromCriteria = (criteria: ProductCriteria): CountProductsRequest => {
  const {
    groups,
    market,
    families,
    brands,
    sizes,
    colors,
    seasons,
    date,
    featureValues,
    brandOrigins,
    visibility,
    reference,
    hasStock,
  } = criteria;
  return {
    date: date,
    groups: toArray(groups),
    markets: toArray(market),
    families: toArray(families),
    brands: toArray(brands),
    sizes: toArray(sizes),
    colors: toArray(colors),
    seasons: toArray(seasons),
    featureValues: toArray(featureValues),
    brandOrigins: toArray(brandOrigins),
    visibility,
    reference: toArray(reference),
    hasStock: stringToBoolean(hasStock),
  };
};

const createListProductsAvailabilityRequestFromCriteria = (
  criteria: ProductsCriteriaAvailability,
): ProductsAvailabilityRequest => {
  const {
    groups,
    families,
    brands,
    sizes,
    colors,
    seasons,
    featureValues,
    brandOrigins,
    visibility,
    reference,
    hasStock,
  } = criteria;
  return {
    groups: !!groups ? toArray(groups) : undefined,
    families: !!families ? toArray(families) : undefined,
    brands: !!brands ? toArray(brands) : undefined,
    sizes: !!sizes ? toArray(sizes) : undefined,
    colors: !!colors ? toArray(colors) : undefined,
    seasons: !!seasons ? toArray(seasons) : undefined,
    featureValues: !!featureValues ? toArray(featureValues) : undefined,
    brandOrigins: !!brandOrigins ? toArray(brandOrigins) : undefined,
    visibility: !!visibility ? visibility : undefined,
    reference: !!reference ? toArray(reference) : undefined,
    hasStock: !!hasStock ? hasStock : undefined,
  };
};

const createListProductsVariantVisibilityRequestFromCriteria = (
  criteria: ProductsCriteriaAvailability,
): ProductsAvailabilityRequest => {
  const {
    groups,
    families,
    brands,
    sizes,
    colors,
    seasons,
    featureValues,
    brandOrigins,
    visibility,
    reference,
    hasStock,
  } = criteria;
  return {
    groups: !!groups ? toArray(groups) : undefined,
    families: !!families ? toArray(families) : undefined,
    brands: !!brands ? toArray(brands) : undefined,
    sizes: !!sizes ? toArray(sizes) : undefined,
    colors: !!colors ? toArray(colors) : undefined,
    seasons: !!seasons ? toArray(seasons) : undefined,
    featureValues: !!featureValues ? toArray(featureValues) : undefined,
    brandOrigins: !!brandOrigins ? toArray(brandOrigins) : undefined,
    visibility: !!visibility ? visibility : undefined,
    reference: !!reference ? toArray(reference) : undefined,
    hasStock: !!hasStock ? hasStock : undefined,
  };
};

export {
  createListProductsRequestFromCriteria,
  createCountProductsRequestFromCriteria,
  createListProductsAvailabilityRequestFromCriteria,
  createListProductsVariantVisibilityRequestFromCriteria,
};
