import { Command } from "@lookiero/messaging.js";

class Logout extends Command {
  /* istanbul ignore next */
  public messageName(): string {
    return "Logout";
  }
}

export default Logout;
