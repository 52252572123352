import { Command } from "@lookiero/messaging.js";
import {
  FashionLabsProductFeature,
  FashionLabsProductModelInfo,
} from "@src/core/projection/fashionLabsProduct/model/FashionLabsProduct";

class CreateFashionLabsProduct extends Command {
  public readonly productId: string;
  public readonly title: string;
  public readonly note: string;
  public readonly familyId: string;
  public readonly brandId: string;
  public readonly group: string;
  public readonly channels: string[];
  public readonly features: FashionLabsProductFeature[];
  public readonly productModel: FashionLabsProductModelInfo;
  public readonly media: {
    readonly id: string;
  }[];

  public constructor(
    productId: string,
    title: string,
    note: string,
    familyId: string,
    brandId: string,
    group: string,
    channels: string[],
    features: FashionLabsProductFeature[],
    productModel: FashionLabsProductModelInfo,
    media: { id: string }[],
  ) {
    super();
    this.productId = productId;
    this.title = title;
    this.note = note;
    this.familyId = familyId;
    this.brandId = brandId;
    this.group = group;
    this.channels = channels;
    this.features = features;
    this.productModel = productModel;
    this.media = media;
  }

  public messageName(): string {
    return "CreateFashionLabsProduct";
  }
}

export default CreateFashionLabsProduct;
