import { QueryHandler } from "@lookiero/messaging.js";
import Brand from "../../model/Brand";
import BrandView from "../../model/BrandView";
import ListBrands from "./ListBrands";

class ListBrandsHandler implements QueryHandler<ListBrands> {
  private readonly view: BrandView;

  public constructor(view: BrandView) {
    this.view = view;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: ListBrands): Promise<Brand[]> {
    return this.view.list();
  }
}

export default ListBrandsHandler;
