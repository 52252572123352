import { OrderProjection } from "@src/core/projection/order/model/OrderProjection";

export type ResponseViewOrderByOwnerAndStatus = {
  readonly id: string;
  readonly reference: string;
  readonly orderItems: {
    readonly id: string;
    readonly imageUrl: string;
    readonly reference: string;
    readonly brand: string;
    readonly origin: string;
    readonly familyName: string;
    readonly weather: { value: string }[];
    readonly stock: number;
    readonly quantity: number;
  }[];
};

const responseMapper = (response: ResponseViewOrderByOwnerAndStatus): OrderProjection => ({
  orderId: response.id,
  orderReference: response.reference,
  orderItems: response.orderItems?.map((item) => ({
    orderItemId: item.id,
    imageUrl: item.imageUrl,
    reference: item.reference,
    brand: item.brand,
    origin: item.origin,
    familyName: item.familyName,
    weather: item.weather.map((value) => value.value),
    stock: item.stock,
    quantity: item.quantity,
  })),
});

export { responseMapper };
