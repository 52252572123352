import { CommandHandler } from "@lookiero/messaging.js";
import FamilyRepository from "../../model/FamilyRepository";
import CloneFamilyFeatureValues from "./CloneFamilyFeatureValues";
import Family from "../../model/Family";

class CloneFamilyFeatureValuesHandler implements CommandHandler<CloneFamilyFeatureValues> {
  private repository: FamilyRepository;

  public constructor(repository: FamilyRepository) {
    this.repository = repository;
  }

  public async execute({ familyId, cloneFamilyId }: CloneFamilyFeatureValues): Promise<void> {
    const instace = Family.cloneFeatureValues(familyId, cloneFamilyId);

    await this.repository.cloneFeatureValues(instace);
  }
}

export default CloneFamilyFeatureValuesHandler;
