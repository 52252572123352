import InMemoryEmployeeRepository from "@src/shared/infrastructure/domain/employee/model/InMemoryEmployeeRepository";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";

class JwtAuthFetcher {
  public constructor(private employeeRepository: InMemoryEmployeeRepository) {}

  private static UN_AUTHORIZED_HTTP_STATUS_CODES = [401, 403];

  public async fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    const customParams = await this.buildRequestInitWithJwtAuthorization(init);

    const response = await fetch(input, customParams);

    this.checkRequestWasAuthorized(response);

    return response;
  }

  private async buildRequestInitWithJwtAuthorization(params?: RequestInit): Promise<RequestInit> {
    const headers = await this.addJwtAuthorization(params?.headers);
    return {
      ...params,
      headers,
    };
  }

  private async addJwtAuthorization(headers?: HeadersInit): Promise<HeadersInit> {
    const authorization = await this.getJwtAuthorization();
    return {
      ...headers,
      ...authorization,
    };
  }

  public async getJwtAuthorization(): Promise<Record<string, string>> {
    const employee = await this.employeeRepository.getCurrentEmployee();
    return { Authorization: `Bearer ${employee?.authToken}` };
  }

  private checkRequestWasAuthorized(response: Response): void {
    if (JwtAuthFetcher.UN_AUTHORIZED_HTTP_STATUS_CODES.includes(response.status)) {
      throw new UnAuthorizedEmployeeError();
    }
  }
}

export default JwtAuthFetcher;
