import { DomainEvent } from "@lookiero/messaging.js";

class FamilyFeatureValuesEdited extends DomainEvent {
  public familyId: string;
  public featureValuesIds: string[];

  public constructor({ familyId, featureValuesIds }: { familyId: string; featureValuesIds: string[] }) {
    super();
    this.familyId = familyId;
    this.featureValuesIds = featureValuesIds;
  }

  public messageName(): string {
    return "FamilyFeatureValuesEdited";
  }
}

export default FamilyFeatureValuesEdited;
