import { DomainEvent } from "@lookiero/messaging.js";

class NotificationRemoved extends DomainEvent {
  public notificationId: string;

  public constructor(notificationId: string) {
    super();
    this.notificationId = notificationId;
  }

  public messageName(): string {
    return "NotificationRemoved";
  }
}

export default NotificationRemoved;
