import { QueryHandler } from "@lookiero/messaging.js";
import GetCurrentEmployee from "./GetCurrentEmployee";
import EmployeeView from "../../model/EmployeeView";
import Employee from "../../model/Employee";

class GetCurrentEmployeeHandler implements QueryHandler<GetCurrentEmployee> {
  private readonly view: EmployeeView;

  public constructor(view: EmployeeView) {
    this.view = view;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: GetCurrentEmployee): Promise<Employee | undefined> {
    return this.view.getCurrentEmployee();
  }
}

export default GetCurrentEmployeeHandler;
