import { EventBus } from "@lookiero/messaging.js";
import Employee from "@src/core/projection/employee/model/Employee";
import EmployeeView from "@src/core/projection/employee/model/EmployeeView";

import StorageClient from "@src/shared/delivery/application/StorageClient";

class InMemoryEmployeeView implements EmployeeView {
  private eventBus!: EventBus;

  private static readonly EMPLOYEE_KEY = "employee";

  private readonly employeeStorageClient: StorageClient;

  constructor(storage: StorageClient) {
    this.employeeStorageClient = storage;
  }

  public async getCurrentEmployee(): Promise<Employee | undefined> {
    const employee = await this.employeeStorageClient.get(InMemoryEmployeeView.EMPLOYEE_KEY);
    return employee ? new Employee({ ...employee }) : undefined;
  }

  public async isEmployeeStillLoggedIn(): Promise<boolean> {
    return await this.employeeStorageClient.has(InMemoryEmployeeView.EMPLOYEE_KEY);
  }
}

export default InMemoryEmployeeView;
