import Size from "@src/core/projection/size/model/Size";
import SizeView from "@src/core/projection/size/model/SizeView";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import HttpClient from "../../../../../shared/delivery/application/HttpClient";

class HttpSizeView implements SizeView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Size[]> {
    try {
      const response = await this.httpClient.post("/search-sizes", { per_page: -1 });

      if (!response.ok) {
        throw new Error("obtener las tallas del servicio");
      }

      const { result } = await response.json();
      return result;
    } catch (error) {
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener las tallas del servicio");
    }
  }
}

export default HttpSizeView;
