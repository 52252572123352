import { QueryHandler } from "@lookiero/messaging.js";
import EmployeeView from "../../model/EmployeeView";
import GetCurrentEmployee from "../getCurrentEmployee/GetCurrentEmployee";

class IsEmployeeStillLoggedInHandler implements QueryHandler<GetCurrentEmployee> {
  private readonly view: EmployeeView;

  public constructor(view: EmployeeView) {
    this.view = view;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: GetCurrentEmployee): Promise<boolean> {
    return await this.view.isEmployeeStillLoggedIn();
  }
}

export default IsEmployeeStillLoggedInHandler;
