import React, { useEffect } from "react";
import { QueryClient, useQueryClient } from "react-query";

type QueryClientRetrieverProps = {
  readonly retriever: (queryClient: QueryClient) => void;
};

const QueryClientRetriever: React.FC<QueryClientRetrieverProps> = ({ retriever }) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    retriever(queryClient);
  }, [retriever, queryClient]);

  return <></>;
};

export default QueryClientRetriever;
