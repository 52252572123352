import { Command } from "@lookiero/messaging.js";
import NotificationLevel from "../../model/NotificationLevel";

class CreateNotification extends Command {
  public readonly notification: {
    readonly level: NotificationLevel;
    readonly content: string;
  };

  constructor(notification: { readonly level: NotificationLevel; readonly content: string }) {
    super();
    this.notification = notification;
  }

  protected messageName(): string {
    return "CreateNotification";
  }
}

export default CreateNotification;
