import Season from "@src/core/projection/season/model/Season";
import SeasonView from "@src/core/projection/season/model/SeasonView";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import HttpClient from "../../../../../shared/delivery/application/HttpClient";

class HttpSeasonView implements SeasonView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Season[]> {
    try {
      const response = await this.httpClient.post("/search-seasons", { per_page: -1 });

      if (!response.ok) {
        throw new Error("obtener las temporadas del servicio");
      }

      const { result } = await response.json();
      return result;
    } catch (error) {
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener las temporadas del servicio");
    }
  }
}

export default HttpSeasonView;
