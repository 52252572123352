import NotificationView from "@src/core/projection/notification/model/NotificationView";
import Notification from "@src/core/projection/notification/model/Notification";
import StorageClient from "@src/shared/delivery/application/StorageClient";

class InMemoryNotificationView implements NotificationView {
  private notificationsStorageClient: StorageClient;

  public constructor(notificationsStorageClient: StorageClient) {
    this.notificationsStorageClient = notificationsStorageClient;
  }

  public async list(): Promise<Notification[]> {
    return await this.notificationsStorageClient.getAll();
  }
}

export default InMemoryNotificationView;
