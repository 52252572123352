import { Query } from "@lookiero/messaging.js";

class CanProductBeCreated extends Query {
  public readonly group: string;

  public constructor(group: string) {
    super();
    this.group = group;
  }

  public messageName(): string {
    return "CanProductBeCreated";
  }
}

export default CanProductBeCreated;
