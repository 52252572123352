import { QueryHandler } from "@lookiero/messaging.js";
import Market from "../../model/Market";
import MarketView from "../../model/MarketView";
import ListMarkets from "./ListMarkets";

class ListMarketsHandler implements QueryHandler<ListMarkets> {
  private readonly view: MarketView;

  public constructor(view: MarketView) {
    this.view = view;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: ListMarkets): Promise<Market[]> {
    return this.view.list();
  }
}

export default ListMarketsHandler;
