import { DomainEvent } from "@lookiero/messaging.js";

class FashionLabsProductEdited extends DomainEvent {
  public productGroup: string;

  public constructor(productGroup: string) {
    super();
    this.productGroup = productGroup;
  }

  public messageName(): string {
    return "FashionLabsProductEdited";
  }
}

export default FashionLabsProductEdited;
