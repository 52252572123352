import { PagedFashionLabsProductsCriteria } from "@src/core/projection/fashionLabsProduct/model/FashionLabsProductView";

type SearchByCriteriaDto = {
  readonly group: string;
  readonly page: number;
  readonly per_page: number;
};

const searchByCriteriaDtoFromCriteria = (criteria: PagedFashionLabsProductsCriteria): SearchByCriteriaDto => ({
  group: criteria.group,
  page: criteria.page,
  per_page: criteria.perPage,
});

export { searchByCriteriaDtoFromCriteria };
