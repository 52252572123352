import { Command } from "@lookiero/messaging.js";

class CreateEmployee extends Command {
  readonly authToken: string;
  readonly username: string;
  readonly roles: string[];

  constructor({ authToken, username, roles }: { authToken: string; username: string; roles: string[] }) {
    super();
    this.authToken = authToken;
    this.username = username;
    this.roles = roles;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return "CreateEmployee";
  }
}

export default CreateEmployee;
