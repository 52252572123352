import React, { createContext, FC, ReactNode, useCallback, useContext, useState } from "react";
import invariant from "tiny-invariant";

interface CatalogSelectionContextApi {
  readonly familiesSelection?: null | string | string[];
  readonly updateFamilies: (familiesSelection?: null | string | string[]) => void;
  readonly groupsSelection?: null | string | string[];
  readonly updateGroups: (groupsSelection?: null | string | string[]) => void;
  readonly brandsSelection?: null | string | string[];
  readonly updateBrands: (brandsSelection?: null | string | string[]) => void;
  readonly sizesSelection?: null | string | string[];
  readonly updateSizes: (SizesSelection?: null | string | string[]) => void;
  readonly colorsSelection?: null | string | string[];
  readonly updateColors: (ColorsSelection?: null | string | string[]) => void;
  readonly seasonsSelection?: null | string | string[];
  readonly updateSeasons: (seasonsSelection?: null | string | string[]) => void;
  readonly featureValuesSelection?: null | string | string[];
  readonly updateFeatureValues: (featureValuesSelection?: null | string | string[]) => void;
  readonly brandOriginsSelection?: null | string | string[];
  readonly updateBrandOrigins: (brandOriginsSelection?: null | string | string[]) => void;
  readonly visibilitySelection?: null | string;
  readonly updateVisibility: (visibilitySelection?: null | string) => void;
  readonly stockSelection?: null | string;
  readonly updateStock: (stockSelection?: null | string) => void;
  readonly updateSelection: (productsCount?: number, params?: Record<string, string | string[]>) => void;
  readonly isSelection: boolean;
  readonly updateReference: (referenceSelection?: null | string) => void;
  readonly referenceSelection?: null | string;
}

const CatalogSelectionContext = createContext<CatalogSelectionContextApi | null>(null);

interface CatalogSelectionProviderProps {
  readonly children: ReactNode;
}

const CatalogSelectionProvider: FC<CatalogSelectionProviderProps> = ({ children }) => {
  const [groupsSelection, setGroupsSelection] = useState<string | string[] | null | undefined>();
  const [familiesSelection, setFamiliesSelection] = useState<string | string[] | null | undefined>();
  const [brandsSelection, setBrandsSelection] = useState<string | string[] | null | undefined>();
  const [sizesSelection, setSizesSelection] = useState<string | string[] | null | undefined>();
  const [colorsSelection, setColorsSelection] = useState<string | string[] | null | undefined>();
  const [stockSelection, setStockSelection] = useState<string | null | undefined>();
  const [seasonsSelection, setSeasonsSelection] = useState<string | string[] | null | undefined>();
  const [featureValuesSelection, setFeatureValuesSelection] = useState<string | string[] | null | undefined>();
  const [brandOriginsSelection, setBrandOriginsSelection] = useState<string | string[] | null | undefined>();
  const [visibilitySelection, setVisibilitySelection] = useState<string | null | undefined>();
  const [isSelection, setIsSelection] = useState(false);
  const [referenceSelection, setReferenceSelection] = useState<string | null | undefined>();

  const updateFamilies = useCallback(
    (updatedFamilies?: null | string | string[]) => setFamiliesSelection(updatedFamilies),
    [],
  );
  const updateGroups = useCallback((updatedGroups?: null | string | string[]) => setGroupsSelection(updatedGroups), []);
  const updateBrands = useCallback((updatedBrands?: null | string | string[]) => setBrandsSelection(updatedBrands), []);
  const updateSizes = useCallback((updatedSizes?: null | string | string[]) => setSizesSelection(updatedSizes), []);
  const updateColors = useCallback((updatedColors?: null | string | string[]) => setColorsSelection(updatedColors), []);
  const updateStock = useCallback((updatedStock?: null | string) => setStockSelection(updatedStock), []);
  const updateSeasons = useCallback(
    (updatedSeasons?: null | string | string[]) => setSeasonsSelection(updatedSeasons),
    [],
  );
  const updateFeatureValues = useCallback(
    (updateFeatureValues?: null | string | string[]) => setFeatureValuesSelection(updateFeatureValues),
    [],
  );
  const updateVisibility = useCallback(
    (updateVisibility?: null | string) => setVisibilitySelection(updateVisibility),
    [],
  );
  const updateBrandOrigins = useCallback(
    (updateBrandOrigins?: null | string | string[]) => setBrandOriginsSelection(updateBrandOrigins),
    [],
  );
  const updateReference = useCallback(
    (updatedReference?: null | string) => setReferenceSelection(updatedReference),
    [],
  );

  const updateSelection = useCallback(
    (productsCount?: number, params?: Record<string, string | string[]>) => {
      setIsSelection(
        productsCount &&
          productsCount < 500 &&
          (groupsSelection?.length ||
            familiesSelection?.length ||
            brandsSelection?.length ||
            sizesSelection?.length ||
            colorsSelection?.length ||
            seasonsSelection?.length ||
            featureValuesSelection?.length ||
            brandOriginsSelection?.length ||
            visibilitySelection?.length ||
            stockSelection?.length ||
            referenceSelection?.length ||
            params)
          ? false
          : true,
      );
    },
    [
      groupsSelection,
      familiesSelection,
      brandsSelection,
      sizesSelection,
      colorsSelection,
      seasonsSelection,
      featureValuesSelection,
      brandOriginsSelection,
      visibilitySelection,
      stockSelection,
      referenceSelection,
    ],
  );

  return (
    <CatalogSelectionContext.Provider
      value={{
        familiesSelection,
        updateFamilies,
        groupsSelection,
        updateGroups,
        brandsSelection,
        updateBrands,
        sizesSelection,
        updateSizes,
        colorsSelection,
        updateColors,
        stockSelection,
        updateStock,
        seasonsSelection,
        updateSeasons,
        featureValuesSelection,
        updateFeatureValues,
        visibilitySelection,
        updateVisibility,
        updateSelection,
        brandOriginsSelection,
        updateBrandOrigins,
        isSelection,
        updateReference,
        referenceSelection,
      }}
    >
      {children}
    </CatalogSelectionContext.Provider>
  );
};

const useCatalogSelection = (): CatalogSelectionContextApi => {
  const catalogSelection = useContext(CatalogSelectionContext);

  invariant(catalogSelection, "useCatalogSelection must be used within a CatalogSelectionProvider");

  return catalogSelection;
};

export { CatalogSelectionProvider, useCatalogSelection };
