import { AggregateRoot } from "@lookiero/messaging.js";
import NotificationLevel from "./NotificationLevel";
import NotificationCreated from "./NotificationCreated";
import NotificationRemoved from "./NotificationRemoved";

class Notification extends AggregateRoot {
  public id: string;
  public level: NotificationLevel;
  public content: string;
  public timestamp: number;

  public constructor(id: string, level: NotificationLevel, content: string) {
    super();
    this.id = id;
    this.level = level;
    this.content = content;
    this.timestamp = Date.now();
  }

  public static create(id: string, level: NotificationLevel, content: string): Notification {
    const instance = new Notification(id, level, content);
    instance.record(new NotificationCreated(instance.id));

    return instance;
  }

  public remove(): void {
    this.record(new NotificationRemoved(this.id));
  }
}

export default Notification;
