import { EventBus } from "@lookiero/messaging.js";
import Availability from "@src/core/domain/availability/model/Availability";
import AvailabilityRepository from "@src/core/domain/availability/model/AvailabilityRepository";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
class HttpAvailabilityRepository implements AvailabilityRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async edit(availability: Availability): Promise<void> {
    try {
      const requestBuilder = {
        transaction_id: availability.transactionId,
        product_ids: availability.products,
        channel: availability.channelName.toUpperCase(),
        available_markets: availability.availableSellingMarkets,
        unavailable_markets: availability.unavailableSellingMarkets,
        reason: availability.reason,
      };

      const response = await this.httpClient.post("/edit-products-availability-by-channel", requestBuilder);

      if (!response.ok) {
        throw new Error("Editar disponibilidad de prendas en el servicio");
      }

      this.eventBus.publish(availability.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("Editar disponibilidad de prendas en el servicio");
    }
  }
}
export default HttpAvailabilityRepository;
