import Family from "@src/core/projection/family/model/Family";
import FamilyView from "@src/core/projection/family/model/FamilyView";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import HttpClient from "../../../../../shared/delivery/application/HttpClient";

class HttpFamilyView implements FamilyView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Family[]> {
    try {
      const response = await this.httpClient.post("/search-families", { per_page: -1 });

      if (!response.ok) {
        throw new Error("obtener las familias del servicio");
      }

      const { result } = await response.json();
      return result;
    } catch (error) {
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener las familias del servicio");
    }
  }
}

export default HttpFamilyView;
