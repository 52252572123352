/* eslint-disable @typescript-eslint/no-explicit-any */
import { Container } from "inversify";
import { helpers, inversifyInterfaces } from "inversify-vanillajs-helpers";
import getDecorators from "inversify-inject-decorators";
import { handlerContainer } from "./handlerContainer";
import { fromStringToSymbol } from "../_data-transfrom/fromStringToSymbol";

const inSingletonScopeBinding = <T>(binding: inversifyInterfaces.BindingInSyntax<T>): void =>
  void binding.inSingletonScope();

enum BusInjection {
  COMMAND_BUS = "commandBus",
  EVENT_BUS = "eventBus",
}

interface RegisterArgs {
  readonly identifier: string;
  readonly dependencies: string[];
  readonly classInjection: any;
}

interface Register {
  (args: RegisterArgs): void;
}

interface ListRegister {
  (args: RegisterArgs[]): void;
}

interface RegisterDynamicArgs {
  readonly identifier: string;
  readonly func: (context: inversifyInterfaces.Context) => any;
}

interface RegisterDynamic {
  (args: RegisterDynamicArgs): void;
}

interface RegisterConstantArgs {
  readonly identifier: string;
  readonly value: any;
}

interface RegisterConstant {
  (args: RegisterConstantArgs): void;
}

interface RegistersFunctionResult {
  listRegister: ListRegister;
  register: Register;
  registerDynamicValue: RegisterDynamic;
  registerConstantValue: RegisterConstant;
  busInjection: ({ identifiyer, name, type }: { identifiyer: string; name: string; type: BusInjection }) => void;
}

interface RegistersFunctionArgs {
  readonly container: Container;
}

interface RegistersFunction {
  (args: RegistersFunctionArgs): RegistersFunctionResult;
}

const registers: RegistersFunction = ({ container }) => {
  const inversifyRegister = helpers.register(container as any);
  const inversifyRegisterDynamicValue = helpers.registerDynamicValue(container as any);
  const inversifyRegisterConstantValue = helpers.registerConstantValue(container as any);

  const { lazyInject } = getDecorators(container, true);
  const handler = handlerContainer({ container });

  const register: Register = ({ identifier, dependencies, classInjection }) =>
    inversifyRegister(
      fromStringToSymbol(identifier),
      dependencies.map((dependency) => fromStringToSymbol(dependency)),
      inSingletonScopeBinding,
    )(classInjection);

  const listRegister: ListRegister = (args) =>
    args.map((arg) =>
      inversifyRegister(
        fromStringToSymbol(arg.identifier),
        arg.dependencies.map((dependency) => fromStringToSymbol(dependency)),
        inSingletonScopeBinding,
      )(arg.classInjection),
    );

  const registerDynamicValue: RegisterDynamic = ({ identifier, func }) =>
    inversifyRegisterDynamicValue(fromStringToSymbol(identifier), func, inSingletonScopeBinding);

  const registerConstantValue: RegisterConstant = ({ identifier, value }) =>
    inversifyRegisterConstantValue(fromStringToSymbol(identifier), value);

  const busInjection = ({ identifiyer, name, type }: { identifiyer: string; name: string; type: BusInjection }) =>
    lazyInject(fromStringToSymbol(identifiyer))(handler(name), type);

  return { listRegister, register, registerDynamicValue, registerConstantValue, busInjection };
};

export type { RegisterArgs };
export { registers, BusInjection };
