import { EventBus } from "@lookiero/messaging.js";
import FashionLabsProduct from "@src/core/domain/fashionLabsProduct/model/FashionLabsProduct";
import FashionLabsProductRepository from "@src/core/domain/fashionLabsProduct/model/FashionLabsProductRepository";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import { toCreateFashionLabsProductRequest, toEditFashionLabsProductRequest } from "./requestBuilder";
import { toFashionLabsProduct } from "./responseMapper";

class HttpFashionLabsProductRepository implements FashionLabsProductRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async getByGroup(group: string): Promise<FashionLabsProduct | undefined> {
    try {
      const requestParameters = {
        group: group.toUpperCase(),
      };

      const response = await this.httpClient.post("/view-product-by-group", requestParameters);

      if (!response.ok) {
        throw new Error("obtener el pedido del servicio");
      }

      const { result: dto } = await response.json();

      if (!dto) {
        return undefined;
      }

      return toFashionLabsProduct(dto);
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener el pedido del servicio");
    }
  }

  public async createProduct(product: FashionLabsProduct): Promise<void> {
    try {
      const createFashionLabsProductDto = toCreateFashionLabsProductRequest(product);

      const response = await this.httpClient.post("/create-product", createFashionLabsProductDto);

      if (!response.ok) {
        throw new Error("crear producto de fashion labs en el servicio");
      }

      this.eventBus.publish(product.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("crear producto de fashion labs en el servicio");
    }
  }

  public async editProduct(product: FashionLabsProduct): Promise<void> {
    try {
      const editFashionLabsProductDto = toEditFashionLabsProductRequest(product);

      const response = await this.httpClient.post("/edit-product", editFashionLabsProductDto);

      if (!response.ok) {
        throw new Error("editar producto de fashion labs en el servicio");
      }

      this.eventBus.publish(product.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("editar producto de fashion labs en el servicio");
    }
  }
}

export default HttpFashionLabsProductRepository;
