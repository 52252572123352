import HttpBrandView from "@src/core/infrastructure/projection/brand/model/HttpBrandView";
import HttpColorView from "@src/core/infrastructure/projection/color/model/HttpColorView";
import InMemoryEmployeeView from "@src/core/infrastructure/projection/employee/model/InMemoryEmployeeView";
import HttpFamilyView from "@src/core/infrastructure/projection/family/model/HttpFamilyView";
import HttpFashionLabsProductView from "@src/core/infrastructure/projection/fashionLabsProduct/model/HttpFashionLabsProductView";
import HttpFashionModelView from "@src/core/infrastructure/projection/fashionModel/model/HttpFashionModelView";
import HttpFeatureView from "@src/core/infrastructure/projection/feature/model/HttpFeatureView";
import HttpMarketView from "@src/core/infrastructure/projection/market/model/HttpMarketView";
import HttpMediaView from "@src/core/infrastructure/projection/media/model/HttpMediaView";
import InMemoryNotificationView from "@src/core/infrastructure/projection/notification/model/InMemoryNotificationView";
import HttpOrderByOwnerAndStatusView from "@src/core/infrastructure/projection/order/viewOrderByOwnerAndStatus/HttpOrderByOwnerAndStatusView";
import HttpSeasonView from "@src/core/infrastructure/projection/season/model/HttpSeasonView";
import HttpSizeView from "@src/core/infrastructure/projection/size/model/HttpSizeView";
import HttpUserView from "@src/core/infrastructure/projection/user/model/HttpUserView";
import ListBrandsHandler from "@src/core/projection/brand/query/listBrands/ListBrandsHandler";
import ListColorsHandler from "@src/core/projection/color/query/listColors/ListColorsHandler";
import GetCurrentEmployeeHandler from "@src/core/projection/employee/query/getCurrentEmployee/GetCurrentEmployeeHandler";
import IsEmployeeStillLoggedInHandler from "@src/core/projection/employee/query/isEmployeeStillLoggedIn/IsEmployeeStillLoggedInHandler";
import ListFamiliesHandler from "@src/core/projection/family/query/listFamilies/ListFamiliesHandler";
import CanProductBeCreatedHandler from "@src/core/projection/fashionLabsProduct/query/canProductBeCreated/CanProductBeCreatedHandler";
import CountFashionLabsProductsByCriteriaHandler from "@src/core/projection/fashionLabsProduct/query/countFashionLabsProductsByCriteria/CountFashionLabsProductsByCriteriaHandler";
import SearchFashionLabsProductsByCriteriaHandler from "@src/core/projection/fashionLabsProduct/query/searchFashionLabsProductsByCriteria/SearchFashionLabsProductsByCriteriaHandler";
import ViewProductByGroupHandler from "@src/core/projection/fashionLabsProduct/query/viewProductByGroup/ViewProductByGroupHandler";
import ViewProductForImportHandler from "@src/core/projection/fashionLabsProduct/query/viewProductForImport/ViewProductForImportHandler";
import ListFashionModelFittingsHandler from "@src/core/projection/fashionModel/query/listFashionModelFittings/ListFashionModelFittingsHandler";
import ListFashionModelsHandler from "@src/core/projection/fashionModel/query/listFashionModels/ListFashionModelsHandler";
import GetFeaturesHandler from "@src/core/projection/feature/query/getFeatures/GetFeaturesHandler";
import SearchFeaturesByFamilyHandler from "@src/core/projection/feature/query/searchFeaturesByFamily/SearchFeaturesByFamilyHandler";
import SearchFeatureValuesByFeatureHandler from "@src/core/projection/feature/query/searchFeatureValuesByFeature/SearchFeatureValuesByFeatureHandler";
import ListMarketsHandler from "@src/core/projection/market/query/listMarkets/ListMarketsHandler";
import DownloadMediaHandler from "@src/core/projection/media/query/downloadMedia/DownloadMediaHandler";
import ListNotificationsHandler from "@src/core/projection/notification/query/listNotifications/ListNotificationsHandler";
import ViewOrderByOwnerAndStatusHandler from "@src/core/projection/order/query/viewOrderByOwnerAndStatus/ViewOrderByOwnerAndStatusHandler";
import CountProductsHandler from "@src/core/projection/product/query/countProducts/CountProductsHandler";
import ListProductsHandler from "@src/core/projection/product/query/listProducts/ListProductsHandler";
import ListProductsForGroupPartialsHandler from "@src/core/projection/product/query/listProductsForGroupPartials/ListProductsForGroupPartialsHandler";
import ViewProductByIdHandler from "@src/core/projection/product/query/viewProductById/ViewProductByIdHandler";
import ViewProductsForAvailabilityHandler from "@src/core/projection/product/query/viewProductsForAvailability/ViewProductsForAvailabilityHandler";
import ViewProductVariantsForVisibilityHandler from "@src/core/projection/product/query/viewProductVariantsForVisibility/ViewProductVariantsForVisibilityHandler";
import ListSeasonsHandler from "@src/core/projection/season/query/listSeasons/ListSeasonsHandler";
import ListSizesHandler from "@src/core/projection/size/query/listSizes/ListSizesHandler";
import ListUsersHandler from "@src/core/projection/user/query/listUsers/ListUsersHandler";
import InMemoryStorageClient from "@src/shared/delivery/infrastructure/application/InMemoryStorageClient";

const queryRegisterList = [
  { identifier: "BrandView", dependencies: ["HttpClient"], classInjection: HttpBrandView },
  { identifier: "ListBrandsHandler", dependencies: ["BrandView"], classInjection: ListBrandsHandler },

  { identifier: "ColorView", dependencies: ["HttpClient"], classInjection: HttpColorView },
  { identifier: "ListColorsHandler", dependencies: ["ColorView"], classInjection: ListColorsHandler },

  { identifier: "SizeView", dependencies: ["HttpClient"], classInjection: HttpSizeView },
  { identifier: "ListSizesHandler", dependencies: ["SizeView"], classInjection: ListSizesHandler },

  { identifier: "MarketView", dependencies: ["HttpClient"], classInjection: HttpMarketView },
  { identifier: "ListMarketsHandler", dependencies: ["MarketView"], classInjection: ListMarketsHandler },

  { identifier: "FamilyView", dependencies: ["HttpClient"], classInjection: HttpFamilyView },
  { identifier: "ListFamiliesHandler", dependencies: ["FamilyView"], classInjection: ListFamiliesHandler },

  { identifier: "SeasonView", dependencies: ["HttpClient"], classInjection: HttpSeasonView },
  { identifier: "ListSeasonsHandler", dependencies: ["SeasonView"], classInjection: ListSeasonsHandler },

  { identifier: "NotificationStorageClient", dependencies: [], classInjection: InMemoryStorageClient },

  {
    identifier: "NotificationView",
    dependencies: ["NotificationStorageClient"],
    classInjection: InMemoryNotificationView,
  },
  {
    identifier: "ListNotificationsHandler",
    dependencies: ["NotificationView"],
    classInjection: ListNotificationsHandler,
  },

  { identifier: "CountProductsHandler", dependencies: ["ProductView"], classInjection: CountProductsHandler },
  { identifier: "ListProductsHandler", dependencies: ["ProductView"], classInjection: ListProductsHandler },
  { identifier: "ViewProductByIdHandler", dependencies: ["ProductView"], classInjection: ViewProductByIdHandler },
  {
    identifier: "ViewProductsForAvailabilityHandler",
    dependencies: ["ProductView"],
    classInjection: ViewProductsForAvailabilityHandler,
  },
  {
    identifier: "ListProductsForGroupPartialsHandler",
    dependencies: ["ProductView"],
    classInjection: ListProductsForGroupPartialsHandler,
  },

  { identifier: "FashionModelView", dependencies: ["HttpClient"], classInjection: HttpFashionModelView },
  {
    identifier: "ListFashionModelsHandler",
    dependencies: ["FashionModelView"],
    classInjection: ListFashionModelsHandler,
  },
  {
    identifier: "ListFashionModelFittingsHandler",
    dependencies: ["FashionModelView"],
    classInjection: ListFashionModelFittingsHandler,
  },

  { identifier: "FeatureView", dependencies: ["HttpClient"], classInjection: HttpFeatureView },
  {
    identifier: "SearchFeaturesByFamilyHandler",
    dependencies: ["FeatureView"],
    classInjection: SearchFeaturesByFamilyHandler,
  },
  {
    identifier: "SearchFeatureValuesByFeatureHandler",
    dependencies: ["FeatureView"],
    classInjection: SearchFeatureValuesByFeatureHandler,
  },
  {
    identifier: "GetFeaturesHandler",
    dependencies: ["FeatureView"],
    classInjection: GetFeaturesHandler,
  },

  { identifier: "FashionLabsProductView", dependencies: ["HttpClient"], classInjection: HttpFashionLabsProductView },
  {
    identifier: "ViewProductForImportHandler",
    dependencies: ["FashionLabsProductView"],
    classInjection: ViewProductForImportHandler,
  },
  {
    identifier: "SearchFashionLabsProductsByCriteriaHandler",
    dependencies: ["FashionLabsProductView"],
    classInjection: SearchFashionLabsProductsByCriteriaHandler,
  },
  {
    identifier: "CountFashionLabsProductsByCriteriaHandler",
    dependencies: ["FashionLabsProductView"],
    classInjection: CountFashionLabsProductsByCriteriaHandler,
  },
  {
    identifier: "CanProductBeCreatedHandler",
    dependencies: ["FashionLabsProductView"],
    classInjection: CanProductBeCreatedHandler,
  },
  {
    identifier: "ViewProductByGroupHandler",
    dependencies: ["FashionLabsProductView"],
    classInjection: ViewProductByGroupHandler,
  },
  {
    identifier: "MediaView",
    dependencies: ["HttpClient"],
    classInjection: HttpMediaView,
  },
  {
    identifier: "DownloadMediaHandler",
    dependencies: ["MediaView"],
    classInjection: DownloadMediaHandler,
  },
  {
    identifier: "OrderProjectionView",
    dependencies: ["HttpClient"],
    classInjection: HttpOrderByOwnerAndStatusView,
  },
  {
    identifier: "ViewOrderByOwnerAndStatusHandler",
    dependencies: ["OrderProjectionView"],
    classInjection: ViewOrderByOwnerAndStatusHandler,
  },
  {
    identifier: "UserView",
    dependencies: ["HttpClient"],
    classInjection: HttpUserView,
  },
  {
    identifier: "ListUsersHandler",
    dependencies: ["UserView"],
    classInjection: ListUsersHandler,
  },
  {
    identifier: "ViewProductVariantsForVisibilityHandler",
    dependencies: ["ProductView"],
    classInjection: ViewProductVariantsForVisibilityHandler,
  },
  {
    identifier: "EmployeeView",
    dependencies: ["LocalStorageClient"],
    classInjection: InMemoryEmployeeView,
  },
  {
    identifier: "GetCurrentEmployeeHandler",
    dependencies: ["EmployeeView", "LocalStorageClient"],
    classInjection: GetCurrentEmployeeHandler,
  },
  {
    identifier: "IsEmployeeStillLoggedInHandler",
    dependencies: ["EmployeeView", "LocalStorageClient"],
    classInjection: IsEmployeeStillLoggedInHandler,
  },
];

export { queryRegisterList };
