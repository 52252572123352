import StorageClient from "../../application/StorageClient";

class InMemoryStorageClient implements StorageClient {
  private cacheMap: Map<string, unknown>;

  constructor() {
    this.cacheMap = new Map();
  }

  public has(key: string): Promise<boolean> {
    const exist = this.cacheMap.has(key);
    return exist ? Promise.resolve(true) : Promise.reject(false);
  }

  public get(key: string): Promise<unknown | undefined> {
    const item = this.cacheMap.get(key);
    return Promise.resolve(item);
  }

  public getAll(): Promise<unknown[]> {
    const allItems = [...this.cacheMap.values()];
    return Promise.resolve(allItems);
  }

  public store(key: string, item: unknown): Promise<void> {
    try {
      this.cacheMap.set(key, item);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject();
    }
  }

  public delete(key: string): Promise<void> {
    this.cacheMap.delete(key);
    return Promise.resolve();
  }

  public clear(): Promise<void> {
    this.cacheMap.clear();
    return Promise.resolve();
  }
}

export default InMemoryStorageClient;
