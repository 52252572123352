import { DomainEvent } from "@lookiero/messaging.js";

class OrderItemRemoved extends DomainEvent {
  public orderId: string;
  public orderItemId: string;
  public quantity: number;

  public constructor(orderId: string, orderItemId: string, quantity: number) {
    super();
    this.orderId = orderId;
    this.orderItemId = orderItemId;
    this.quantity = quantity;
  }

  protected messageName(): string {
    return "OrderRemoved";
  }
}

export default OrderItemRemoved;
