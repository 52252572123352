import React, { useState, useCallback, useContext, useMemo, ReactNode } from "react";
import ProductAndGroup from "@src/core/projection/product/model/ProductAndGroup";

interface ProductSelectionProps {
  readonly children: ReactNode;
}

const contextInitialValue: {
  products: void | Record<string, ProductAndGroup>;
} = {
  get products(): void {
    throw new Error(
      "Your are trying to use the useProductSelection hook without wrapping your app with the <ProductSelectionProvider>.",
    );
  },
};

const ProductSelectionContext = React.createContext(contextInitialValue);

export const ProductSelectionProvider: React.FC<ProductSelectionProps> = ({ children }) => {
  const [products, setProducts] = useState({} as Record<string, ProductAndGroup>);

  const clearProducts = useCallback(() => setProducts({}), []);

  const setProductSelected = useCallback(
    (id: string, group: string, selected: boolean) =>
      setProducts((products) => {
        if (!selected) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [id]: productId, ...result } = products;
          return result;
        }

        return {
          ...products,
          [id]: { id, group },
        };
      }),
    [setProducts],
  );

  const value = useMemo(
    () => ({ products, clearProducts, setProductSelected }),
    [products, clearProducts, setProductSelected],
  );

  return <ProductSelectionContext.Provider value={value}>{children}</ProductSelectionContext.Provider>;
};

type ProductSelectionContextType = {
  products: Record<string, ProductAndGroup>;
  clearProducts: () => void;
  setProductSelected: (id: string, group: string, selected: boolean) => void;
};

const useProductSelection = (): ProductSelectionContextType =>
  useContext(ProductSelectionContext) as ProductSelectionContextType;

export default useProductSelection;
