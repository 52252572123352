import { QueryHandler } from "@lookiero/messaging.js";
import FashionLabsProductView from "../../model/FashionLabsProductView";
import CanProductBeCreated from "./CanProductBeCreated";

class CanProductBeCreatedHandler implements QueryHandler<CanProductBeCreated> {
  private readonly view: FashionLabsProductView;

  public constructor(view: FashionLabsProductView) {
    this.view = view;
  }

  public async handle({ group }: CanProductBeCreated): Promise<boolean> {
    return await this.view.canBeCreated(group);
  }
}

export default CanProductBeCreatedHandler;
