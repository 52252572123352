import { CommandHandler } from "@lookiero/messaging.js";
import OrderRepository from "../../model/OrderRepository";
import Order from "../../model/Order";
import CreateOrder from "./CreateOrder";

class CreateOrderHandler implements CommandHandler<CreateOrder> {
  private repository: OrderRepository;

  public constructor(repository: OrderRepository) {
    this.repository = repository;
  }

  public async execute({ orderId }: CreateOrder): Promise<void> {
    const instance = Order.createOrder(orderId);
    return this.repository.create(instance);
  }
}

export default CreateOrderHandler;
