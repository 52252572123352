import FashionLabsProductVariant from "@src/core/domain/fashionLabsProductVariant/model/FashionLabsProductVariant";
import { FashionLabsProductVariantDto } from "./FashionLabsProductVariantDto";

export const toFashionLabsProductVariant = (dto: FashionLabsProductVariantDto): FashionLabsProductVariant =>
  new FashionLabsProductVariant(
    dto.id,
    dto.product_id,
    dto.season,
    dto.publishable,
    dto.barcode,
    dto.reference,
    dto.color,
    dto.size,
    dto.media.map((mediaElement) => ({
      id: mediaElement.id,
      commercialUse: mediaElement.media_attributes.commercial_use,
      perspective: mediaElement.perspective,
      url: mediaElement.url,
    })),
    {
      id: dto.fashion_model.id,
      sizeId: dto.fashion_model.size_id,
      fitting: dto.fashion_model.fitting,
    },
    dto.selling_price_eur,
    dto.selling_price_gbp,
    dto.selling_price_sek,
    dto.selling_price_chf,
    dto.selling_prices,
  );
