import { AggregateRoot } from "@lookiero/messaging.js";
import EmployeeLoggedOut from "./EmployeeLoggedOut";
import EmployeeLoggedIn from "./EmployeeLoggedIn";

class Employee extends AggregateRoot {
  public authToken?: string;
  public username?: string;
  public roles?: string[];

  public constructor(authToken?: string, username?: string, roles?: string[]) {
    super();
    this.authToken = authToken;
    this.username = username;
    this.roles = roles;
  }

  public static logout(): Employee {
    const instance = new Employee();
    instance.record(new EmployeeLoggedOut());
    return instance;
  }

  public static create({
    authToken,
    username,
    roles,
  }: {
    authToken: string;
    username: string;
    roles: string[];
  }): Employee {
    const instance = new Employee(authToken, username, roles);
    instance.record(new EmployeeLoggedIn());
    return instance;
  }
}

export default Employee;
