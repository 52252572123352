import { CommandHandler } from "@lookiero/messaging.js";
import Availability from "../../model/Availability";
import AvailabilityRepository from "../../model/AvailabilityRepository";
import EditAvailability from "./EditAvailability";

class EditAvailabilityHandler implements CommandHandler<EditAvailability> {
  private repository: AvailabilityRepository;

  public constructor(repository: AvailabilityRepository) {
    this.repository = repository;
  }
  public execute({
    transactionId,
    products,
    channelName,
    availableSellingMarkets,
    unavailableSellingMarkets,
    reason,
  }: EditAvailability): Promise<void> {
    const availability = Availability.edit(
      transactionId,
      products,
      channelName,
      availableSellingMarkets,
      unavailableSellingMarkets,
      reason,
    );
    return this.repository.edit(availability);
  }
}

export default EditAvailabilityHandler;
