import { QueryHandler } from "@lookiero/messaging.js";
import FashionLabsProductView from "../../model/FashionLabsProductView";
import CountFashionLabsProductsByCriteria from "./CountFashionLabsProductsByCriteria";

class CountFashionLabsProductsByCriteriaHandler implements QueryHandler<CountFashionLabsProductsByCriteria> {
  private readonly view: FashionLabsProductView;

  public constructor(view: FashionLabsProductView) {
    this.view = view;
  }

  public async handle({ criteria }: CountFashionLabsProductsByCriteria): Promise<number> {
    return this.view.countByCriteria(criteria);
  }
}

export default CountFashionLabsProductsByCriteriaHandler;
