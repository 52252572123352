import HttpClient from "@src/shared/delivery/application/HttpClient";
import FashionModel from "@src/core/projection/fashionModel/model/FashionModel";
import FashionModelFitting from "@src/core/projection/fashionModel/model/FashionModelFitting";
import FashionModelView from "@src/core/projection/fashionModel/model/FashionModelRepository";
import { FashionModelDtoToFashionModel } from "./responseMapper";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";

class HttpFashionModelView implements FashionModelView {
  private httpClient: HttpClient;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async ofId(id: string): Promise<FashionModel> {
    try {
      const response = await this.httpClient.post("/get-fashion-labs-model-by-id", { id });

      if (!response.ok) {
        throw new Error("obtener la lista de modelos del servicio");
      }

      const { result } = await response.json();

      return result ? result.map(FashionModelDtoToFashionModel) : undefined;
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener la lista de modelos del servicio");
    }
  }

  public async list(): Promise<FashionModel[]> {
    try {
      const response = await this.httpClient.post("/search-fashion-models", {});

      if (!response.ok) {
        throw new Error("obtener la lista de modelos del servicio");
      }

      const { result } = await response.json();

      return result ? result.map(FashionModelDtoToFashionModel) : [];
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener la lista de modelos del servicio");
    }
  }

  public async listFittings(): Promise<FashionModelFitting[]> {
    try {
      const response = await this.httpClient.post("/search-fashion-model-fittings", {});

      if (!response.ok) {
        throw new Error("obtener la lista de fittings");
      }

      const { result } = await response.json();

      return result;
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener la lista de fittings");
    }
  }
}

export default HttpFashionModelView;
