import LoggerLayer from "./LoggerLayer";

abstract class LoggerEvent {
  public readonly layer: LoggerLayer;

  public constructor(layer: LoggerLayer) {
    this.layer = layer;
  }
}

export default LoggerEvent;
