import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import FashionLabsModelEdited from "@src/core/domain/fashionLabsModel/model/FashionLabsModelEdited";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";

class CreateNotificationWhenFashionLabsModelEdited implements ProcessManager<FashionLabsModelEdited> {
  private readonly commandBus!: CommandBus;

  public async process(): Promise<void> {
    this.commandBus.dispatch(
      new CreateNotification({
        level: NotificationLevel.SUCCESS,
        content: `Cambios sobre la modelo correctamente almacenados`,
      }),
    );
  }
}

export default CreateNotificationWhenFashionLabsModelEdited;
