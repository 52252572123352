import { EventBus } from "@lookiero/messaging.js";
import FashionLabsProductVariant from "@src/core/domain/fashionLabsProductVariant/model/FashionLabsProductVariant";
import FashionLabsProductVariantRepository from "@src/core/domain/fashionLabsProductVariant/model/FashionLabsProductVariantRepository";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import { toFashionLabsProductVariantDto } from "./requestBuilder";
import { toFashionLabsProductVariant } from "./responseMapper";

class HttpFashionLabsProductVariantRepository implements FashionLabsProductVariantRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async getById(id: string): Promise<FashionLabsProductVariant> {
    try {
      const response = await this.httpClient.post("/view-product-variant-by-id", { product_variant_id: id });

      if (!response.ok) {
        throw new Error("obtener el detalle del variant del producto del servicio");
      }

      const result = await response.json();

      return toFashionLabsProductVariant(result.result);
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("obtener el detalle del variant del producto del servicio");
    }
  }
  public async editVariant(productVariant: FashionLabsProductVariant): Promise<void> {
    try {
      const response = await this.httpClient.post(
        "/edit-product-variant",
        toFashionLabsProductVariantDto(productVariant),
      );

      if (!response.ok) {
        throw new Error("editar producto de fashion labs en el servicio");
      }
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("editar producto de fashion labs en el servicio");
    }
  }

  public async create(productVariant: FashionLabsProductVariant): Promise<void> {
    try {
      const response = await this.httpClient.post(
        "/create-product-variant",
        toFashionLabsProductVariantDto(productVariant),
      );

      if (!response.ok) {
        throw new Error("crear producto de fashion labs en el servicio");
      }

      this.eventBus.publish(productVariant.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("crear product variants de fashion labs en el servicio");
    }
  }
}

export default HttpFashionLabsProductVariantRepository;
