import { QueryHandler } from "@lookiero/messaging.js";
import Family from "../../model/Family";
import FamilyView from "../../model/FamilyView";
import ListFamilies from "./ListFamilies";

class ListFamiliesHandler implements QueryHandler<ListFamilies> {
  private readonly view: FamilyView;

  public constructor(view: FamilyView) {
    this.view = view;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: ListFamilies): Promise<Family[]> {
    return this.view.list();
  }
}

export default ListFamiliesHandler;
