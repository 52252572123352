import { Query } from "@lookiero/messaging.js";

class SearchFeatureValuesByFeature extends Query {
  public readonly featureId: string;

  public constructor(featureId: string) {
    super();
    this.featureId = featureId;
  }

  public messageName(): string {
    return "SearchFeatureValuesByFeature";
  }
}

export default SearchFeatureValuesByFeature;
