import React, { useMemo } from "react";
import { Text, TextVariant } from "@lookiero/react-ui-kit";
import useProductSelection from "../../../views/_behaviors/useProductSelection";
import "./product-selection-summary.css";

const ProductSelectionSummary: React.FC = () => {
  const { products, clearProducts } = useProductSelection();
  const selectedProductsCount = useMemo(() => Object.keys(products).length, [products]);

  return selectedProductsCount > 0 ? (
    <div className="product-selection-summary">
      <Text variant={TextVariant.BODY_SMALL}>
        {selectedProductsCount} seleccionado{selectedProductsCount > 1 ? "s" : ""}
      </Text>
      <div onClick={clearProducts}>
        <Text variant={TextVariant.LINK}>Borrar selección</Text>
      </div>
    </div>
  ) : null;
};

export default ProductSelectionSummary;
