import { Command } from "@lookiero/messaging.js";

class EditFamilyFeatureValues extends Command {
  public familyId: string;
  public featureValuesIds: string[];

  public constructor({ familyId, featureValuesIds }: { familyId: string; featureValuesIds: string[] }) {
    super();
    this.familyId = familyId;
    this.featureValuesIds = featureValuesIds;
  }

  public messageName(): string {
    return "EditFamilyFeatureValues";
  }
}

export default EditFamilyFeatureValues;
