import { QueryHandler } from "@lookiero/messaging.js";
import Product from "../../model/Product";
import ProductView from "../../model/ProductView";
import ViewProductDetail from "./ViewProductById";

class ViewProductDetailHandler implements QueryHandler<ViewProductDetail> {
  private readonly view: ProductView;

  public constructor(view: ProductView) {
    this.view = view;
  }

  public handle({ productId }: ViewProductDetail): Promise<Product> {
    return this.view.ofId(productId);
  }
}

export default ViewProductDetailHandler;
