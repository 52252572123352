import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";
import FamilyFeatureValuesEdited from "../../domain/family/model/FamilyFeatureValuesEdited";

class CreateNotificationWhenFamilyFeatureValuesEdited implements ProcessManager<FamilyFeatureValuesEdited> {
  private readonly commandBus!: CommandBus;

  public async process(): Promise<void> {
    this.commandBus.dispatch(
      new CreateNotification({
        level: NotificationLevel.SUCCESS,
        content: `Las features values se han editado correctamente`,
      }),
    );
  }
}

export default CreateNotificationWhenFamilyFeatureValuesEdited;
