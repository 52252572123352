import { QueryHandler } from "@lookiero/messaging.js";
import Feature from "../../model/Feature";
import FeatureView from "../../model/FeatureView";
import GetFeatures from "./GetFeatures";

class GetFeaturesHandler implements QueryHandler<GetFeatures> {
  private view: FeatureView;

  constructor(view: FeatureView) {
    this.view = view;
  }

  public handle(): Promise<Feature[]> {
    return this.view.get();
  }
}

export default GetFeaturesHandler;
