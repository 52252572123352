import React, { createContext, ReactNode, useContext } from "react";
import { DomainEvent, useAsk } from "@lookiero/messaging.js";
import EmployeeLoggedIn from "@src/core/domain/employee/model/EmployeeLoggedIn";
import GetCurrentEmployee from "@src/core/projection/employee/query/getCurrentEmployee/GetCurrentEmployee";
import Employee from "@src/core/projection/employee/model/Employee";
import EmployeeLoggedOut from "@src/core/domain/employee/model/EmployeeLoggedOut";

const EmployeeViewContext = createContext<Employee | undefined>(undefined);

interface EmployeeViewContextProviderProps {
  readonly children: ReactNode;
}

const EmployeeProvider: React.FC<EmployeeViewContextProviderProps> = ({
  children,
}: EmployeeViewContextProviderProps) => {
  const { data } = useAsk<Employee, Error>({
    query: new GetCurrentEmployee(),
    invalidation: (event: DomainEvent) => event instanceof EmployeeLoggedIn || event instanceof EmployeeLoggedOut,
  });

  return <EmployeeViewContext.Provider value={data}>{children}</EmployeeViewContext.Provider>;
};

export const useGetCurrentEmployee = (): Employee | undefined => {
  return useContext<Employee | undefined>(EmployeeViewContext);
};

export default EmployeeProvider;
