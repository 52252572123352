import { AggregateRoot } from "@lookiero/messaging.js";
import AvailabilityEdited from "./AvailabilityEdited";

class Availability extends AggregateRoot {
  public transactionId: string;
  public products: string[];
  public channelName: string;
  public availableSellingMarkets?: string[];
  public unavailableSellingMarkets?: string[];
  public reason: string;

  public constructor(
    transactionId: string,
    products: string[],
    channelName: string,
    availableSellingChannels: string[],
    unavailableSellingChannels: string[],
    reason: string,
  ) {
    super();
    this.transactionId = transactionId;
    this.products = products;
    this.channelName = channelName;
    this.availableSellingMarkets = availableSellingChannels;
    this.unavailableSellingMarkets = unavailableSellingChannels;
    this.reason = reason;
  }

  public static edit(
    transactionId: string,
    products: string[],
    channelName: string,
    availableSellingMarkets: string[],
    unavailableSellingMarkets: string[],
    reason: string,
  ): Availability {
    const instance = new Availability(
      transactionId,
      products,
      channelName,
      availableSellingMarkets,
      unavailableSellingMarkets,
      reason,
    );
    instance.record(new AvailabilityEdited(transactionId, channelName));

    return instance;
  }
}

export default Availability;
