import { useCallback } from "react";
import Environment from "@src/core/projection/environment/model/Environment";
import { execute, HotjarCommandType, initScript } from "./hotjarTracker";

interface UseHotJarFunctionArgs {
  readonly environment: Environment;
}

type UserInfoKey = string | number;
type UserInfoValue = string | number | Date | boolean;
type UserInfo = Record<UserInfoKey, UserInfoValue>;

interface UseHotJarFunctionResults {
  initialize: () => void;
  onEvent: (eventName: string) => void;
  onIdentify: ({ userId, userInfo }: { userId: string | null; userInfo: UserInfo }) => void;
  onStateChange: (relativePath: string) => void;
}

interface UseHotJarFunction {
  (args: UseHotJarFunctionArgs): UseHotJarFunctionResults;
}

const useHotjar: UseHotJarFunction = ({ environment }) => {
  const initialize = useCallback(
    () => initScript({ id: environment.hotjar.userId, version: environment.hotjar.version }),
    [environment],
  );

  const onEvent = useCallback((eventName: string) => execute(HotjarCommandType.EVENT, eventName), []);

  const onIdentify = useCallback(
    ({ userId, userInfo }: { userId: string | null; userInfo: UserInfo }) =>
      execute(HotjarCommandType.IDENTIFY, userId, userInfo),
    [],
  );

  const onStateChange = useCallback((relativePath: string) => execute(HotjarCommandType.IDENTIFY, relativePath), []);

  return { initialize, onEvent, onIdentify, onStateChange };
};

export default useHotjar;
