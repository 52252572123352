import { AggregateRoot } from "@lookiero/messaging.js";
import FeatureValueCreated from "./FeatureValueCreated";

class FeatureValue extends AggregateRoot {
  readonly featureValueId: string;
  readonly featureId: string;
  readonly value: string;

  constructor({ featureValueId, featureId, value }: { featureValueId: string; featureId: string; value: string }) {
    super();
    this.featureValueId = featureValueId;
    this.featureId = featureId;
    this.value = value;
  }

  public static create(featureValueId: string, featureId: string, value: string): FeatureValue {
    const instance = new FeatureValue({ featureValueId, featureId, value });
    instance.record(new FeatureValueCreated({ featureValueId, featureId, value }));
    return instance;
  }
}

export default FeatureValue;
