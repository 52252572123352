import HttpClient from "@src/shared/delivery/application/HttpClient";
import Feature from "@src/core/projection/feature/model/Feature";
import FeatureView from "@src/core/projection/feature/model/FeatureView";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import FeatureValue from "@src/core/projection/feature/model/FeatureValue";

class HttpFeatureView implements FeatureView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async get(): Promise<Feature[]> {
    try {
      const response = await this.httpClient.get("/get-features");

      if (!response.ok) {
        throw new Error("Obtener las <b>features</b> del servicio");
      }

      return await response.json();
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener las <b>features</b> del servicio");
    }
  }

  public async searchByFamily(familyId: string): Promise<Feature[]> {
    try {
      const response = await this.httpClient.post("/search-fashion-labs-features-by-family", { family_id: familyId });

      if (!response.ok) {
        throw new Error("Obtener las <b>features</b> del servicio");
      }

      const { result } = await response.json();

      return result;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener las <b>features</b> del servicio");
    }
  }
  public async searchByFeature(featureId: string): Promise<FeatureValue[]> {
    try {
      const response = await this.httpClient.post("/search-feature-values-by-feature", { feature_id: featureId });

      if (!response.ok) {
        throw new Error("Obtener las <b>feature values</b> del servicio");
      }

      return await response.json();
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("Obtener las <b>feature values</b> del servicio");
    }
  }
}

export default HttpFeatureView;
