import { DomainEvent } from "@lookiero/messaging.js";

class OrderPlaced extends DomainEvent {
  public orderId: string;
  public userId: string;
  public orderReference: string;

  public constructor(orderId: string, userId: string, orderReference: string) {
    super();
    this.orderId = orderId;
    this.userId = userId;
    this.orderReference = orderReference;
  }

  protected messageName(): string {
    return "OrderPlaced";
  }
}

export default OrderPlaced;
