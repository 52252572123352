import { Query } from "@lookiero/messaging.js";
import { FashionLabsProductsCriteria } from "../../model/FashionLabsProductView";

class CountFashionLabsProductsByCriteria extends Query {
  public readonly criteria: FashionLabsProductsCriteria;

  public constructor(criteria: FashionLabsProductsCriteria) {
    super();
    this.criteria = criteria;
  }

  public messageName(): string {
    return "CountFashionLabsProductsByCriteria";
  }
}

export default CountFashionLabsProductsByCriteria;
