import { Query } from "@lookiero/messaging.js";
import { ProductsCriteriaAvailability } from "../../model/ProductView";

class ViewProductVariantsForVisibility extends Query {
  public readonly groups: undefined | null | string | string[];
  public readonly families: undefined | null | string | string[];
  public readonly brands: undefined | null | string | string[];
  public readonly sizes: undefined | null | string | string[];
  public readonly colors: undefined | null | string | string[];
  public readonly seasons: undefined | null | string | string[];
  public readonly featureValues: undefined | null | string | string[];
  public readonly brandOrigins: undefined | null | string | string[];
  public readonly visibility: undefined | null | string;
  public readonly hasStock: undefined | null | string;

  public constructor({
    groups,
    families,
    brands,
    sizes,
    colors,
    seasons,
    featureValues,
    brandOrigins,
    visibility,
    hasStock,
  }: ProductsCriteriaAvailability) {
    super();
    this.groups = groups;
    this.families = families;
    this.brands = brands;
    this.sizes = sizes;
    this.colors = colors;
    this.seasons = seasons;
    this.featureValues = featureValues;
    this.brandOrigins = brandOrigins;
    this.visibility = visibility;
    this.hasStock = hasStock;
  }

  public messageName(): string {
    return "viewProductVariantsForVisibility";
  }
}

export default ViewProductVariantsForVisibility;
