import { QueryHandler } from "@lookiero/messaging.js";
import ViewOrderByOwnerAndStatus from "./ViewOrderByOwnerAndStatus";
import OrderView from "../../model/OrderView";
import { OrderProjection } from "../../model/OrderProjection";

class ViewOrderByOwnerAndStatusHandler implements QueryHandler<ViewOrderByOwnerAndStatus> {
  private readonly view: OrderView;

  public constructor(view: OrderView) {
    this.view = view;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: ViewOrderByOwnerAndStatus): Promise<OrderProjection> {
    return this.view.ViewOrderByOwnerAndStatus();
  }
}

export default ViewOrderByOwnerAndStatusHandler;
