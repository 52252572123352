import React from "react";
import { Button, ButtonVariant, Text, TextVariant } from "@lookiero/react-ui-kit";
import "./error-reporter.css";

interface ErrorReporterProps {
  readonly onReport: () => void;
}

const ErrorReporter: React.FC<ErrorReporterProps> = ({ onReport }: ErrorReporterProps) => (
  <div className="error-reporter">
    <Text className="error-reporter__title" variant={TextVariant.HEADING_2} tag="h2">
      OOOOOPS
    </Text>
    <Text className="error-reporter__description" variant={TextVariant.BODY} tag="div">
      <p>
        Cuéntanos qué ha pasado, qué estabas haciendo o cualquier otra información que consideres importante. Toda
        información que incluyas nos resultará de gran ayuda.
      </p>
      <p>¡Muchas gracias!</p>
    </Text>
    <Button variant={ButtonVariant.PRIMARY} onClick={onReport}>
      Reportar feedback
    </Button>
  </div>
);

export default ErrorReporter;
