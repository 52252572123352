import { DomainEvent } from "@lookiero/messaging.js";

class FashionLabsModelCreated extends DomainEvent {
  public modelId: string;

  public constructor(modelId: string) {
    super();
    this.modelId = modelId;
  }

  public messageName(): string {
    return "FashionLabsModelCreated";
  }
}

export default FashionLabsModelCreated;
