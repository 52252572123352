import { EventBus } from "@lookiero/messaging.js";
import Family from "@src/core/domain/family/model/Family";
import FamilyRepository from "@src/core/domain/family/model/FamilyRepository";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";

class HttpFamilyRepository implements FamilyRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async create(family: Family): Promise<void> {
    try {
      const body = {
        category: family.category,
        segment: family.segment,
        id: family.familyId,
        name: family.familyName,
        letter: family.letter,
      };
      const response = await this.httpClient.post("/create-family", body);

      if (!response.ok) {
        throw new Error("crear familia en el servicio");
      }

      this.eventBus.publish(family.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("crear familia en el servicio");
    }
  }
  public async cloneFeatureValues(family: Family): Promise<void> {
    try {
      const body = {
        familyId: family.familyId,
        originFamilyId: family.cloneFamilyId,
      };

      const response = await this.httpClient.post("/clone-family-feature-values", body);

      if (!response.ok) {
        throw new Error("clonar familia en el servicio");
      }

      this.eventBus.publish(family.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("clonar familia en el servicio");
    }
  }

  public async editFeatureValues(family: Family): Promise<void> {
    try {
      const body = {
        familyId: family.familyId,
        featureValues: family.featureValuesIds,
      };

      const response = await this.httpClient.post("/edit-family-feature-values", body);

      if (!response.ok) {
        throw new Error("editar familia en el servicio");
      }

      this.eventBus.publish(family.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("editar familia en el servicio");
    }
  }
}

export default HttpFamilyRepository;
