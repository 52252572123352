import { DomainEvent } from "@lookiero/messaging.js";

class VisibilityEdited extends DomainEvent {
  public transactionId: string;

  public constructor(transactionId: string) {
    super();
    this.transactionId = transactionId;
  }

  protected messageName(): string {
    return "VisibilityEdited";
  }
}

export default VisibilityEdited;
