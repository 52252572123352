import { DomainEvent } from "@lookiero/messaging.js";

class FamilyCreated extends DomainEvent {
  public familyId: string;
  public category: string;
  public segment: string;
  public letter: string;
  public familyName: string;

  public constructor({
    familyId,
    category,
    segment,
    letter,
    familyName,
  }: {
    familyId: string;
    category: string;
    segment: string;
    letter: string;
    familyName: string;
  }) {
    super();
    this.familyId = familyId;
    this.category = category;
    this.segment = segment;
    this.letter = letter;
    this.familyName = familyName;
  }

  public messageName(): string {
    return "FamilyCreated";
  }
}

export default FamilyCreated;
