import { QueryHandler } from "@lookiero/messaging.js";
import { toISO } from "@src/shared/domain/Date/DateFormatter";
import ProductView from "../../model/ProductView";
import CountProducts from "./CountProducts";

class CountProductsHandler implements QueryHandler<CountProducts> {
  private readonly view: ProductView;

  public constructor(view: ProductView) {
    this.view = view;
  }

  public handle({
    search,
    market,
    families,
    brands,
    sizes,
    colors,
    seasons,
    date = toISO(new Date()),
    featureValues,
    brandOrigins,
    visibility,
    hasStock,
    reference,
  }: CountProducts): Promise<number> {
    return this.view.countByCriteria({
      groups: search,
      market,
      families,
      brands,
      sizes,
      colors,
      seasons,
      date,
      featureValues,
      brandOrigins,
      visibility,
      hasStock,
      reference,
    });
  }
}

export default CountProductsHandler;
