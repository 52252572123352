import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";
import OrderPlaced from "@src/core/domain/order/model/OrderPlaced";

class CreateNotificationWhenOrderPlaced implements ProcessManager<OrderPlaced> {
  private readonly commandBus!: CommandBus;

  public async process({ orderReference }: OrderPlaced): Promise<void> {
    this.commandBus.dispatch(
      new CreateNotification({
        level: NotificationLevel.SUCCESS,
        content: `Pedido ${orderReference} se ha creado correctamente.`,
      }),
    );
  }
}

export default CreateNotificationWhenOrderPlaced;
