import { CommandHandler } from "@lookiero/messaging.js";
import FashionLabsModel from "../../model/FashionLabsModel";
import FashionLabsModelRepository from "../../model/FashionLabsModelRepository";
import CreateFashionLabsModel from "./CreateFashionLabsModel";

class FashionLabsModelHandler implements CommandHandler<CreateFashionLabsModel> {
  private fashionLabsModelRepository: FashionLabsModelRepository;

  public constructor(fashionLabsModelRepository: FashionLabsModelRepository) {
    this.fashionLabsModelRepository = fashionLabsModelRepository;
  }

  public async execute({ modelId, modelName, attributes }: CreateFashionLabsModel): Promise<void> {
    const fashionLabsModel = FashionLabsModel.create(modelId, modelName, attributes);

    if (!fashionLabsModel) {
      throw new Error(`No hay ningun modelo con el id ${modelName}`);
    }

    await this.fashionLabsModelRepository.create(fashionLabsModel);
  }
}

export default FashionLabsModelHandler;
