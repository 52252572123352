import { DomainEvent } from "@lookiero/messaging.js";

class OrderCreated extends DomainEvent {
  public orderId: string;

  public constructor(orderId: string) {
    super();
    this.orderId = orderId;
  }

  protected messageName(): string {
    return "OrderCreated";
  }
}

export default OrderCreated;
