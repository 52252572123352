import { ProcessManager } from "@lookiero/messaging.js";
import ErrorThrown from "@src/core/domain/error/model/ErrorThrown";
import Logger from "@src/core/application/logger/model/Logger";
import LoggerError from "@src/core/application/logger/model/LoggerError";
import LoggerLayer from "@src/core/application/logger/model/LoggerLayer";

class LogWhenError implements ProcessManager<ErrorThrown> {
  private readonly logger: Logger;

  constructor(logger: Logger) {
    this.logger = logger;
  }

  public async process({ error }: ErrorThrown): Promise<void> {
    this.logger.error(new LoggerError({ layer: LoggerLayer.APPLICATION, error }));
  }
}

export default LogWhenError;
