import { DomainEvent } from "@lookiero/messaging.js";

class EmployeeAuthenticated extends DomainEvent {
  public authToken: string;
  public username: string;
  public roles: string[];

  public constructor({ authToken, username, roles }: { authToken: string; username: string; roles: string[] }) {
    super();
    this.username = username;
    this.authToken = authToken;
    this.roles = roles;
  }

  public messageName(): string {
    return "EmployeeAuthenticated";
  }
}

export default EmployeeAuthenticated;
