import { OrderProjection } from "@src/core/projection/order/model/OrderProjection";
import OrderView from "@src/core/projection/order/model/OrderView";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import { responseMapper } from "./responseMapper";

class HttpOrderByOwnerAndStatusView implements OrderView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async ViewOrderByOwnerAndStatus(): Promise<OrderProjection> {
    try {
      const response = await this.httpClient.post("/view-logged-in-user-draft-order", { per_page: -1 });

      if (!response.ok) {
        throw new Error("Obtener los usuarios del servicio");
      }

      const result = await response.json();
      return responseMapper(result);
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("Obtener los usuarios del servicio");
    }
  }
}

export default HttpOrderByOwnerAndStatusView;
