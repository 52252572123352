import HttpClient from "@src/shared/delivery/application/HttpClient";
import Color from "@src/core/projection/color/model/Color";
import ColorView from "@src/core/projection/color/model/ColorView";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";

class HttpColorView implements ColorView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Color[]> {
    try {
      const response = await this.httpClient.post("/search-colors", { per_page: -1 });

      if (!response.ok) {
        throw new Error("obtener los colores del servicio");
      }

      const { result } = await response.json();
      return result;
    } catch (error) {
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener los colores del servicio");
    }
  }
}

export default HttpColorView;
