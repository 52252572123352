import { DomainEvent } from "@lookiero/messaging.js";

class FamilyFeatureValuesCloned extends DomainEvent {
  public familyId: string;
  public cloneFamilyId: string;

  public constructor({ familyId, cloneFamilyId }: { familyId: string; cloneFamilyId: string }) {
    super();
    this.familyId = familyId;
    this.cloneFamilyId = cloneFamilyId;
  }

  public messageName(): string {
    return "FamilyFeatureValuesCloned";
  }
}

export default FamilyFeatureValuesCloned;
