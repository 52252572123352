import { EventBus } from "@lookiero/messaging.js";
import Media from "@src/core/domain/media/model/Media";
import MediaRepository from "@src/core/domain/media/model/MediaRepository";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";

class HttpMediaRepository implements MediaRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async remove(media: Media): Promise<void> {
    try {
      const response = await this.httpClient.post("/remove-media", { id: media.mediaId });

      if (!response.ok) {
        throw new Error("borrar media del servicio");
      }

      this.eventBus.publish(media.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("borrar media del servicio");
    }
  }

  public async markForCommercialUse(media: Media): Promise<void> {
    try {
      const response = await this.httpClient.post("/mark-media-for-commercial-use", { media_id: media.mediaId });

      if (!response.ok) {
        throw new Error("marcar media para uso comercial");
      }

      this.eventBus.publish(media.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("marcar media para uso comercial");
    }
  }

  public async unmarkForCommercialUse(media: Media): Promise<void> {
    try {
      const response = await this.httpClient.post("/unmark-media-for-commercial-use", { media_id: media.mediaId });

      if (!response.ok) {
        throw new Error("desmarcar media para uso comercial");
      }

      this.eventBus.publish(media.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("desmarcar media para uso comercial");
    }
  }
}

export default HttpMediaRepository;
