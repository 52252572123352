import { QueryHandler } from "@lookiero/messaging.js";
import FashionModel from "../../model/FashionModel";
import FashionModelView from "../../model/FashionModelRepository";
import ListFashionModels from "./ListFashionModels";

class ListFashionModelsHandler implements QueryHandler<ListFashionModels> {
  private readonly view: FashionModelView;

  public constructor(view: FashionModelView) {
    this.view = view;
  }

  public async handle(): Promise<FashionModel[]> {
    return await this.view.list();
  }
}

export default ListFashionModelsHandler;
