import { CommandHandler } from "@lookiero/messaging.js";
import Notification from "../../model/Notification";
import NotificationRepository from "../../model/NotificationRepository";
import RemoveNotification from "./RemoveNotification";

class RemoveNotificationHandler implements CommandHandler<RemoveNotification> {
  private readonly repository: NotificationRepository;

  public constructor(repository: NotificationRepository) {
    this.repository = repository;
  }

  public async execute({ notificationId }: RemoveNotification): Promise<void> {
    const notification: Notification = await this.repository.get(notificationId);

    if (!notification) {
      return;
    }

    notification.remove();

    return this.repository.remove(notification);
  }
}

export default RemoveNotificationHandler;
