import { Query } from "@lookiero/messaging.js";
import { toISO } from "@src/shared/domain/Date/DateFormatter";
import Catalog from "@src/ui/_config/catalog";

type ListProductsFieldType = undefined | string | string[];

interface ListProductsParameters {
  search: undefined | string;
  families: ListProductsFieldType;
  brands: ListProductsFieldType;
  seasons: ListProductsFieldType;
  sizes: ListProductsFieldType;
  colors: ListProductsFieldType;
  climatologies: ListProductsFieldType;
  visibility: undefined | string;
  market: ListProductsFieldType;
  date: undefined | string;
  page: number;
  perPage: undefined | number;
  featureValues: ListProductsFieldType;
  brandOrigins: ListProductsFieldType;
  hasStock: undefined | string;
  reference: undefined | string;
}

class ListProducts extends Query {
  public readonly search: undefined | string;
  public readonly families: ListProductsFieldType;
  public readonly brands: ListProductsFieldType;
  public readonly seasons: ListProductsFieldType;
  public readonly sizes: ListProductsFieldType;
  public readonly colors: ListProductsFieldType;
  public readonly visibility: undefined | string;
  public readonly climatologies: ListProductsFieldType;
  public readonly market: ListProductsFieldType;
  public readonly date: string;
  public readonly page: number;
  public readonly perPage: number;
  public readonly featureValues: ListProductsFieldType;
  public readonly brandOrigins: ListProductsFieldType;
  public readonly hasStock: undefined | string;
  public readonly reference: undefined | string;

  public constructor({
    search,
    families,
    brands,
    seasons,
    sizes,
    colors,
    climatologies,
    visibility,
    market,
    date = toISO(new Date()),
    page = 1,
    perPage = Catalog.DEFAULT_PER_PAGE,
    featureValues,
    brandOrigins,
    hasStock,
    reference,
  }: ListProductsParameters) {
    super();
    this.search = search;
    this.families = families;
    this.brands = brands;
    this.seasons = seasons;
    this.sizes = sizes;
    this.colors = colors;
    this.visibility = visibility;
    this.climatologies = climatologies;
    this.market = market;
    this.date = date;
    this.page = page;
    this.perPage = perPage;
    this.featureValues = featureValues;
    this.brandOrigins = brandOrigins;
    this.hasStock = hasStock;
    this.reference = reference;
  }

  public messageName(): string {
    return "ListProducts";
  }
}

export default ListProducts;
