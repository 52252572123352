import { Command } from "@lookiero/messaging.js";

class RemoveMedia extends Command {
  public readonly mediaId: string;

  public constructor(mediaId: string) {
    super();
    this.mediaId = mediaId;
  }

  public messageName(): string {
    return "RemoveMedia";
  }
}

export default RemoveMedia;
