import { CommandHandler } from "@lookiero/messaging.js";
import OrderRepository from "../../model/OrderRepository";
import Order from "../../model/Order";
import RemoveOrder from "./RemoveOrderItem";

class RemoveOrderItemHandler implements CommandHandler<RemoveOrder> {
  private repository: OrderRepository;

  public constructor(repository: OrderRepository) {
    this.repository = repository;
  }

  public async execute({ orderId, orderItemId, quantity }: RemoveOrder): Promise<void> {
    const instance = Order.removeOrderItem(orderId, orderItemId, quantity);
    return this.repository.removeOrderItem(instance);
  }
}

export default RemoveOrderItemHandler;
