import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import FashionLabsProductEdited from "@src/core/domain/fashionLabsProduct/model/FashionLabsProductEdited";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";
import HttpFashionLabsProductView from "../../infrastructure/projection/fashionLabsProduct/model/HttpFashionLabsProductView";

class CreateNotificationWhenFashionLabsProductEdited implements ProcessManager<FashionLabsProductEdited> {
  private readonly commandBus!: CommandBus;
  private readonly repository: HttpFashionLabsProductView;

  constructor(repository: HttpFashionLabsProductView) {
    this.repository = repository;
  }

  public async process({ productGroup }: FashionLabsProductEdited): Promise<void> {
    setTimeout(async () => {
      const fashionLabsProduct = await this.repository.byGroup(productGroup);
      let level = NotificationLevel.SUCCESS;
      let content = `El producto con grupo <b onmouseover='this.style.color="grey"; this.style.textDecoration="underline";' onmouseout='this.style.color="black"; this.style.textDecoration="none";' ><a href="${window.location.pathname}/${productGroup}">${productGroup}</a></b> ha sido <b>editado</b> con éxito`;

      if (!fashionLabsProduct) {
        return [];
      }

      const productVariantsColorWithoutMainPerspectives = [
        ...new Set(
          fashionLabsProduct.productVariants
            .filter((productVariant) => {
              const hasMainPerspective = productVariant.media.some((media) => media.perspective === "MAIN");
              return !hasMainPerspective;
            })
            .map((productVariant) => {
              return productVariant.color.code;
            }),
        ),
      ];

      if (productVariantsColorWithoutMainPerspectives.length > 0) {
        level = NotificationLevel.ERROR;
        content = `El producto con grupo <b onmouseover='this.style.color="grey"; this.style.textDecoration="underline";' onmouseout='this.style.color="black"; this.style.textDecoration="none";' ><a href="${window.location.pathname}/${productGroup}">${productGroup}</a></b> no ha sido <b>editado</b> con éxito.<br><br>Los siguientes colores no tienen perspectiva principal: <b>${productVariantsColorWithoutMainPerspectives.join(", ")}</b>`;
      }

      this.commandBus.dispatch(
        new CreateNotification({
          level,
          content,
        }),
      );
    }, 300);
  }
}

export default CreateNotificationWhenFashionLabsProductEdited;
