import FashionLabsModel from "@src/core/domain/fashionLabsModel/model/FashionLabsModel";

type Attributes = {
  readonly height?: string;
  readonly weight?: string;
  readonly braSize?: string;
  readonly upperSize?: string;
  readonly bottomSize?: string;
  readonly hipOutline?: string;
  readonly chestOutline?: string;
  readonly waistOutline?: string;
  readonly shoes?: string;
};

type CreateFashionLabsModelDto = {
  readonly id: string;
  readonly name: string;
  readonly attributes: Attributes;
};

type EditFashionLabsModelDto = {
  readonly id: string;
  readonly name: string;
  readonly attributes: Attributes;
};

const createFashionLabsModelDtoFromFashionLabsModel = (model: FashionLabsModel): CreateFashionLabsModelDto => ({
  id: model.modelId,
  name: model.modelName,
  attributes: model.attributes,
});

const editFashionLabsModelDtoFromFashionLabsModel = (model: FashionLabsModel): EditFashionLabsModelDto => ({
  id: model.modelId,
  name: model.modelName,
  attributes: model.attributes,
});

export { createFashionLabsModelDtoFromFashionLabsModel, editFashionLabsModelDtoFromFashionLabsModel };
