import MediaService from "@src/core/application/media/model/MediaService";

class CdnMediaService implements MediaService {
  private cdnUrl: string;

  public constructor(cdnUrl: string) {
    this.cdnUrl = cdnUrl;
  }

  public getUrlByIdAndName(id: string, name: string): string {
    const [, folder, subfolder] = /(.{2})(.{2}).+/.exec(id) || [];
    const [, extension] = /.+(\..+)/.exec(name) || [];

    return `${this.cdnUrl}/${folder}/${subfolder}/${id}${extension}`;
  }

  public appendFormattingQueryParams(url: string, width?: number): string {
    if (Boolean(width)) {
      return `${url}?f=auto&w=${width}`;
    }

    return `${url}?f=auto`;
  }
}

export default CdnMediaService;
