import { Command } from "@lookiero/messaging.js";

class CloneFamilyFeatureValues extends Command {
  public familyId: string;
  public cloneFamilyId: string;

  public constructor({ familyId, cloneFamilyId }: { familyId: string; cloneFamilyId: string }) {
    super();
    this.familyId = familyId;
    this.cloneFamilyId = cloneFamilyId;
  }

  public messageName(): string {
    return "CloneFamilyFeatureValues";
  }
}

export default CloneFamilyFeatureValues;
