import { Command } from "@lookiero/messaging.js";

class AddOrderItem extends Command {
  public readonly orderId: string;
  public readonly orderItemId: string;
  public readonly quantity: number;

  public constructor(orderId: string, orderItemId: string, quantity: number) {
    super();
    this.orderId = orderId;
    this.orderItemId = orderItemId;
    this.quantity = quantity;
  }

  public messageName(): string {
    return "AddOrderItem";
  }
}

export default AddOrderItem;
