import React, { useCallback, useEffect } from "react";
import { DomainEvent, useAsk, useDispatch } from "@lookiero/messaging.js";
import ThrowError from "@src/core/domain/error/command/throwError/ThrowError";
import RemoveNotification from "@src/core/domain/notification/command/removeNotification/RemoveNotification";
import NotificationCreated from "@src/core/domain/notification/model/NotificationCreated";
import NotificationRemoved from "@src/core/domain/notification/model/NotificationRemoved";
import Notification from "@src/core/projection/notification/model/Notification";
import ListNotifications from "@src/core/projection/notification/query/listNotifications/ListNotifications";
import instanceOfClass from "@src/shared/_types/instaceOfClass";
import Notifications from "../../component-library/organisms/Notifications/Notifications";
import NotificationsConfig from "../../_config/notifications";

const NotificationsContainer: React.FC = () => {
  const { data: notifications = [] } = useAsk<Notification[], Error>({
    query: new ListNotifications(),
    invalidation: (event: DomainEvent) => instanceOfClass(event, [NotificationCreated, NotificationRemoved]),
    options: { staleTime: Infinity, retry: 0, onError: (error: Error) => dispatch(new ThrowError(error)) },
  });

  const dispatch = useDispatch();
  const removeNotification = useCallback(
    (notificationId: string) => dispatch(new RemoveNotification(notificationId)),
    [dispatch],
  );

  useEffect(() => {
    const now = Date.now();
    const timeouts = notifications.map((notification: Notification) =>
      setTimeout(
        () => removeNotification(notification.id),
        Math.max(0, (notification.timestamp || 0) + NotificationsConfig.DEFAULT_DURATION - now),
      ),
    );
    return (): void => timeouts.forEach((timeout: NodeJS.Timeout) => clearTimeout(timeout));
  }, [notifications, removeNotification]);

  return <Notifications notifications={notifications} onCancel={removeNotification} />;
};

export default NotificationsContainer;
