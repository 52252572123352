import React from "react";
import { createRoot } from "react-dom/client";
import { MessagingRoot } from "@lookiero/messaging.js";
import * as serviceWorker from "./serviceWorker";
import Environment from "./core/projection/environment/model/Environment";
import Logger from "./core/application/logger/model/Logger";
import { fromStringToSymbol } from "./core/_config/_data-transfrom/fromStringToSymbol";
import "./ui/component-library/_tokens/index";
import "./index.css";
import "@lookiero/react-ui-kit/lib/styles/styles.css";
import "react-medium-image-zoom/dist/styles.css";
import Root from "./ui/views/Root";
import { bootstrap } from "./core/_config/bootstrap";

(async function (): Promise<void> {
  const container = await bootstrap();

  const rootContainer = document.getElementById("root") as HTMLElement;
  const root = createRoot(rootContainer);

  root.render(
    <Root
      logger={container.get<Logger>(fromStringToSymbol("Logger"))}
      environment={container.get<Environment>(fromStringToSymbol("Environment"))}
      i18nEndpoint={container.get<(locale: string) => string>(fromStringToSymbol("TranslationEndpoint"))}
      MessagingRootComponent={container.get<MessagingRoot>(fromStringToSymbol("MessagingRootComponent"))}
    />,
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
