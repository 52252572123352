import EmployeeRepository from "@src/core/domain/employee/model/EmployeeRepository";
import { EventBus } from "@lookiero/messaging.js";
import Employee from "@src/core/domain/employee/model/Employee";
import StorageClient from "@src/shared/delivery/application/StorageClient";

type JsonEmployee = {
  authToken: string;
  username: string;
  roles: string[];
};
class InMemoryEmployeeRepository implements EmployeeRepository {
  private eventBus!: EventBus;

  private static readonly EMPLOYEE_KEY = "employee";

  private readonly employeeStorageClient: StorageClient;

  constructor(storage: StorageClient) {
    this.employeeStorageClient = storage;
  }

  public async save(employee: Employee): Promise<void> {
    const employeeToSave = InMemoryEmployeeRepository.employeeToJson(employee);
    await this.employeeStorageClient.store(InMemoryEmployeeRepository.EMPLOYEE_KEY, employeeToSave);

    this.eventBus.publish(employee.recordedEvents());

    return Promise.resolve();
  }

  public async remove(employee: Employee): Promise<void> {
    await this.employeeStorageClient.delete(InMemoryEmployeeRepository.EMPLOYEE_KEY);

    this.eventBus.publish(employee.recordedEvents());

    return Promise.resolve();
  }

  public async getCurrentEmployee(): Promise<Employee | undefined> {
    return await this.employeeStorageClient
      .get(InMemoryEmployeeRepository.EMPLOYEE_KEY)
      .then(InMemoryEmployeeRepository.jsonToEmployee);
  }

  private static employeeToJson(employee: Employee): JsonEmployee {
    return {
      authToken: employee.authToken || "",
      username: employee.username || "",
      roles: employee.roles || [],
    };
  }

  private static jsonToEmployee(jsonEmployee?: JsonEmployee): Employee | undefined {
    return jsonEmployee ? new Employee(jsonEmployee.authToken, jsonEmployee.username, jsonEmployee.roles) : undefined;
  }
}

export default InMemoryEmployeeRepository;
