import { QueryHandler } from "@lookiero/messaging.js";
import Color from "../../model/Color";
import ColorView from "../../model/ColorView";
import ListColors from "./ListColors";

class ListColorsHandler implements QueryHandler<ListColors> {
  private readonly view: ColorView;

  public constructor(view: ColorView) {
    this.view = view;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: ListColors): Promise<Color[]> {
    return this.view.list();
  }
}

export default ListColorsHandler;
