import { EventBus } from "@lookiero/messaging.js";
import FeatureValue from "@src/core/domain/featureValue/model/FeatureValue";
import FeatureValueRepository from "@src/core/domain/featureValue/model/FeatureValueRepository";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import { toFeatureValueDto } from "./requestBuilder";

class HttpFeatureValueRepository implements FeatureValueRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async create(featureValue: FeatureValue): Promise<void> {
    try {
      const response = await this.httpClient.post("/create-feature-value", toFeatureValueDto(featureValue));

      if (!response.ok) {
        throw new Error("crear feature value");
      }

      this.eventBus.publish(featureValue.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("crear feature value");
    }
  }
}

export default HttpFeatureValueRepository;
