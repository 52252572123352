import { QueryHandler } from "@lookiero/messaging.js";
import FeatureView from "../../model/FeatureView";
import SearchFeatureValuesByFeature from "@src/core/projection/feature/query/searchFeatureValuesByFeature/SearchFeatureValuesByFeature";
import FeatureValue from "@src/core/projection/feature/model/FeatureValue";

class SearchFeatureValuesByFeatureHandler implements QueryHandler<SearchFeatureValuesByFeature> {
  private view: FeatureView;

  constructor(view: FeatureView) {
    this.view = view;
  }

  public handle({ featureId }: SearchFeatureValuesByFeature): Promise<FeatureValue[]> {
    return this.view.searchByFeature(featureId);
  }
}

export default SearchFeatureValuesByFeatureHandler;
