import Catalog from "@src/ui/_config/catalog";
import Product from "@src/core/projection/product/model/Product";
import ProductAndGroup from "@src/core/projection/product/model/ProductAndGroup";
import ProductPreview from "@src/core/projection/product/model/ProductPreview";
import ProductView, {
  PagedProductCriteria,
  ProductCriteria,
  ProductsCriteriaAvailability,
} from "@src/core/projection/product/model/ProductView";
import HttpClient from "../../../../../shared/delivery/application/HttpClient";
import {
  createCountProductsRequestFromCriteria,
  createListProductsAvailabilityRequestFromCriteria,
  createListProductsRequestFromCriteria,
} from "./requestBuilder";
import {
  ProductDto,
  productFromDto,
  productPreviewFromDto,
  productPreviewToAvailabilityFromDto,
  ProductVariantDto,
  productVariantPreviewToVisibilityFromDto,
} from "./responseMapper";
import ProductsToAvailabilityPreview from "@src/core/projection/product/model/ProductAvailabilityPreview";
import ProductVariantVisibilityPreview from "@src/core/projection/product/model/ProductVariantVisibilityPreview";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";

class HttpProductView implements ProductView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async ofId(id: string): Promise<Product> {
    const requestParameters = {
      product_id: id,
    };

    try {
      const response = await this.httpClient.post("/view-product-by-id", requestParameters);

      if (!response.ok) {
        throw new Error("obtener el detalle del producto del servicio");
      }

      const result = await response.json();

      return productFromDto(result.result);
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("obtener el detalle del producto del servicio");
    }
  }

  public async searchByCriteria(criteria: PagedProductCriteria): Promise<ProductPreview[]> {
    try {
      const response = await this.httpClient.post(
        "/v2/search-products-by-criteria",
        createListProductsRequestFromCriteria(criteria),
      );

      if (!response.ok) {
        throw new Error("obtener los productos del servicio");
      }

      const result = await response.json();

      return result ? result.map((dto: ProductDto) => productPreviewFromDto(dto)) : [];
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener los productos del servicio");
    }
  }

  public async countByCriteria(criteria: ProductCriteria): Promise<number> {
    try {
      const response = await this.httpClient.post(
        "/v2/count-products-by-criteria",
        createCountProductsRequestFromCriteria(criteria),
      );

      if (!response.ok) {
        throw new Error("obtener el número de productos del servicio");
      }

      const result = await response.json();

      return result;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("obtener el número de productos del servicio");
    }
  }

  public async searchByCriteriaToAvailability(
    criteria: ProductsCriteriaAvailability,
  ): Promise<ProductsToAvailabilityPreview[]> {
    try {
      const response = await this.httpClient.post(
        "/search-products-by-criteria-lite",
        createListProductsAvailabilityRequestFromCriteria(criteria),
      );

      if (!response.ok) {
        throw new Error("obtener los productos del servicio para disponibilidad");
      }

      const { result } = await response.json();

      return result ? result.map((dto: ProductDto) => productPreviewToAvailabilityFromDto(dto)) : [];
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("obtener los productos del servicio para disponiblidad");
    }
  }

  public async searchByCriteriaToVisibility(
    criteria: ProductsCriteriaAvailability,
  ): Promise<ProductVariantVisibilityPreview[]> {
    try {
      const response = await this.httpClient.post(
        "/search-product-variants-by-criteria-lite",
        createListProductsAvailabilityRequestFromCriteria(criteria),
      );

      if (!response.ok) {
        throw new Error("obtener los variants del servicio para disponibilidad");
      }

      const { result } = await response.json();

      return result
        ? (result as ProductVariantDto[]).map((dto: ProductVariantDto) => productVariantPreviewToVisibilityFromDto(dto))
        : [];
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("obtener los productos del servicio para visibilidad");
    }
  }

  public async listForGroupPartials(partials: string[]): Promise<Record<string, ProductAndGroup[]>> {
    if (!partials || partials.length === 0) {
      return Promise.resolve({});
    }

    const requests = partials.map(async (partial) => {
      const requestParameters = { group_partial: partial, size: Catalog.AUTOCOMPLETE_PAGE };

      try {
        const response = await this.httpClient.post("/autocomplete-product-groups", requestParameters);

        if (!response.ok) {
          console.error({ response });
          throw new Error("obtener los productos (autocompletado) del servicio");
        }

        const result = await response.json();

        return result.result
          ? result.result.map((product: { productId: string; group: string }) => ({
              id: product.productId,
              group: product.group,
            }))
          : null;
      } catch (error) {
        console.error({ error });
        throw error instanceof UnAuthorizedEmployeeError
          ? error
          : new Error("obtener los productos (autocompletado) del servicio");
      }
    });

    return new Promise((resolve, reject) => {
      Promise.all(requests).then(
        (results) => resolve(results.reduce((acc, result, index) => ({ ...acc, [partials[index]]: result }), {})),
        (error) => reject(error),
      );
    });
  }
}

export default HttpProductView;
