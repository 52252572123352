import { Command } from "@lookiero/messaging.js";

class PlaceOrder extends Command {
  public readonly orderId: string;
  public readonly userId: string;
  public readonly orderReference: string;

  public constructor(orderId: string, userId: string, orderReference: string) {
    super();
    this.orderId = orderId;
    this.userId = userId;
    this.orderReference = orderReference;
  }

  public messageName(): string {
    return "PlaceOrder";
  }
}

export default PlaceOrder;
