import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";
import OrderItemRemoved from "@src/core/domain/order/model/OrderItemRemoved";

class CreateNotificationWhenOrderItemRemoved implements ProcessManager<OrderItemRemoved> {
  private readonly commandBus!: CommandBus;

  public async process({ orderId }: OrderItemRemoved): Promise<void> {
    this.commandBus.dispatch(
      new CreateNotification({
        level: NotificationLevel.SUCCESS,
        content: `Pedido ${orderId} ha sido eliminado correctamente`,
      }),
    );
  }
}

export default CreateNotificationWhenOrderItemRemoved;
