import React, { ReactNode, useContext } from "react";
import { invariant } from "ts-invariant";
import { UseQueryResult } from "react-query";
import { DomainEvent, useAsk, useDispatch } from "@lookiero/messaging.js";
import ThrowError from "@src/core/domain/error/command/throwError/ThrowError";
import Brand from "@src/core/projection/brand/model/Brand";
import Color from "@src/core/projection/color/model/Color";
import Size from "@src/core/projection/size/model/Size";
import Market from "@src/core/projection/market/model/Market";
import Family from "@src/core/projection/family/model/Family";
import Season from "@src/core/projection/season/model/Season";
import FashionModel from "@src/core/projection/fashionModel/model/FashionModel";
import FashionModelFitting from "@src/core/projection/fashionModel/model/FashionModelFitting";
import ListBrands from "@src/core/projection/brand/query/listBrands/ListBrands";
import ListColors from "@src/core/projection/color/query/listColors/ListColors";
import ListSizes from "@src/core/projection/size/query/listSizes/ListSizes";
import ListMarkets from "@src/core/projection/market/query/listMarkets/ListMarkets";
import ListFamilies from "@src/core/projection/family/query/listFamilies/ListFamilies";
import ListSeasons from "@src/core/projection/season/query/listSeasons/ListSeasons";
import ListFashionModels from "@src/core/projection/fashionModel/query/listFashionModels/ListFashionModels";
import ListFashionModelFittings from "@src/core/projection/fashionModel/query/listFashionModelFittings/ListFashionModelFittings";
import FeatureValue from "@src/core/projection/feature/model/FeatureValue";
import SearchFeatureValueByFeature from "@src/core/projection/feature/query/searchFeatureValuesByFeature/SearchFeatureValuesByFeature";
import FamilyCreated from "@src/core/domain/family/model/FamilyCreated";
import FamilyFeatureValuesCloned from "@src/core/domain/family/model/FamilyFeatureValuesCloned";
import instanceOfClass from "@src/shared/_types/instaceOfClass";

type StaticResourcesContextType = {
  brands: UseQueryResult<Brand[], Error>;
  colors: UseQueryResult<Color[], Error>;
  sizes: UseQueryResult<Size[], Error>;
  markets: UseQueryResult<Market[], Error>;
  families: UseQueryResult<Family[], Error>;
  seasons: UseQueryResult<Season[], Error>;
  fashionModels: UseQueryResult<FashionModel[], Error>;
  fashionModelFittings: UseQueryResult<FashionModelFitting[], Error>;
  weatherFeatureValues: UseQueryResult<FeatureValue[], Error>;
};

const StaticResourcesContext = React.createContext<StaticResourcesContextType>(
  null as unknown as StaticResourcesContextType,
);

interface StaticResourcesProviderProps {
  readonly children: ReactNode;
}

const WEATHER_FEATURE_ID = "6e518bda-2083-41b9-89c0-6c52392261d3";

const StaticResourcesProvider: React.FC<StaticResourcesProviderProps> = ({
  children,
}: StaticResourcesProviderProps) => {
  const dispatch = useDispatch();

  const brands = useAsk<Brand[], Error>({
    query: new ListBrands(),
    options: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (error: Error) => dispatch(new ThrowError(error)),
    },
  });
  const colors = useAsk<Color[], Error>({
    query: new ListColors(),
    options: { staleTime: Infinity, retry: 0, onError: (error: Error) => dispatch(new ThrowError(error)) },
  });
  const sizes = useAsk<Size[], Error>({
    query: new ListSizes(),
    options: { staleTime: Infinity, retry: 0, onError: (error: Error) => dispatch(new ThrowError(error)) },
  });
  const markets = useAsk<Market[], Error>({
    query: new ListMarkets(),
    options: { staleTime: Infinity, retry: 0, onError: (error: Error) => dispatch(new ThrowError(error)) },
  });
  const families = useAsk<Family[], Error>({
    query: new ListFamilies(),
    invalidation: (event: DomainEvent) => instanceOfClass(event, [FamilyCreated, FamilyFeatureValuesCloned]),
    options: { staleTime: Infinity, retry: 0, onError: (error: Error) => dispatch(new ThrowError(error)) },
  });
  const seasons = useAsk<Season[], Error>({
    query: new ListSeasons(),
    options: { staleTime: Infinity, retry: 0, onError: (error: Error) => dispatch(new ThrowError(error)) },
  });
  const fashionModels = useAsk<FashionModel[], Error>({
    query: new ListFashionModels(),
    options: { staleTime: Infinity, retry: 0, onError: (error: Error) => dispatch(new ThrowError(error)) },
  });
  const fashionModelFittings = useAsk<FashionModelFitting[], Error>({
    query: new ListFashionModelFittings(),
    options: { staleTime: Infinity, retry: 0, onError: (error: Error) => dispatch(new ThrowError(error)) },
  });
  const weatherFeatureValues = useAsk<FeatureValue[], Error>({
    query: new SearchFeatureValueByFeature(WEATHER_FEATURE_ID),
    options: { staleTime: Infinity, retry: 0, onError: (error: Error) => dispatch(new ThrowError(error)) },
  });
  const value = {
    brands,
    colors,
    sizes,
    markets,
    families,
    seasons,
    fashionModels,
    fashionModelFittings,
    weatherFeatureValues,
  };

  return <StaticResourcesContext.Provider value={value}>{children}</StaticResourcesContext.Provider>;
};

const useQueryStaticResources = (): StaticResourcesContextType => {
  const values = useContext<StaticResourcesContextType>(StaticResourcesContext);

  invariant(
    values,
    "You are trying to use the useQueryStaticResources hook without wrapping your app with the <StaticResourcesProvider>.",
  );

  return values;
};

export { StaticResourcesProvider };

export default useQueryStaticResources;
