import { DomainEvent, useAsk } from "@lookiero/messaging.js";
import EmployeeLoggedIn from "@src/core/domain/employee/model/EmployeeLoggedIn";
import EmployeeLoggedOut from "@src/core/domain/employee/model/EmployeeLoggedOut";
import IsEmployeeStillLoggedIn from "@src/core/projection/employee/query/isEmployeeStillLoggedIn/IsEmployeeStillLoggedIn";

const useIsEmployeeStillLoggedIn = (): boolean | undefined => {
  const { data } = useAsk<boolean, Error>({
    query: new IsEmployeeStillLoggedIn(),
    invalidation: (event: DomainEvent) => event instanceof EmployeeLoggedIn || event instanceof EmployeeLoggedOut,
  });

  return data;
};

export default useIsEmployeeStillLoggedIn;
