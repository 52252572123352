import { DomainEvent } from "@lookiero/messaging.js";

class FashionLabsProductCreated extends DomainEvent {
  public productGroup: string;

  public constructor(productGroup: string) {
    super();
    this.productGroup = productGroup;
  }

  public messageName(): string {
    return "FashionLabsProductCreated";
  }
}

export default FashionLabsProductCreated;
