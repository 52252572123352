import { CommandHandler } from "@lookiero/messaging.js";
import FashionLabsProductRepository from "../../model/FashionLabsProductRepository";
import EditFashionLabsProduct from "./EditFashionLabsProduct";

class EditFashionLabsProductHandler implements CommandHandler<EditFashionLabsProduct> {
  private repository: FashionLabsProductRepository;

  public constructor(repository: FashionLabsProductRepository) {
    this.repository = repository;
  }

  public async execute({ group, title, note, features, productModel, media }: EditFashionLabsProduct): Promise<void> {
    const fashionLabsProduct = await this.repository.getByGroup(group);

    if (!fashionLabsProduct) {
      throw new Error(`No hay ningun producto con el grupo ${group}`);
    }

    fashionLabsProduct.editProduct(title, note, features, productModel, media);

    return this.repository.editProduct(fashionLabsProduct);
  }
}

export default EditFashionLabsProductHandler;
