import { CommandHandler } from "@lookiero/messaging.js";
import FamilyRepository from "../../model/FamilyRepository";
import EditFamilyFeatureValues from "./EditFamilyFeatureValues";
import Family from "../../model/Family";

class EditFamilyFeatureValuesHandler implements CommandHandler<EditFamilyFeatureValues> {
  private repository: FamilyRepository;

  public constructor(repository: FamilyRepository) {
    this.repository = repository;
  }

  public async execute({ familyId, featureValuesIds }: EditFamilyFeatureValues): Promise<void> {
    const instace = Family.editFeatureValues(familyId, featureValuesIds);

    await this.repository.editFeatureValues(instace);
  }
}

export default EditFamilyFeatureValuesHandler;
