import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import FashionLabsProductCreated from "@src/core/domain/fashionLabsProduct/model/FashionLabsProductCreated";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";

class CreateNotificationWhenFashionLabsProductCreated implements ProcessManager<FashionLabsProductCreated> {
  private readonly commandBus!: CommandBus;

  public async process({ productGroup }: FashionLabsProductCreated): Promise<void> {
    this.commandBus.dispatch(
      new CreateNotification({
        level: NotificationLevel.SUCCESS,
        content: `El producto con grupo <b onmouseover='this.style.color="grey"; this.style.textDecoration="underline";' onmouseout='this.style.color="black"; this.style.textDecoration="none";' ><a href="${window.location.pathname}/${productGroup}">${productGroup}</a></b> ha sido <b>creado</b> con éxito`,
      }),
    );
  }
}

export default CreateNotificationWhenFashionLabsProductCreated;
