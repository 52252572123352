import { Query } from "@lookiero/messaging.js";
import { ProductsCriteriaAvailability } from "../../model/ProductView";

class ViewProductsForAvailability extends Query {
  public readonly groups?: string | string[] | null;
  public readonly families?: string | string[] | null;
  public readonly brands?: string | string[] | null;
  public readonly sizes?: string | string[] | null;
  public readonly colors?: string | string[] | null;
  public readonly seasons?: string | string[] | null;
  public readonly featureValues?: string | string[] | null;
  public readonly brandOrigins?: string | string[] | null;
  public readonly visibility?: string | null;
  public readonly hasStock?: string | null;

  public constructor({
    groups,
    families,
    brands,
    sizes,
    colors,
    seasons,
    featureValues,
    brandOrigins,
    visibility,
    hasStock,
  }: ProductsCriteriaAvailability) {
    super();
    this.groups = groups;
    this.families = families;
    this.brands = brands;
    this.sizes = sizes;
    this.colors = colors;
    this.seasons = seasons;
    this.featureValues = featureValues;
    this.brandOrigins = brandOrigins;
    this.visibility = visibility;
    this.hasStock = hasStock;
  }

  public messageName(): string {
    return "ViewProductsForAvailability";
  }
}

export default ViewProductsForAvailability;
