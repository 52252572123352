import { QueryHandler } from "@lookiero/messaging.js";
import NotificationView from "../../model/NotificationView";
import Notification from "../../model/Notification";
import ListNotifications from "./ListNotifications";

class ListNotificationsHandler implements QueryHandler<ListNotifications> {
  private readonly view: NotificationView;

  public constructor(view: NotificationView) {
    this.view = view;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: ListNotifications): Promise<Notification[]> {
    return this.view.list();
  }
}

export default ListNotificationsHandler;
