import { CommandHandler } from "@lookiero/messaging.js";
import NotificationRepository from "../../model/NotificationRepository";
import Notification from "../../model/Notification";
import CreateNotification from "./CreateNotification";

class CreateNotificationHandler implements CommandHandler<CreateNotification> {
  private readonly repository: NotificationRepository;

  public constructor(repository: NotificationRepository) {
    this.repository = repository;
  }

  public execute({ notification: notif }: CreateNotification): Promise<void> {
    const notification = Notification.create(`${Date.now()}`, notif.level, notif.content);

    return this.repository.save(notification);
  }
}

export default CreateNotificationHandler;
