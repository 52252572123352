import HttpClient from "@src/shared/delivery/application/HttpClient";
import FashionLabsProductPreview from "@src/core/projection/fashionLabsProduct/model/FashionLabsProductPreview";
import FashionLabsProduct from "@src/core/projection/fashionLabsProduct/model/FashionLabsProduct";
import FashionLabsProductView, {
  FashionLabsProductsCriteria,
  PagedFashionLabsProductsCriteria,
} from "@src/core/projection/fashionLabsProduct/model/FashionLabsProductView";
import FashionLabsProductForImport from "@src/core/projection/fashionLabsProduct/model/FashionLabsProductForImport";
import {
  fashionLabsProductFromDto,
  fashionLabsProductPreviewFromDto,
  productDtoToFashionLabsProductForImport,
} from "./responseMapper";
import { searchByCriteriaDtoFromCriteria } from "./requestBuilder";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";

class HttpFashionLabsProductView implements FashionLabsProductView {
  private httpClient: HttpClient;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async searchByCriteria(criteria: PagedFashionLabsProductsCriteria): Promise<FashionLabsProductPreview[]> {
    try {
      const requestParameters = searchByCriteriaDtoFromCriteria(criteria);

      const response = await this.httpClient.post("/search-fashion-labs-products-by-criteria", requestParameters);

      if (!response.ok) {
        throw new Error("buscar productos por criteria");
      }

      const { result } = await response.json();

      return result ? result.map(fashionLabsProductPreviewFromDto) : [];
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("buscar productos por criteria");
    }
  }

  public async countByCriteria(criteria: FashionLabsProductsCriteria): Promise<number> {
    try {
      const response = await this.httpClient.post("/count-fashion-labs-products-by-criteria", {
        group: criteria.group,
      });

      if (!response.ok) {
        throw new Error("Contar productor por criteria");
      }

      const { result } = await response.json();

      return result;
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("Contar productor por criteria");
    }
  }

  public async canBeCreated(group: string): Promise<boolean> {
    const requestParameters = {
      group: group.toUpperCase(),
    };

    try {
      const response = await this.httpClient.post("/can-product-be-created", requestParameters);

      if (!response.ok) {
        throw new Error("comprobar si se puede crear el producto");
      }

      const { result } = await response.json();

      return result;
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("comprobar si se puede crear el producto");
    }
  }

  public async byGroup(group: string): Promise<FashionLabsProduct> {
    const requestParameters = {
      group: group.toUpperCase(),
    };

    try {
      const response = await this.httpClient.post("/view-product-by-group", requestParameters);

      if (!response.ok) {
        throw new Error("obtener el producto por grupo");
      }

      const { result } = await response.json();

      return fashionLabsProductFromDto(result);
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener el producto por grupo");
    }
  }

  public async byGroupForImport(group: string): Promise<FashionLabsProductForImport> {
    try {
      const requestParameters = {
        group: group.toUpperCase(),
      };
      const response = await this.httpClient.post("/view-product-by-group", requestParameters);

      if (!response.ok) {
        throw new Error("obtener la información del grupo del servicio para importarlo");
      }

      const result = await response.json();

      return productDtoToFashionLabsProductForImport(result.result);
    } catch (error) {
      console.error(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("obtener la información del grupo del servicio para importarlo");
    }
  }
}

export default HttpFashionLabsProductView;
