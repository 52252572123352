import { Query } from "@lookiero/messaging.js";

class SearchFeaturesByFamily extends Query {
  public readonly familyId: string;

  public constructor(familyId: string) {
    super();
    this.familyId = familyId;
  }

  public messageName(): string {
    return "SearchFeaturesByFamily";
  }
}

export default SearchFeaturesByFamily;
