import { QueryHandler } from "@lookiero/messaging.js";
import ProductVariantVisibilityPreview from "../../model/ProductVariantVisibilityPreview";
import ProductView from "../../model/ProductView";
import ViewProductVariantsForVisibility from "./ViewProductVariantsForVisibility";

class ViewProductVariantsForVisibilityHandler implements QueryHandler<ViewProductVariantsForVisibility> {
  private readonly view: ProductView;

  public constructor(view: ProductView) {
    this.view = view;
  }

  public handle(criteria: ViewProductVariantsForVisibility): Promise<ProductVariantVisibilityPreview[]> {
    return this.view.searchByCriteriaToVisibility(criteria);
  }
}

export default ViewProductVariantsForVisibilityHandler;
