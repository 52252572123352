import FeatureValue from "@src/core/domain/featureValue/model/FeatureValue";

export type FeatureValueDto = {
  readonly id: string;
  readonly featureId: string;
  readonly value: string;
};

export const toFeatureValueDto = (featureValue: FeatureValue): FeatureValueDto => ({
  id: featureValue.featureValueId,
  featureId: featureValue.featureId,
  value: featureValue.value,
});
