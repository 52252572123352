import { QueryHandler } from "@lookiero/messaging.js";
import Feature from "../../model/Feature";
import FeatureView from "../../model/FeatureView";
import SearchFeaturesByFamily from "./SearchFeaturesByFamily";

class SearchFeaturesByFamilyHandler implements QueryHandler<SearchFeaturesByFamily> {
  private view: FeatureView;

  constructor(view: FeatureView) {
    this.view = view;
  }

  public handle({ familyId }: SearchFeaturesByFamily): Promise<Feature[]> {
    return this.view.searchByFamily(familyId);
  }
}

export default SearchFeaturesByFamilyHandler;
