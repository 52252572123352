import { Command } from "@lookiero/messaging.js";
import Credentials from "../../model/Credentials";

class Login extends Command {
  readonly credentials: Credentials;

  constructor(credentials: Credentials) {
    super();
    this.credentials = credentials;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return "Login";
  }
}

export default Login;
