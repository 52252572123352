import HttpClient from "@src/shared/delivery/application/HttpClient";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import viewUsersFromDto, { ViewUserDto } from "./responseMapper";
import User from "@src/core/projection/user/model/User";
import UserView from "@src/core/projection/user/model/UserView";

class HttpUserView implements UserView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<User[]> {
    try {
      const response = await this.httpClient.post("/view-users", { per_page: -1 });

      if (!response.ok) {
        throw new Error("Obtener los usuarios de los pedidos");
      }

      const result = await response.json();
      return result.map((user: ViewUserDto) => viewUsersFromDto(user));
    } catch (error) {
      console.log(Error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("Obtener los usuarios de los pedidos");
    }
  }
}

export default HttpUserView;
