import { Command } from "@lookiero/messaging.js";

class EditAvailability extends Command {
  public readonly transactionId: string;
  public readonly products: string[];
  public readonly channelName: string;
  public readonly availableSellingMarkets: string[];
  public readonly unavailableSellingMarkets: string[];
  public readonly reason: string;

  public constructor(
    transactionId: string,
    products: string[],
    channelName: string,
    availableSellingChannels: string[],
    unavailableSellingChannels: string[],
    reason: string,
  ) {
    super();
    this.transactionId = transactionId;
    this.products = products;
    this.channelName = channelName;
    this.availableSellingMarkets = availableSellingChannels;
    this.unavailableSellingMarkets = unavailableSellingChannels;
    this.reason = reason;
  }

  public messageName(): string {
    return "EditAvailability";
  }
}

export default EditAvailability;
