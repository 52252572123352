import { DomainEvent } from "@lookiero/messaging.js";

class EmployeeLoggedOut extends DomainEvent {
  /* istanbul ignore next */
  public messageName(): string {
    return "EmployeeLoggedOut";
  }
}

export default EmployeeLoggedOut;
