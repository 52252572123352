import EmployeeRoles from "./EmployeeRoles";

//OPS_LOGISTICS_CATALOG_CATEGORIES
class Employee {
  public authToken: string;
  public username: string;
  public roles: string[];

  constructor({ authToken, username, roles }: { authToken: string; username: string; roles: string[] }) {
    this.authToken = authToken;
    this.username = username;
    this.roles = roles;
  }

  hasReceptionRole(): boolean {
    return this.roles.includes(EmployeeRoles.RECEPTION_ROLE);
  }
  hasCatalogOrdersRole(): boolean {
    return this.roles.includes(EmployeeRoles.CATALOG_ORDERS_ROLE);
  }

  hasCatalogCategoriesRole(): boolean {
    return this.roles.includes(EmployeeRoles.OPS_LOGISTICS_CATALOG_CATEGORIES);
  }
}

export default Employee;
