import { FC } from "react";
import { Navigate, generatePath } from "react-router-dom";
import Routes from "./routes";
import useIsEmployeeStillLoggedIn from "../views/_behaviors/useIsEmployeeStillLoggedIn";

interface AuthenticationMiddlewareProps {
  children: JSX.Element;
  locale: string;
}

const AuthenticationMiddleware: FC<AuthenticationMiddlewareProps> = ({ children, locale }) => {
  const isEmployeeStillLoggedIn = useIsEmployeeStillLoggedIn();

  return isEmployeeStillLoggedIn === undefined ? null : isEmployeeStillLoggedIn ? (
    children
  ) : (
    <Navigate to={generatePath(Routes.LOGIN, { locale })} />
  );
};

export default AuthenticationMiddleware;
