import FashionLabsProductVariant from "@src/core/domain/fashionLabsProductVariant/model/FashionLabsProductVariant";
import { FashionLabsProductVariantDto } from "./FashionLabsProductVariantDto";

export const toFashionLabsProductVariantDto = (
  productVariant: FashionLabsProductVariant,
): FashionLabsProductVariantDto => ({
  id: productVariant.id,
  product_id: productVariant.productId,
  season: productVariant.season,
  publishable: productVariant.publishable,
  barcode: productVariant.barcode,
  reference: productVariant.reference,
  color: { id: productVariant.color.id },
  size: { id: productVariant.size.id },
  media: productVariant.media.map((variantMedia) => ({
    id: variantMedia.id,
    media_attributes: { commercial_use: variantMedia.commercialUse },
    perspective: variantMedia.perspective,
    url: variantMedia.url,
  })),
  fashion_model: {
    id: productVariant.fashionModel.id,
    size_id: productVariant.fashionModel.sizeId,
    fitting: productVariant.fashionModel.fitting,
  },
  selling_price_eur: productVariant.sellingPriceEur,
  selling_price_gbp: productVariant.sellingPriceGbp,
  selling_price_sek: productVariant.sellingPriceSek,
  selling_price_chf: productVariant.sellingPriceChf,
  selling_prices: productVariant.sellingPrices,
});
