import { QueryHandler } from "@lookiero/messaging.js";
import Size from "../../model/Size";
import SizeView from "../../model/SizeView";
import ListSizes from "./ListSizes";

class ListSizesHandler implements QueryHandler<ListSizes> {
  private readonly view: SizeView;

  public constructor(view: SizeView) {
    this.view = view;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: ListSizes): Promise<Size[]> {
    return this.view.list();
  }
}

export default ListSizesHandler;
