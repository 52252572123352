import { CommandHandler } from "@lookiero/messaging.js";
import Visibility from "../../model/Visibility";
import VisibilityRepository from "../../model/VisibilityRepository";
import EditVisibility from "./EditVisibility";

class EditVisibilityHandler implements CommandHandler<EditVisibility> {
  private repository: VisibilityRepository;

  public constructor(repository: VisibilityRepository) {
    this.repository = repository;
  }
  public execute({ transactionId, productVariants, visibility, reason }: EditVisibility): Promise<void> {
    const visibilityInstance = Visibility.edit(transactionId, productVariants, visibility, reason);
    return this.repository.edit(visibilityInstance);
  }
}

export default EditVisibilityHandler;
