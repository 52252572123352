import { QueryHandler } from "@lookiero/messaging.js";
import Season from "../../model/Season";
import SeasonView from "../../model/SeasonView";
import ListSeasons from "./ListSeasons";

class ListSeasonsHandler implements QueryHandler<ListSeasons> {
  private readonly view: SeasonView;

  public constructor(view: SeasonView) {
    this.view = view;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: ListSeasons): Promise<Season[]> {
    return this.view.list();
  }
}

export default ListSeasonsHandler;
