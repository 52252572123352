import { CommandHandler } from "@lookiero/messaging.js";
import CoreError from "../../model/CoreError";
import ErrorRepository from "../../model/ErrorRepository";
import ThrowError from "./ThrowError";

class ThrowErrorHandler implements CommandHandler<ThrowError> {
  private repository: ErrorRepository;

  public constructor(repository: ErrorRepository) {
    this.repository = repository;
  }

  public execute({ error }: ThrowError): Promise<void> {
    const coreError = CoreError.throw(error);

    return this.repository.throw(coreError);
  }
}

export default ThrowErrorHandler;
