import { Command, CommandHandler } from "@lookiero/messaging.js";
import { handlerContainer } from "../helpers/handlerContainer";
import { Container } from "inversify";
import { registers } from "../helpers/registers";
import Class from "@lookiero/messaging.js/dist/Class";

import EditAvailability from "@src/core/domain/availability/command/editAvailability/EditAvailability";
import CreateEmployee from "@src/core/domain/employee/command/create/CreateEmployee";
import Logout from "@src/core/domain/employee/command/logout/Logout";
import ThrowError from "@src/core/domain/error/command/throwError/ThrowError";
import CloneFamilyFeatureValues from "@src/core/domain/family/command/cloneFamilyFeatureValues/CloneFamilyFeatureValues";
import CreateFamily from "@src/core/domain/family/command/createFamily/CreateFamily";
import EditFamilyFeatureValues from "@src/core/domain/family/command/editFamilyFeatureValues/EditFamilyFeatureValues";
import CreateFashionLabsModel from "@src/core/domain/fashionLabsModel/command/createFashionLabsModel/CreateFashionLabsModel";
import EditFashionLabsModel from "@src/core/domain/fashionLabsModel/command/editFashionLabsModel/EditFashionLabsModel";
import CreateFashionLabsProduct from "@src/core/domain/fashionLabsProduct/command/createFashionLabsProduct/CreateFashionLabsProduct";
import EditFashionLabsProduct from "@src/core/domain/fashionLabsProduct/command/editFashionLabsProduct/EditFashionLabsProduct";
import CreateFashionLabsProductVariant from "@src/core/domain/fashionLabsProductVariant/command/createFashionLabsProductVariant/CreateFashionLabsProductVariant";
import EditFashionLabsProductVariant from "@src/core/domain/fashionLabsProductVariant/command/editFashionLabsProductVariant/EditFashionLabsProductVariant";
import CreateFeatureValue from "@src/core/domain/featureValue/command/createFeatureValue/CreateFeatureValue";
import RemoveMedia from "@src/core/domain/media/command/removeMedia/RemoveMedia";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import RemoveNotification from "@src/core/domain/notification/command/removeNotification/RemoveNotification";
import AddOrderItem from "@src/core/domain/order/command/addItem/AddOrderItem";
import CreateOrder from "@src/core/domain/order/command/createOrder/CreateOrder";
import PlaceOrder from "@src/core/domain/order/command/placeOrder/PlaceOrder";
import EditVisibility from "@src/core/domain/visibility/command/editVisibility/EditVisibility";
import Login from "@src/core/domain/employee/command/login/Login";
import RemoveOrder from "@src/core/domain/order/command/removeOrderItem/RemoveOrderItem";
import { commandRegisterList } from "./commandRegisterList";

interface CommandDependeciesFunctionArgs {
  readonly container: Container;
}
type CommandDependeciesFunctionResult = [Class<Command>, CommandHandler<Command>][];

interface CommandDependeciesFunction {
  (args: CommandDependeciesFunctionArgs): Promise<CommandDependeciesFunctionResult>;
}

const commandDependecies: CommandDependeciesFunction = async ({ container }) => {
  const { listRegister } = registers({ container });
  const handler = handlerContainer({ container });

  listRegister(commandRegisterList);

  return Promise.resolve([
    [ThrowError, handler("ThrowCoreErrorHandler")],
    [CreateNotification, handler("CreateNotificationHandler")],
    [RemoveNotification, handler("RemoveNotificationHandler")],
    [CreateFashionLabsProduct, handler("CreateFashionLabsProductHandler")],
    [CreateFashionLabsProductVariant, handler("CreateFashionLabsProductVariantHandler")],
    [EditFashionLabsModel, handler("EditFashionLabsModelHandler")],
    [EditFashionLabsProductVariant, handler("EditFashionLabsProductVariantHandler")],
    [EditFashionLabsProduct, handler("EditFashionLabsProductHandler")],
    [RemoveMedia, handler("RemoveMediaHandler")],
    [EditAvailability, handler("EditAvailabilityHandler")],
    [EditVisibility, handler("EditVisibilityHandler")],
    [Login, handler("LoginHandler")],
    [Logout, handler("LogoutHandler")],
    [CreateEmployee, handler("CreateEmployeeHandler")],
    [AddOrderItem, handler("AddOrderItemHandler")],
    [CreateOrder, handler("CreateOrderHandler")],
    [RemoveOrder, handler("RemoveOrderHandler")],
    [PlaceOrder, handler("PlaceOrderHandler")],
    [CreateFamily, handler("CreateFamilyHandler")],
    [CreateFeatureValue, handler("CreateFeatureValueHandler")],
    [CreateFashionLabsModel, handler("CreateFashionLabsModelHandler")],
    [CloneFamilyFeatureValues, handler("CloneFamilyFeatureValuesHandler")],
    [EditFamilyFeatureValues, handler("EditFamilyFeatureValuesHandler")],
  ] as CommandDependeciesFunctionResult);
};

export { commandDependecies };
