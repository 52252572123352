import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import ErrorThrown from "@src/core/domain/error/model/ErrorThrown";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import Logout from "@src/core/domain/employee/command/logout/Logout";

class LogoutWhenUnAuthorizedEmployeeError implements ProcessManager<ErrorThrown> {
  private commandBus!: CommandBus;

  public async process({ error }: ErrorThrown): Promise<void> {
    if (error instanceof UnAuthorizedEmployeeError) {
      this.commandBus.dispatch(new Logout());
    }
  }
}

export default LogoutWhenUnAuthorizedEmployeeError;
