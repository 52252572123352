import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";
import OrderItemAdded from "@src/core/domain/order/model/OrderItemAdded";

class CreateNotificationWhenOrderItemAdded implements ProcessManager<OrderItemAdded> {
  private readonly commandBus!: CommandBus;

  public async process(): Promise<void> {
    this.commandBus.dispatch(
      new CreateNotification({
        level: NotificationLevel.SUCCESS,
        content: "Referencia del modelo añadida al pedido",
      }),
    );
  }
}

export default CreateNotificationWhenOrderItemAdded;
