import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import AvailabilityEdited from "@src/core/domain/availability/model/AvailabilityEdited";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";

class CreateNotificationWhenAvailabilityEdited implements ProcessManager<AvailabilityEdited> {
  private readonly commandBus!: CommandBus;

  public async process({ channelName }: AvailabilityEdited): Promise<void> {
    this.commandBus.dispatch(
      new CreateNotification({
        level: NotificationLevel.SUCCESS,
        content: `Disponibilidad correctamente actualizada para <b>${channelName}</b>.`,
      }),
    );
  }
}

export default CreateNotificationWhenAvailabilityEdited;
