import User from "@src/core/projection/user/model/User";

export interface ViewUserDto {
  readonly id: string;
  readonly name: string;
  readonly email: string;
}

const viewUserFromDto = (dto: ViewUserDto): User => ({
  id: dto.id,
  name: dto.name,
});

export default viewUserFromDto;
