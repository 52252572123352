import { Query } from "@lookiero/messaging.js";

class GetFeatures extends Query {
  public constructor() {
    super();
  }

  public messageName(): string {
    return "GetFeatures";
  }
}

export default GetFeatures;
