import FashionLabsProduct, {
  FashionLabsProductFeature,
} from "@src/core/domain/fashionLabsProduct/model/FashionLabsProduct";
import { CreateFashionLabsProductRequest } from "./CreateFashionLabsProductRequest";
import { EditFashionLabsProductRequest, FeaturesRequest } from "./EditFashionLabsProductRequest";

const toFeaturesRequest = (features: FashionLabsProductFeature[]): FeaturesRequest =>
  features.map((feature) => ({
    id: feature.id,
    values: feature.values.map((value) => ({
      id: value.id,
      qualifier: value.qualifier
        ? {
            id: value.qualifier.id,
            values: value.qualifier.values.map(({ id }) => ({ id })),
          }
        : undefined,
    })),
  }));

const toCreateFashionLabsProductRequest = (product: FashionLabsProduct): CreateFashionLabsProductRequest => ({
  id: product.productId,
  title: product.title,
  note: product.note,
  family: { id: product.familyId },
  brand: { id: product.brandId },
  group: product.group,
  channels: product.channels,
  features: toFeaturesRequest(product.features),
  media: product.media,
  product_model: {
    model_id: product.productModel.modelId,
    size_id: product.productModel.sizeId,
    model_fitting: product.productModel.modelFitting,
  },
});

const toEditFashionLabsProductRequest = (product: FashionLabsProduct): EditFashionLabsProductRequest => ({
  id: product.productId,
  title: product.title,
  note: product.note,
  features: toFeaturesRequest(product.features),
  media: product.media,
  product_model: {
    model_id: product.productModel.modelId,
    size_id: product.productModel.sizeId,
    model_fitting: product.productModel.modelFitting,
  },
});

export { toCreateFashionLabsProductRequest, toEditFashionLabsProductRequest };
