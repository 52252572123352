import { DomainEvent } from "@lookiero/messaging.js";

class ErrorThrown extends DomainEvent {
  public readonly error: Error;

  public constructor(error: Error) {
    super();
    this.error = error;
  }

  /* istanbul ignore file */
  public messageName(): string {
    return "ErrorThrown";
  }
}

export default ErrorThrown;
