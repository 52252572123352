import { EventBus } from "@lookiero/messaging.js";
import NotificationRepository from "@src/core/domain/notification/model/NotificationRepository";
import Notification from "@src/core/domain/notification/model/Notification";
import StorageClient from "@src/shared/delivery/application/StorageClient";

class InMemoryNotificationRepository implements NotificationRepository {
  private eventBus!: EventBus;
  private notificationsCacheClient: StorageClient;

  public constructor(notificationsCacheClient: StorageClient) {
    this.notificationsCacheClient = notificationsCacheClient;
  }

  public async get(id: string): Promise<Notification> {
    const notification = await this.notificationsCacheClient.get(id);

    if (!notification) {
      return Promise.reject(new Error(`Notification for the provided id ${id} does not exist`));
    }

    return Promise.resolve(notification);
  }

  public async save(notification: Notification): Promise<void> {
    await this.notificationsCacheClient.store(notification.id, notification);

    this.eventBus.publish(notification.recordedEvents());

    return Promise.resolve();
  }

  public async remove(notification: Notification): Promise<void> {
    await this.notificationsCacheClient.delete(notification.id);

    this.eventBus.publish(notification.recordedEvents());

    return Promise.resolve();
  }
}

export default InMemoryNotificationRepository;
