import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import CreateEmployee from "@src/core/domain/employee/command/create/CreateEmployee";
import EmployeeAuthenticated from "@src/core/domain/employee/model/EmployeeAuthenticated";

class CreateEmployeeWhenAuthenticated implements ProcessManager<EmployeeAuthenticated> {
  private readonly commandBus!: CommandBus;

  public async process(employee: EmployeeAuthenticated): Promise<void> {
    this.commandBus.dispatch(new CreateEmployee({ ...employee }));
  }
}

export default CreateEmployeeWhenAuthenticated;
