enum MediaPerspective {
  VIDEO = "VIDEO",
  COLLAGE = "COLLAGE",
  MAIN = "MAIN",
  MAIN_TRANSPARENT = "MAIN_TRANSPARENT",
  FRONT = "FRONT",
  SIDE = "SIDE",
  FRONT_FAR = "FRONT_FAR",
  BACK = "BACK",
  DETAIL = "DETAIL",
  LABEL = "LABEL",
  FLAT_BACK = "FLAT_BACK",
  BOTTOM_UP = "BOTTOM_UP",
  BACK_FAR = "BACK_FAR",
  FAST_TRACK = "FAST_TRACK",
  FITTING_FRONT = "FITTING_FRONT",
  FITTING_BACK = "FITTING_BACK",
  FITTING_TOTAL = "FITTING_TOTAL",
  FITTING_DETAIL_1 = "FITTING_DETAIL_1",
}

export default MediaPerspective;
