import { AggregateRoot } from "@lookiero/messaging.js";
import VisibilityEdited from "./VisibilityEdited";

class Visibility extends AggregateRoot {
  public transactionId: string;
  public productVariants: string[];
  public status: string;
  public reason: string;

  constructor(transactionId: string, productVariants: string[], status: string, reason: string) {
    super();
    this.transactionId = transactionId;
    this.productVariants = productVariants;
    this.status = status;
    this.reason = reason;
  }

  public static edit(transactionId: string, productVariants: string[], visibility: string, reason: string): Visibility {
    const instance = new Visibility(transactionId, productVariants, visibility, reason);
    instance.record(new VisibilityEdited(transactionId));

    return instance;
  }
}

export default Visibility;
