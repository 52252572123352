import { CommandHandler } from "@lookiero/messaging.js";
import FashionLabsProductVariant from "../../model/FashionLabsProductVariant";
import FashionLabsProductVariantRepository from "../../model/FashionLabsProductVariantRepository";
import CreateFashionLabsProductVariant from "./CreateFashionLabsProductVariant";
import CreateFashionLabsProduct from "./CreateFashionLabsProductVariant";

class CreateFashionLabsProductVariantHandler implements CommandHandler<CreateFashionLabsProduct> {
  private repository: FashionLabsProductVariantRepository;

  public constructor(repository: FashionLabsProductVariantRepository) {
    this.repository = repository;
  }

  public execute(command: CreateFashionLabsProductVariant): Promise<void> {
    command.productVariants.forEach((variant) => {
      const variantDomain = FashionLabsProductVariant.create(
        variant.id,
        variant.productId,
        variant.season,
        variant.publishable,
        variant.barcode,
        variant.reference,
        variant.color,
        variant.size,
        variant.media,
        variant.fashionModel,
        variant.sellingPriceEur,
        variant.sellingPriceGbp,
        variant.sellingPriceSek,
        variant.sellingPriceChf,
        variant.sellingPrices,
      );
      this.repository.create(variantDomain);
    });
    return new Promise((resolve) => resolve());
  }
}

export default CreateFashionLabsProductVariantHandler;
