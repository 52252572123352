import React, { useMemo } from "react";

import { Icon, IconVariant, Text, TextVariant } from "@lookiero/react-ui-kit";
import { ReactIntl } from "@lookiero/i18n.js";
import useLogout from "./_behaviour/useLogout";
import { useGetCurrentEmployee } from "@src/ui/views/_behaviors/employeeProvider";
import { ROOT_I18N_PREFIX, RootI18n } from "@src/ui/i18n/I18nRootMessages";
import { AvatarDropdown, Avatar, Menu, Toggle } from "@src/ui/component-library/atoms/avatarDropdown/AvatarDropdown";

import "./logoutButton.css";

const LogoutButton: React.FC = () => {
  const currentEmployee = useGetCurrentEmployee();
  const employeeUsername = useMemo(() => currentEmployee?.username as string, [currentEmployee]);
  const employeeUsernameInitial = useMemo(
    () => employeeUsername.charAt(0).toLocaleUpperCase() as string,
    [employeeUsername],
  );

  const { logout } = useLogout();

  return (
    <div className="logout">
      <AvatarDropdown>
        <Toggle>
          <Text variant={TextVariant.BODY}>{employeeUsernameInitial}</Text>
        </Toggle>
        <Menu>
          <div className="logout__user">
            <Avatar>
              <Text variant={TextVariant.BODY}>{employeeUsernameInitial}</Text>
            </Avatar>
            <Text variant={TextVariant.BODY_SMALL} className="logout__user-name">
              {employeeUsername}
            </Text>
          </div>
          <div className="logout__button-container">
            <div className="logout__button" onClick={logout} role="button">
              <Icon variant={IconVariant.LOGOUT} />
              <Text variant={TextVariant.BODY_SMALL}>
                <ReactIntl.I18nMessage id={RootI18n.HUB_LOG_OUT} prefix={ROOT_I18N_PREFIX} />
              </Text>
            </div>
          </div>
        </Menu>
      </AvatarDropdown>
    </div>
  );
};

export default LogoutButton;
