import { Command } from "@lookiero/messaging.js";
import { FashionLabsProductVariant as FashionLabsProductVariantProjection } from "@src/core/projection/fashionLabsProduct/model/FashionLabsProduct";

type FashionLabsProductVariantCommand = FashionLabsProductVariantProjection & {
  readonly productId: string;
};

class EditFashionLabsProductVariant extends Command {
  public readonly productVariants: FashionLabsProductVariantCommand[];

  public constructor(productVariants: FashionLabsProductVariantCommand[]) {
    super();
    this.productVariants = productVariants;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return "EditFashionLabsProductVariant";
  }
}

export default EditFashionLabsProductVariant;
