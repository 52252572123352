import { Query } from "@lookiero/messaging.js";
import { PagedFashionLabsProductsCriteria } from "../../model/FashionLabsProductView";

class SearchFashionLabsProductsByCriteria extends Query {
  public readonly criteria: PagedFashionLabsProductsCriteria;

  public constructor(criteria: PagedFashionLabsProductsCriteria) {
    super();
    this.criteria = criteria;
  }

  public messageName(): string {
    return "SearchFashionLabsProductsByCriteria";
  }
}

export default SearchFashionLabsProductsByCriteria;
