import { Query } from "@lookiero/messaging.js";
import { toISO } from "@src/shared/domain/Date/DateFormatter";

type CountProductsFieldType = undefined | string | string[];

interface CountProductsParameters {
  search: undefined | string;
  families: CountProductsFieldType;
  brands: CountProductsFieldType;
  seasons: CountProductsFieldType;
  sizes: CountProductsFieldType;
  colors: CountProductsFieldType;
  climatologies: CountProductsFieldType;
  visibility: undefined | string;
  market: CountProductsFieldType;
  date: undefined | string;
  featureValues: CountProductsFieldType;
  brandOrigins: CountProductsFieldType;
  reference: undefined | string;
  hasStock: undefined | string;
}

class CountProducts extends Query {
  public readonly search: undefined | string;
  public readonly families: CountProductsFieldType;
  public readonly brands: CountProductsFieldType;
  public readonly seasons: CountProductsFieldType;
  public readonly sizes: CountProductsFieldType;
  public readonly colors: CountProductsFieldType;
  public readonly visibility: undefined | string;
  public readonly climatologies: CountProductsFieldType;
  public readonly market: CountProductsFieldType;
  public readonly date: undefined | string;
  public readonly featureValues: CountProductsFieldType;
  public readonly brandOrigins: CountProductsFieldType;
  public readonly reference: undefined | string;
  public readonly hasStock: undefined | string;

  constructor({
    search,
    families,
    brands,
    seasons,
    sizes,
    colors,
    climatologies,
    visibility,
    market,
    date = toISO(new Date()),
    featureValues,
    brandOrigins,
    reference,
    hasStock,
  }: CountProductsParameters) {
    super();
    this.search = search;
    this.families = families;
    this.brands = brands;
    this.seasons = seasons;
    this.sizes = sizes;
    this.colors = colors;
    this.visibility = visibility;
    this.climatologies = climatologies;
    this.market = market;
    this.date = date;
    this.featureValues = featureValues;
    this.brandOrigins = brandOrigins;
    this.reference = reference;
    this.hasStock = hasStock;
  }

  public messageName(): string {
    return "CountProducts";
  }
}

export default CountProducts;
