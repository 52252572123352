import { DomainEvent } from "@lookiero/messaging.js";

class MediaRemoved extends DomainEvent {
  public mediaId: string;

  public constructor(mediaId: string) {
    super();
    this.mediaId = mediaId;
  }

  public messageName(): string {
    return "MediaRemoved";
  }
}

export default MediaRemoved;
