import { AggregateRoot } from "@lookiero/messaging.js";
import FamilyCreated from "./FamilyCreated";
import FamilyFeatureValuesCloned from "./FamilyFeatureValuesCloned";
import FamilyFeatureValuesEdited from "./FamilyFeatureValuesEdited";

class Family extends AggregateRoot {
  public familyId: string;
  public category?: string;
  public segment?: string;
  public letter?: string;
  public familyName?: string;
  public cloneFamilyId?: string;
  public featureValuesIds?: string[];

  constructor({
    familyId,
    category,
    segment,
    letter,
    familyName,
    cloneFamilyId,
    featureValuesIds,
  }: {
    familyId: string;
    category?: string;
    segment?: string;
    letter?: string;
    familyName?: string;
    cloneFamilyId?: string;
    featureValuesIds?: string[];
  }) {
    super();
    this.familyId = familyId;
    this.category = category;
    this.segment = segment;
    this.letter = letter;
    this.familyName = familyName;
    this.cloneFamilyId = cloneFamilyId;
    this.featureValuesIds = featureValuesIds;
  }

  public static create(family: {
    familyId: string;
    category: string;
    segment: string;
    letter: string;
    familyName: string;
  }): Family {
    const instance = new Family(family);
    instance.record(new FamilyCreated(family));

    return instance;
  }

  public static editFeatureValues(familyId: string, featureValuesIds: string[]): Family {
    const instance = new Family({ familyId, featureValuesIds });
    instance.record(new FamilyFeatureValuesEdited({ familyId, featureValuesIds }));

    return instance;
  }

  public static cloneFeatureValues(familyId: string, cloneFamilyId: string): Family {
    const instance = new Family({ familyId, cloneFamilyId });

    instance.record(new FamilyFeatureValuesCloned({ familyId, cloneFamilyId }));

    return instance;
  }
}

export default Family;
