import { useLocation } from "react-router";
import { match } from "path-to-regexp";

const useIsRoute = (route: string): boolean => {
  const location = useLocation();
  const routeMatch = match(route);
  return Boolean(routeMatch(location.pathname));
};

export default useIsRoute;
