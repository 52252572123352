import { Query, QueryHandler } from "@lookiero/messaging.js";
import Class from "@lookiero/messaging.js/dist/Class";
import { Container } from "inversify";
import { registers } from "../helpers/registers";
import { handlerContainer } from "../helpers/handlerContainer";
import { queryRegisterList } from "./queryRegisterList";

import HttpClient from "@src/shared/delivery/application/HttpClient";
import ListBrands from "@src/core/projection/brand/query/listBrands/ListBrands";
import ListColors from "@src/core/projection/color/query/listColors/ListColors";
import GetCurrentEmployee from "@src/core/projection/employee/query/getCurrentEmployee/GetCurrentEmployee";
import IsEmployeeStillLoggedIn from "@src/core/projection/employee/query/isEmployeeStillLoggedIn/IsEmployeeStillLoggedIn";
import ListFamilies from "@src/core/projection/family/query/listFamilies/ListFamilies";
import CanProductBeCreated from "@src/core/projection/fashionLabsProduct/query/canProductBeCreated/CanProductBeCreated";
import CountFashionLabsProductsByCriteria from "@src/core/projection/fashionLabsProduct/query/countFashionLabsProductsByCriteria/CountFashionLabsProductsByCriteria";
import SearchFashionLabsProductsByCriteria from "@src/core/projection/fashionLabsProduct/query/searchFashionLabsProductsByCriteria/SearchFashionLabsProductsByCriteria";
import ViewProductByGroup from "@src/core/projection/fashionLabsProduct/query/viewProductByGroup/ViewProductByGroup";
import ViewProductForImport from "@src/core/projection/fashionLabsProduct/query/viewProductForImport/ViewProductForImport";
import ListFashionModelFittings from "@src/core/projection/fashionModel/query/listFashionModelFittings/ListFashionModelFittings";
import ListFashionModels from "@src/core/projection/fashionModel/query/listFashionModels/ListFashionModels";
import GetFeatures from "@src/core/projection/feature/query/getFeatures/GetFeatures";
import SearchFeaturesByFamily from "@src/core/projection/feature/query/searchFeaturesByFamily/SearchFeaturesByFamily";
import SearchFeatureValuesByFeature from "@src/core/projection/feature/query/searchFeatureValuesByFeature/SearchFeatureValuesByFeature";
import ListMarkets from "@src/core/projection/market/query/listMarkets/ListMarkets";
import DownloadMedia from "@src/core/projection/media/query/downloadMedia/DownloadMedia";
import ListNotifications from "@src/core/projection/notification/query/listNotifications/ListNotifications";
import ViewOrderByOwnerAndStatus from "@src/core/projection/order/query/viewOrderByOwnerAndStatus/ViewOrderByOwnerAndStatus";
import CountProducts from "@src/core/projection/product/query/countProducts/CountProducts";
import ListProducts from "@src/core/projection/product/query/listProducts/ListProducts";
import ListProductsForGroupPartials from "@src/core/projection/product/query/listProductsForGroupPartials/ListProductsForGroupPartials";
import ViewProductById from "@src/core/projection/product/query/viewProductById/ViewProductById";
import ViewProductsForAvailability from "@src/core/projection/product/query/viewProductsForAvailability/ViewProductsForAvailability";
import ViewProductVariantsForVisibility from "@src/core/projection/product/query/viewProductVariantsForVisibility/ViewProductVariantsForVisibility";
import ListSeasons from "@src/core/projection/season/query/listSeasons/ListSeasons";
import ListSizes from "@src/core/projection/size/query/listSizes/ListSizes";
import ListUsers from "@src/core/projection/user/query/listUsers/ListUsers";
import HttpProductView from "@src/core/infrastructure/projection/product/model/HttpProductView";
import { fromStringToSymbol } from "../_data-transfrom/fromStringToSymbol";

interface QueryDependeciesFunctionArgs {
  readonly container: Container;
}
type QueryDependeciesFunctionResult = [Class<Query>, QueryHandler<Query>][];
interface QueryDependeciesFunction {
  (args: QueryDependeciesFunctionArgs): Promise<QueryDependeciesFunctionResult>;
}

const queryDependecies: QueryDependeciesFunction = async ({ container }) => {
  const { listRegister, registerDynamicValue } = registers({ container });
  const handler = handlerContainer({ container });

  registerDynamicValue({
    identifier: "ProductView",
    func: (context) => {
      const httpClient = context.container.get<HttpClient>(fromStringToSymbol("HttpClient"));

      return new HttpProductView(httpClient);
    },
  });

  listRegister(queryRegisterList);

  return Promise.resolve([
    [ListBrands, handler("ListBrandsHandler")],
    [ListColors, handler("ListColorsHandler")],
    [ListSizes, handler("ListSizesHandler")],
    [ListMarkets, handler("ListMarketsHandler")],
    [ListFamilies, handler("ListFamiliesHandler")],
    [ListSeasons, handler("ListSeasonsHandler")],
    [ListNotifications, handler("ListNotificationsHandler")],
    [ListProducts, handler("ListProductsHandler")],
    [ListProductsForGroupPartials, handler("ListProductsForGroupPartialsHandler")],
    [ViewProductById, handler("ViewProductByIdHandler")],
    [CountProducts, handler("CountProductsHandler")],
    [ViewProductForImport, handler("ViewProductForImportHandler")],
    [ListFashionModels, handler("ListFashionModelsHandler")],
    [ListFashionModelFittings, handler("ListFashionModelFittingsHandler")],
    [SearchFeaturesByFamily, handler("SearchFeaturesByFamilyHandler")],
    [GetFeatures, handler("GetFeaturesHandler")],
    [SearchFeatureValuesByFeature, handler("SearchFeatureValuesByFeatureHandler")],
    [SearchFashionLabsProductsByCriteria, handler("SearchFashionLabsProductsByCriteriaHandler")],
    [CountFashionLabsProductsByCriteria, handler("CountFashionLabsProductsByCriteriaHandler")],
    [CanProductBeCreated, handler("CanProductBeCreatedHandler")],
    [ViewProductByGroup, handler("ViewProductByGroupHandler")],
    [ViewProductsForAvailability, handler("ViewProductsForAvailabilityHandler")],
    [ViewProductVariantsForVisibility, handler("ViewProductVariantsForVisibilityHandler")],
    [DownloadMedia, handler("DownloadMediaHandler")],
    [GetCurrentEmployee, handler("GetCurrentEmployeeHandler")],
    [IsEmployeeStillLoggedIn, handler("IsEmployeeStillLoggedInHandler")],
    [ViewOrderByOwnerAndStatus, handler("ViewOrderByOwnerAndStatusHandler")],
    [ListUsers, handler("ListUsersHandler")],
  ] as QueryDependeciesFunctionResult);
};

export { queryDependecies };
