import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import FamilyFeatureValuesCloned from "@src/core/domain/family/model/FamilyFeatureValuesCloned";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";

class CreateNotificationWhenFamilyFeatureValuesCloned implements ProcessManager<FamilyFeatureValuesCloned> {
  private readonly commandBus!: CommandBus;

  public async process(): Promise<void> {
    this.commandBus.dispatch(
      new CreateNotification({
        level: NotificationLevel.SUCCESS,
        content: `Las features values se han clonado correctamente`,
      }),
    );
  }
}

export default CreateNotificationWhenFamilyFeatureValuesCloned;
