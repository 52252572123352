import { AggregateRoot } from "@lookiero/messaging.js";
import FashionLabsModelEdited from "./FashionLabsModelEdited";
import FashionLabsModelCreated from "./FashionLabsModelCreated";

type Attributes = {
  readonly height?: string;
  readonly weight?: string;
  readonly braSize?: string;
  readonly upperSize?: string;
  readonly bottomSize?: string;
  readonly hipOutline?: string;
  readonly chestOutline?: string;
  readonly waistOutline?: string;
  readonly shoes?: string;
};

class FashionLabsModel extends AggregateRoot {
  public modelId: string;
  public modelName: string;
  public attributes: Attributes;

  public constructor(modelId: string, modelName: string, attributes: Attributes) {
    super();
    this.modelId = modelId;
    this.modelName = modelName;
    this.attributes = attributes;
  }
  public static create(modelId: string, modelName: string, attributes: Attributes): FashionLabsModel {
    const instance = new FashionLabsModel(modelId, modelName, attributes);
    instance.record(new FashionLabsModelCreated(instance.modelId));
    return instance;
  }

  public static edit(modelId: string, modelName: string, attributes: Attributes): FashionLabsModel {
    const instance = new FashionLabsModel(modelId, modelName, attributes);
    instance.record(new FashionLabsModelEdited(instance.modelId));

    return instance;
  }
}

export default FashionLabsModel;
