import Order from "@src/core/domain/order/model/Order";

type AddOrderItemDto = {
  readonly orderId: string;
  readonly orderItemId: string;
  readonly quantity: number;
};

const addOrderItemDtoFromOrder = (order: Order): AddOrderItemDto => ({
  orderId: order.orderId,
  orderItemId: order.orderItemId as string,
  quantity: order.quantity as number,
});

export { addOrderItemDtoFromOrder };
