import { AggregateRoot } from "@lookiero/messaging.js";
import { BarcodeEan13 } from "@src/shared/domain/BarcodeEan13";
import FashionLabsProductVariantCreated from "./FashionLabsProductVariantCreated";
import FashionLabsProductVariantEdited from "./FashionLabsProductVariantEdited";
import { SellingPrice } from "@src/shared/domain/price/model/Price";

type ObjectId = {
  id: string;
};

export type ProductVariantFashionModel = ObjectId & {
  sizeId: string;
  fitting: string;
};

export type ProductVariantMediaPerspective = string;

export type ProductVariantMedia = ObjectId & {
  perspective: ProductVariantMediaPerspective;
  url: string;
  commercialUse: boolean;
};

class FashionLabsProductVariant extends AggregateRoot {
  public id: string;
  public productId: string;
  public season: number;
  public publishable: boolean;
  public barcode: BarcodeEan13;
  public reference: string;
  public color: ObjectId;
  public size: ObjectId;
  public media: ProductVariantMedia[];
  public fashionModel: ProductVariantFashionModel;
  public sellingPriceEur: number;
  public sellingPriceGbp: number;
  public sellingPriceSek: number;
  public sellingPriceChf: number;
  public sellingPrices: SellingPrice[];

  public constructor(
    id: string,
    productId: string,
    season: number,
    publishable: boolean,
    barcode: BarcodeEan13,
    reference: string,
    color: ObjectId,
    size: ObjectId,
    media: ProductVariantMedia[],
    fashionModel: ProductVariantFashionModel,
    sellingPriceEur: number,
    sellingPriceGbp: number,
    sellingPriceSek: number,
    sellingPriceChf: number,
    sellingPrices: SellingPrice[],
  ) {
    super();
    this.id = id;
    this.productId = productId;
    this.season = season;
    this.publishable = publishable;
    this.barcode = barcode;
    this.reference = reference;
    this.color = color;
    this.size = size;
    this.media = media;
    this.fashionModel = fashionModel;
    this.sellingPriceEur = sellingPriceEur;
    this.sellingPriceGbp = sellingPriceGbp;
    this.sellingPriceSek = sellingPriceSek;
    this.sellingPriceChf = sellingPriceChf;
    this.sellingPrices = sellingPrices;
  }

  public static create(
    id: string,
    productId: string,
    season: number,
    publishable: boolean,
    barcode: BarcodeEan13,
    reference: string,
    color: ObjectId,
    size: ObjectId,
    media: ProductVariantMedia[],
    fashionModel: ProductVariantFashionModel,
    sellingPriceEur: number,
    sellingPriceGbp: number,
    sellingPriceSek: number,
    sellingPriceChf: number,
    sellingPrices: SellingPrice[],
  ): FashionLabsProductVariant {
    const instance = new FashionLabsProductVariant(
      id,
      productId,
      season,
      publishable,
      barcode,
      reference,
      color,
      size,
      media,
      fashionModel,
      sellingPriceEur,
      sellingPriceGbp,
      sellingPriceSek,
      sellingPriceChf,
      sellingPrices,
    );

    instance.record(new FashionLabsProductVariantCreated(id));

    return instance;
  }

  public edit(
    season: number,
    publishable: boolean,
    barcode: BarcodeEan13,
    reference: string,
    color: ObjectId,
    size: ObjectId,
    media: ProductVariantMedia[],
    fashionModel: ProductVariantFashionModel,
    sellingPriceEur: number,
    sellingPriceGbp: number,
    sellingPriceSek: number,
    sellingPriceChf: number,
  ): void {
    this.season = season;
    this.publishable = publishable;
    this.barcode = barcode;
    this.reference = reference;
    this.color = color;
    this.size = size;
    this.media = media;
    this.fashionModel = fashionModel;
    this.sellingPriceEur = sellingPriceEur;
    this.sellingPriceGbp = sellingPriceGbp;
    this.sellingPriceSek = sellingPriceSek;
    this.sellingPriceChf = sellingPriceChf;

    this.record(new FashionLabsProductVariantEdited(this.productId));
  }
}

export default FashionLabsProductVariant;
