import React from "react";
import Routes from "@src/ui/_routing/routes";
import { Icon, IconVariant, Link, Text } from "@lookiero/react-ui-kit";
import useIsRoute from "@src/ui/component-library/_behaviors/useIsRoute";
import { generatePath, useLocation } from "react-router-dom";
import classNames from "classnames";

import "./fashion-labs-header.css";
import { I18nMessage } from "@lookiero/i18n-react";
import { ROOT_I18N_PREFIX, RootI18n } from "@src/ui/i18n/I18nRootMessages";

interface HeaderMenuItemProps {
  readonly to: string;
  readonly children: React.ReactNode;
}
const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({ to, children }: HeaderMenuItemProps) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      className={classNames("fashion-labs-header__menu-item", {
        "fashion-labs-header__menu-item--active": isActive,
      })}
      to={to}
    >
      {children}
    </Link>
  );
};

type HeaderProps = {
  readonly locale: string;
};

const FashionLabsHeader: React.FC<HeaderProps> = ({ locale }) => {
  const flProductsOverviewPath = generatePath(`/${Routes.FL_PRODUCTS_OVERVIEW}`, { locale });
  const flModelsOverviewPath = generatePath(`/${Routes.FL_MODELS_OVERVIEW}`, { locale });

  const isModelsRoute = useIsRoute(flModelsOverviewPath);
  const isProductsOverviewRoute = useIsRoute(flProductsOverviewPath);

  const isFashionLabsRouter = isModelsRoute || isProductsOverviewRoute;

  return (
    <>
      {isFashionLabsRouter ? (
        <header className="fashion-labs-header">
          <nav className="fashion-labs-header__menu">
            <HeaderMenuItem to={flProductsOverviewPath}>
              <Icon variant={IconVariant.STYLE} />
              <Text>
                <I18nMessage id={RootI18n.HEADER_FASHION_LABS_PRODUCTS} prefix={ROOT_I18N_PREFIX} />
              </Text>
            </HeaderMenuItem>
            <HeaderMenuItem to={flModelsOverviewPath}>
              <Icon variant={IconVariant.WOMAN} />
              <Text>
                <I18nMessage id={RootI18n.HEADER_FASHION_LABS_MODELS} prefix={ROOT_I18N_PREFIX} />
              </Text>
            </HeaderMenuItem>
          </nav>
        </header>
      ) : null}
    </>
  );
};

export default FashionLabsHeader;
