import { CommandHandler } from "@lookiero/messaging.js";
import FashionLabsProduct from "../../model/FashionLabsProduct";
import FashionLabsProductRepository from "../../model/FashionLabsProductRepository";
import CreateFashionLabsProduct from "./CreateFashionLabsProduct";

class CreateFashionLabsProductHandler implements CommandHandler<CreateFashionLabsProduct> {
  private repository: FashionLabsProductRepository;

  public constructor(repository: FashionLabsProductRepository) {
    this.repository = repository;
  }

  public execute({
    productId,
    title,
    note,
    familyId,
    brandId,
    group,
    channels,
    features,
    productModel,
    media,
  }: CreateFashionLabsProduct): Promise<void> {
    const fashionLabsProduct = FashionLabsProduct.createProduct(
      productId,
      title,
      note,
      familyId,
      brandId,
      group,
      channels,
      features,
      productModel,
      media,
    );

    return this.repository.createProduct(fashionLabsProduct);
  }
}

export default CreateFashionLabsProductHandler;
