import MediaView from "@src/core/projection/media/model/MediaView";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";

class HttpMediaView implements MediaView {
  private httpClient: HttpClient;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async download(mediaId: string): Promise<Blob> {
    try {
      const response = await this.httpClient.post("/download-media", { id: mediaId });

      if (!response.ok) {
        throw new Error("descargar media del servicio");
      }

      const blob = await response.blob();

      return blob;
    } catch (error) {
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("descargar media del servicio");
    }
  }
}

export default HttpMediaView;
