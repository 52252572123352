import { Query } from "@lookiero/messaging.js";

class ListProductsForGroupPartials extends Query {
  public readonly partials: string[];

  public constructor(partials: string[]) {
    super();
    this.partials = partials;
  }

  public messageName(): string {
    return "ListProductsForGroupPartials";
  }
}

export default ListProductsForGroupPartials;
