import React, { FC, ReactNode, useEffect } from "react";
import Environment from "@src/core/projection/environment/model/Environment";
import useHotjar from "./useHotjar";

interface HotjarProps {
  readonly children: ReactNode;
  readonly environment: Environment;
}

const Hotjar: FC<HotjarProps> = ({ children, environment }) => {
  const { initialize } = useHotjar({ environment });

  useEffect(() => initialize(), [initialize]);

  return <>{children}</>;
};

export default Hotjar;
