import { QueryHandler } from "@lookiero/messaging.js";
import FashionModelFitting from "../../model/FashionModelFitting";
import FashionModelView from "../../model/FashionModelRepository";
import ListFashionModelFittings from "./ListFashionModelFittings";

class SearchFashionModelFittingsHandler implements QueryHandler<ListFashionModelFittings> {
  private readonly view: FashionModelView;

  public constructor(view: FashionModelView) {
    this.view = view;
  }

  public async handle(): Promise<FashionModelFitting[]> {
    return await this.view.listFittings();
  }
}

export default SearchFashionModelFittingsHandler;
