import React, { Suspense, useCallback } from "react";
import { ReactIntl } from "@lookiero/i18n.js/";
import { MessagingRoot } from "@lookiero/messaging.js";
import { Loader, Text, TextVariant } from "@lookiero/react-ui-kit";
import Logger from "@src/core/application/logger/model/Logger";
import { EnvironmentProvider } from "./_behaviors/useEnvironment";
import Environment from "@src/core/projection/environment/model/Environment";
import ErrorBoundary from "./ErrorBoundary";
import { QueryParametersProvider } from "../component-library/_behaviors/useQueryParameters";
import { ProductSelectionProvider } from "./_behaviors/useProductSelection";
import ErrorReporter from "./errorReporter/ErrorReporter";
import QueryClientRetriever from "./queryClientRetriever/QueryClientRetriever";
import { QueryClient } from "react-query";
import "./root.css";
import { CatalogSelectionProvider } from "./_behaviors/useCatalogSelection";
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "../_routing/Routing";
import EmployeeProvider from "./_behaviors/employeeProvider";
import NotificationsContainer from "./notifications/NotificationsContainer";
import Hotjar from "../tracking/Hotjar";

declare global {
  interface Window {
    missingI18n: string[];
  }
}

type RootProps = {
  readonly MessagingRootComponent: MessagingRoot;
  readonly logger: Logger;
  readonly environment: Environment;
  readonly i18nEndpoint: (locale: string) => string;
  readonly i18nStorage?: Storage;
  readonly queryClientRetriever?: (queryClient: QueryClient) => void;
};

const RootLoader = (
  <div className="root__loader">
    <Loader />
    <Text variant={TextVariant.SMALL_CAPS}>Cargando...</Text>
  </div>
);

const Root: React.FC<RootProps> = ({
  MessagingRootComponent,
  logger,
  environment,
  i18nEndpoint,
  i18nStorage,
  queryClientRetriever,
}: RootProps) => {
  const { defaultLocale } = environment.internationalization;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnI18nError = useCallback(({ descriptor }: { descriptor: any }) => {
    // by default it outputs a console.error
    // Gather all the missing translations and expose them in the window
    window.missingI18n = [...new Set([...(window.missingI18n || []), descriptor ? descriptor.id : "no-descriptor"])];

    return () => (window.missingI18n = []);
  }, []);

  return (
    <div className="root">
      <Router>
        <EnvironmentProvider environment={environment}>
          <ErrorBoundary logger={logger} fallback={ErrorReporter}>
            <ReactIntl.I18n
              locale={defaultLocale}
              endpoint={i18nEndpoint as (locale: string) => string}
              loader={<Loader />}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onError={handleOnI18nError as any}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              storage={i18nStorage as any}
            >
              <MessagingRootComponent>
                <Hotjar environment={environment}>
                  {queryClientRetriever ? <QueryClientRetriever retriever={queryClientRetriever} /> : null}
                  <CatalogSelectionProvider>
                    <ProductSelectionProvider>
                      <QueryParametersProvider>
                        <EmployeeProvider>
                          <Suspense fallback={RootLoader}>
                            <Routing locale={defaultLocale} />
                          </Suspense>
                          <NotificationsContainer />
                        </EmployeeProvider>
                      </QueryParametersProvider>
                    </ProductSelectionProvider>
                  </CatalogSelectionProvider>
                </Hotjar>
              </MessagingRootComponent>
            </ReactIntl.I18n>
          </ErrorBoundary>
        </EnvironmentProvider>
      </Router>
    </div>
  );
};

export default Root;
