import { Command } from "@lookiero/messaging.js";

export type Attributes = {
  readonly height?: string;
  readonly weight?: string;
  readonly braSize?: string;
  readonly upperSize?: string;
  readonly bottomSize?: string;
  readonly hipOutline?: string;
  readonly chestOutline?: string;
  readonly waistOutline?: string;
  readonly shoes?: string;
};

class CreateFashionLabsModel extends Command {
  public readonly modelId: string;
  public readonly modelName: string;
  public readonly attributes: Attributes;

  public constructor(modelId: string, modelName: string, attributes: Attributes) {
    super();
    this.modelId = modelId;
    this.modelName = modelName;
    this.attributes = attributes || undefined;
  }

  public messageName(): string {
    return "CreateFashionLabsModel";
  }
}

export default CreateFashionLabsModel;
