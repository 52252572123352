import { CommandHandler } from "@lookiero/messaging.js";
import OrderRepository from "../../model/OrderRepository";
import PlaceOrder from "./PlaceOrder";
import Order from "../../model/Order";

class PlaceOrderHandler implements CommandHandler<PlaceOrder> {
  private repository: OrderRepository;

  public constructor(repository: OrderRepository) {
    this.repository = repository;
  }

  public async execute({ orderId, userId, orderReference }: PlaceOrder): Promise<void> {
    const instance = Order.placeOrder(orderId, userId, orderReference);
    return this.repository.placeOrder(instance);
  }
}

export default PlaceOrderHandler;
