import { Command } from "@lookiero/messaging.js";

class CreateFeatureValue extends Command {
  public readonly featureValueId: string;
  public readonly featureId: string;
  public readonly value: string;

  constructor({ featureValueId, featureId, value }: { featureValueId: string; featureId: string; value: string }) {
    super();
    this.featureValueId = featureValueId;
    this.featureId = featureId;
    this.value = value;
  }

  public messageName(): string {
    return "CreateFeatureValue";
  }
}

export default CreateFeatureValue;
