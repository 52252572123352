import { CommandHandler } from "@lookiero/messaging.js";
import FamilyRepository from "../../model/FamilyRepository";
import CreateFamily from "./CreateFamily";
import Family from "../../model/Family";

class CreateFamilyHandler implements CommandHandler<CreateFamily> {
  private repository: FamilyRepository;

  public constructor(repository: FamilyRepository) {
    this.repository = repository;
  }

  public async execute(command: CreateFamily): Promise<void> {
    const family = Family.create(command);
    await this.repository.create(family);
  }
}

export default CreateFamilyHandler;
