import { QueryHandler } from "@lookiero/messaging.js";
import FashionLabsProductForImport from "../../model/FashionLabsProductForImport";
import FashionLabsProductView from "../../model/FashionLabsProductView";
import ViewProductForImport from "./ViewProductForImport";

class ViewProductForImportHandler implements QueryHandler<ViewProductForImport> {
  private readonly view: FashionLabsProductView;

  public constructor(view: FashionLabsProductView) {
    this.view = view;
  }

  public async handle({ group }: ViewProductForImport): Promise<FashionLabsProductForImport> {
    return await this.view.byGroupForImport(group);
  }
}

export default ViewProductForImportHandler;
