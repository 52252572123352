import React, { ReactNode } from "react";
import { Hub } from "@lookiero/react-ui-kit";
import useRoutesForEnvironment from "./behaviors/useRoutesForEnvironment";

interface HubProps {
  children?: ReactNode;
}

const RoutedHub: React.FC<HubProps> = ({ children }) => {
  const menuItems = useRoutesForEnvironment();
  return <Hub menuItems={menuItems}>{children}</Hub>;
};

export default RoutedHub;
