import { QueryHandler } from "@lookiero/messaging.js";
import MediaView from "../../model/MediaView";
import DownloadMedia from "./DownloadMedia";

class DownloadMediaHandler implements QueryHandler<DownloadMedia> {
  private readonly view: MediaView;

  public constructor(view: MediaView) {
    this.view = view;
  }

  public handle({ mediaId }: DownloadMedia): Promise<Blob> {
    return this.view.download(mediaId);
  }
}

export default DownloadMediaHandler;
