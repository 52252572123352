import { AggregateRoot } from "@lookiero/messaging.js";
import MediaRemoved from "./MediaRemoved";

class Media extends AggregateRoot {
  public readonly mediaId: string;

  public constructor(mediaId: string) {
    super();
    this.mediaId = mediaId;
  }

  public static fromId(mediaId: string): Media {
    return new Media(mediaId);
  }

  public remove(): void {
    this.record(new MediaRemoved(this.mediaId));
  }
}

export default Media;
