import HttpClient from "@src/shared/delivery/application/HttpClient";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { EventBus } from "@lookiero/messaging.js";
import Authenticator from "@src/core/domain/employee/model/Autheticator";
import Credentials from "@src/core/domain/employee/model/Credentials";
import InvalidCatalogEmployeeError from "@src/core/domain/employee/model/InvalidCatalogEmployeeError";
import EmployeeAuthenticated from "@src/core/domain/employee/model/EmployeeAuthenticated";
import { JwtToken } from "@src/core/domain/employee/model/JwtToken";
import InvalidEmployeeError from "@src/core/domain/employee/model/InvalidEmployeeError";

export interface EmployeeJwtPayload extends JwtPayload {
  roles: string;
}

const CATALOG_ROLE = "OPS_LOGISTICS_CATALOG";
class HttpAuthenticator implements Authenticator {
  private httpClient: HttpClient;
  private authApi: string;
  private eventBus!: EventBus;

  constructor(httpClient: HttpClient, authApi: string) {
    this.httpClient = httpClient;
    this.authApi = authApi;
  }

  private readonly getEmployeeRolesFromAuthenticationToken = (authToken: EmployeeJwtPayload): string[] => {
    return authToken.roles.split(",");
  };

  public async authenticate(credentials: Credentials): Promise<void> {
    const token = await this.doAuthenticate(credentials);
    const roles = this.getRoles(token);
    const isCatalogEmployee = this.checkIsValidRole(roles);

    if (!isCatalogEmployee) {
      throw new InvalidCatalogEmployeeError();
    }
    this.eventBus.publish([new EmployeeAuthenticated({ authToken: token, username: credentials.username, roles })]);
  }

  private async doAuthenticate(credentials: Credentials): Promise<JwtToken> {
    const response = await this.httpClient.post(this.authApi, { ...credentials });
    if (!response.ok) {
      throw new InvalidEmployeeError();
    }
    return await response.text();
  }

  private getRoles(token: JwtToken): string[] {
    const decodedToken = jwtDecode<EmployeeJwtPayload>(token);
    return this.getEmployeeRolesFromAuthenticationToken(decodedToken);
  }

  private checkIsValidRole(roles: string[]): boolean {
    return roles.includes(CATALOG_ROLE);
  }
}

export default HttpAuthenticator;
