class Brand {
  public id: string;
  public origin: string;
  public name: string;

  public constructor(id: string, origin: string, name: string) {
    this.id = id;
    this.origin = origin;
    this.name = name;
  }
}

export default Brand;
