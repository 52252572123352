import { QueryHandler } from "@lookiero/messaging.js";
import ProductsToAvailabilityPreview from "../../model/ProductAvailabilityPreview";
import ProductView from "../../model/ProductView";
import ViewProductsForAvailability from "./ViewProductsForAvailability";

class ViewProductsForAvailabilityHandler implements QueryHandler<ViewProductsForAvailability> {
  private readonly view: ProductView;

  public constructor(view: ProductView) {
    this.view = view;
  }

  public handle(criteria: ViewProductsForAvailability): Promise<ProductsToAvailabilityPreview[]> {
    return this.view.searchByCriteriaToAvailability(criteria);
  }
}

export default ViewProductsForAvailabilityHandler;
