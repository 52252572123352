import { CommandHandler } from "@lookiero/messaging.js";
import FashionLabsModel from "../../model/FashionLabsModel";
import FashionLabsModelRepository from "../../model/FashionLabsModelRepository";
import EditFashionLabsModel from "./EditFashionLabsModel";

class EditFashionLabsModelHandler implements CommandHandler<EditFashionLabsModel> {
  private repository: FashionLabsModelRepository;

  public constructor(repository: FashionLabsModelRepository) {
    this.repository = repository;
  }

  public async execute({ modelId, modelName, attributes }: EditFashionLabsModel): Promise<void> {
    const fashionLabsModel = FashionLabsModel.edit(modelId, modelName, attributes);

    if (!fashionLabsModel) {
      throw new Error(`No hay ningun Modelo con el id ${modelId}`);
    }

    return this.repository.edit(fashionLabsModel);
  }
}

export default EditFashionLabsModelHandler;
