import { CommandHandler } from "@lookiero/messaging.js";
import CreateEmployee from "./CreateEmployee";
import EmployeeRepository from "../../model/EmployeeRepository";
import Employee from "../../model/Employee";

class CreateEmployeeHandler implements CommandHandler<CreateEmployee> {
  private readonly repository: EmployeeRepository;

  constructor(repository: EmployeeRepository) {
    this.repository = repository;
  }

  public async execute(employeeToCreate: CreateEmployee): Promise<void> {
    const employee = Employee.create({ ...employeeToCreate });
    return await this.repository.save(employee);
  }
}

export default CreateEmployeeHandler;
