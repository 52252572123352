/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, useContext, useEffect, useMemo, useRef, ReactNode } from "react";
import { useLocation } from "react-router";
import { QueryParameter, read, produce } from "../../_config/queryParams";
import { useNavigate } from "react-router-dom";

interface QueryParametersProps {
  readonly children: ReactNode;
}

const contextInitialValue: {
  params: void | Record<string, string | string[]>;
} = {
  get params() {
    if (!this.params) {
      throw new Error(
        "Your are trying to use the useQueryParameters hook without wrapping your app with the <QueryParametersProvider>.",
      );
    }
    return this.params;
  },
};

export const useLogIfChanged = <T,>(name: string, value: T): void => {
  const previous = useRef<T>(value);

  const getValue = (value: any): string => {
    if (typeof value === "object") {
      return JSON.stringify(value);
    } else if (value === undefined) {
      return "undefined";
    } else if (value === null) {
      return "null";
    } else {
      return value.toString();
    }
  };

  if (!Object.is(previous.current, value)) {
    console.log(`${name} changed. Old: ${getValue(previous.current)}, New: ${getValue(value)} `);
    previous.current = value;
  }
};

const QueryParametersContext = React.createContext(contextInitialValue);

export const QueryParametersProvider: React.FC<QueryParametersProps> = ({ children }) => {
  const [params, setParamsValue] = useState(read(window.location.search));
  const navigate = useNavigate();
  const setParams = useCallback(
    (paramsToUpdate: Record<string, QueryParameter>) => {
      const newParams = produce(read(window.location.search), paramsToUpdate);
      navigate(`?${new URLSearchParams(newParams).toString()}`);
    },
    [navigate],
  );

  const value = useMemo(() => ({ params, setParams }), [params, setParams]);

  const location = useLocation();
  useEffect(() => {
    setParamsValue(read(window.location.search));
  }, [location]);

  return <QueryParametersContext.Provider value={value}>{children}</QueryParametersContext.Provider>;
};

const useQueryParameters = (): {
  params: Record<string, string | string[]>;
  setParams: (params: Record<string, QueryParameter>) => void;
} =>
  useContext(QueryParametersContext) as unknown as {
    params: Record<string, string | string[]>;
    setParams: (params: Record<string, QueryParameter>) => void;
  };

export default useQueryParameters;
