enum Routes {
  ROOT = "/",
  HOME = ":locale?/",
  LOGIN = "/:locale?/login",
  USER = ":locale?/user",
  PRODUCTS = ":locale?/products",
  AVAILABILITY = "availability",
  VISIBILITY_LITE = "visibility",
  PRODUCT = ":locale?/product/:productId",
  FL_PRODUCTS_OVERVIEW = ":locale?/fl-products",
  FL_PRODUCT = ":locale?/fl-products/:group",
  FL_MODELS_OVERVIEW = ":locale?/fl-models",
  FL_MODEL = ":modelId",
  FL_MODEL_NEW = "new",
  FAMILIES_OVERVIEW = ":locale?/categories/families",
  FAMILY = ":locale?/categories/families/:familyId",
  FAMILY_FEATURE = ":locale?/categories/families/:familyId/feature/:featureId",
  FAMILY_NEW = "new",
  ORDER = ":locale?/order",
  INACCESIBLE_ROLE = ":locale?/inaccesible",
}

export default Routes;
