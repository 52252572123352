import { QueryHandler } from "@lookiero/messaging.js";
import FashionLabsProductPreview from "../../model/FashionLabsProductPreview";
import FashionLabsProductView from "../../model/FashionLabsProductView";
import SearchFashionLabsProductsByCriteria from "./SearchFashionLabsProductsByCriteria";

class SearchFashionLabsProductByCriteriaHandler implements QueryHandler<SearchFashionLabsProductsByCriteria> {
  private readonly view: FashionLabsProductView;

  public constructor(view: FashionLabsProductView) {
    this.view = view;
  }

  public async handle({ criteria }: SearchFashionLabsProductsByCriteria): Promise<FashionLabsProductPreview[]> {
    return this.view.searchByCriteria(criteria);
  }
}

export default SearchFashionLabsProductByCriteriaHandler;
