import { EventBus } from "@lookiero/messaging.js";
import HttpClient from "@src/shared/delivery/application/HttpClient";
import OrderRepository from "@src/core/domain/order/model/OrderRepository";
import Order from "@src/core/domain/order/model/Order";
import { addOrderItemDtoFromOrder } from "./requestBuilder";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";

class HttpOrderRepository implements OrderRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async create(order: Order): Promise<void> {
    try {
      const response = await this.httpClient.post("/create-order", { id: order.orderId });
      if (!response.ok) {
        throw new Error("crear pedido de unsold en el servicio");
      }

      this.eventBus.publish(order.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("crear pedido de unsold en el servicio");
    }
  }

  public async addItem(order: Order): Promise<void> {
    try {
      const addOrderItemDto = addOrderItemDtoFromOrder(order);

      const response = await this.httpClient.post("/add-order-item", addOrderItemDto);

      if (!response.ok) {
        throw new Error("añadir item al pedido de unsold en el servicio");
      }

      this.eventBus.publish(order.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError
        ? error
        : new Error("añadir item al pedido de unsold en el servicio");
    }
  }
  public async removeOrderItem(order: Order): Promise<void> {
    try {
      const response = await this.httpClient.post("/delete-order-item", {
        orderId: order.orderId,
        orderItemId: order.orderItemId,
        quantity: order.quantity,
      });

      if (!response.ok) {
        throw new Error("eliminar el pedido");
      }

      this.eventBus.publish(order.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("eliminar el pedido");
    }
  }
  public async placeOrder(order: Order): Promise<void> {
    try {
      const response = await this.httpClient.post("/place-order", { orderId: order.orderId, userId: order.userId });

      if (!response.ok) {
        throw new Error("registrar pedido de unsold en el servicio");
      }

      this.eventBus.publish(order.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("registrar pedido de unsold en el servicio");
    }
  }
}

export default HttpOrderRepository;
