import { DomainEvent } from "@lookiero/messaging.js";

class FeatureValueCreated extends DomainEvent {
  public featureValueId: string;
  public featureId: string;
  public value: string;

  public constructor({
    featureValueId,
    featureId,
    value,
  }: {
    featureValueId: string;
    featureId: string;
    value: string;
  }) {
    super();
    this.featureValueId = featureValueId;
    this.featureId = featureId;
    this.value = value;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return "FeatureValueCreated";
  }
}

export default FeatureValueCreated;
