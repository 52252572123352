import { CommandHandler } from "@lookiero/messaging.js";
import Logout from "./Logout";
import EmployeeRepository from "../../model/EmployeeRepository";
import Employee from "../../model/Employee";

class LogoutHandler implements CommandHandler<Logout> {
  private readonly repository: EmployeeRepository;

  constructor(repository: EmployeeRepository) {
    this.repository = repository;
  }

  public async execute(): Promise<void> {
    const employee = Employee.logout();
    return this.repository.remove(employee);
  }
}

export default LogoutHandler;
