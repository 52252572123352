import { EventBus } from "@lookiero/messaging.js";
import Visibility from "@src/core/domain/visibility/model/Visibility";
import VisibilityRepository from "@src/core/domain/visibility/model/VisibilityRepository";
import HttpClient from "@src/shared/delivery/application/HttpClient";

class HttpVisibilityRepository implements VisibilityRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async edit(visibility: Visibility): Promise<void> {
    const requestParameters = {
      transaction_id: visibility.transactionId,
      product_variant_ids: visibility.productVariants,
      status: visibility.status,
      reason: visibility.reason,
    };

    const response = await this.httpClient.post("/edit-product-variants-visibility", requestParameters);

    if (!response.ok) {
      return response.text().then((responseText) => {
        const responseJson = JSON.parse(responseText);
        const { description } = responseJson;
        const productVariantIds = description.split("[")[1].split("]")[0].split(", ");
        const productVariantsTexts = productVariantIds
          .map((productVariantId: string) => `<b>${productVariantId}</b></br>`)
          .join("");

        throw new Error(`<p>${productVariantsTexts}</p>`);
      });
    }

    this.eventBus.publish(visibility.recordedEvents());
  }
}

export default HttpVisibilityRepository;
