import { AggregateRoot } from "@lookiero/messaging.js";
import OrderRemoved from "./OrderItemRemoved";
import OrderCreated from "./OrderCreated";
import OrderPlaced from "./OrderPlaced";
import OrderItemAdded from "./OrderItemAdded";

interface OrderProductVariant {
  readonly orderId: string;
  readonly orderItemId?: string;
  readonly quantity?: number;
  readonly userId?: string;
  readonly orderReference?: string;
}

class Order extends AggregateRoot {
  public orderId: string;
  public orderItemId?: string;
  public quantity?: number;
  readonly userId?: string;
  readonly orderReference?: string;

  constructor({ orderId, orderItemId, quantity, userId, orderReference }: OrderProductVariant) {
    super();
    this.orderId = orderId;
    this.orderItemId = orderItemId;
    this.quantity = quantity;
    this.userId = userId;
    this.orderReference = orderReference;
  }

  public static createOrder(orderId: string): Order {
    const instance = new Order({ orderId });
    instance.record(new OrderCreated(orderId));

    return instance;
  }

  public static addOrderItem(orderId: string, orderItemId: string, quantity: number): Order {
    const instance = new Order({ orderId, orderItemId, quantity });
    instance.record(new OrderItemAdded(orderId, orderItemId, quantity));

    return instance;
  }

  public static placeOrder(orderId: string, userId: string, orderReference: string): Order {
    const instance = new Order({ orderId, userId, orderReference });
    instance.record(new OrderPlaced(orderId, userId, orderReference));

    return instance;
  }

  public static removeOrderItem(orderId: string, orderItemId: string, quantity: number): Order {
    const instance = new Order({ orderId, orderItemId, quantity });
    instance.record(new OrderRemoved(orderId, orderItemId, quantity));

    return instance;
  }
}

export default Order;
