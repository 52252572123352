import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import FashionLabsModelCreated from "@src/core/domain/fashionLabsModel/model/FashionLabsModelCreated";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";

class CreateNotificationWhenFashionLabsModelCreated implements ProcessManager<FashionLabsModelCreated> {
  private readonly commandBus!: CommandBus;

  public async process(): Promise<void> {
    this.commandBus.dispatch(
      new CreateNotification({
        level: NotificationLevel.SUCCESS,
        content: `Nueva modelo correctamente añadida`,
      }),
    );
  }
}

export default CreateNotificationWhenFashionLabsModelCreated;
