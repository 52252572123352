import { QueryHandler } from "@lookiero/messaging.js";
import ListUsers from "./ListUsers";
import UserView from "../../model/UserView";
import User from "../../model/User";

class ListUsersHandler implements QueryHandler<ListUsers> {
  private readonly view: UserView;

  public constructor(view: UserView) {
    this.view = view;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: ListUsers): Promise<User[]> {
    return this.view.list();
  }
}

export default ListUsersHandler;
