import { DomainEvent } from "@lookiero/messaging.js";

class FashionLabsProductVariantCreated extends DomainEvent {
  public productVariantId: string;

  public constructor(productVariantId: string) {
    super();
    this.productVariantId = productVariantId;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return "FashionLabsProductVariantCreated";
  }
}

export default FashionLabsProductVariantCreated;
