enum HotjarCommandType {
  EVENT = "event",
  IDENTIFY = "identify",
  STATE_CHANGE = "stateChange",
}

interface HotjarWindow extends Window {
  readonly hotjar?: (command: HotjarCommandType, ...data: unknown[]) => void;
}

declare const window: HotjarWindow;

const hasWindow = typeof window !== "undefined";

const isReady: boolean = hasWindow && !!window.hotjar;

const execute = (command: HotjarCommandType, ...args: unknown[]): void => {
  if (!isReady) {
    throw Error("Hotjar is not available, make sure init has been called.");
  }
  return window.hotjar!(command, ...args);
};

interface AppendScriptFunctionArgs {
  readonly id: string;
  readonly text: string;
  readonly nonce: undefined | string;
}

interface AppendScriptFunction {
  (args: AppendScriptFunctionArgs): boolean;
}

const appendScript: AppendScriptFunction = ({ id, text, nonce = undefined }) => {
  const script = (document.getElementById(id) as HTMLScriptElement) || document.createElement("script");
  Object.assign(script, { id, nonce, innerText: text, crossOrigin: "anonymous" });

  if (!document.getElementById(id)) {
    document.head.appendChild(script);
  }

  return document.head.contains(script);
};

interface InitOpts {
  readonly debug?: boolean;
  readonly nonce?: string;
}

interface InitScriptFunctionArgs {
  readonly id: number;
  readonly version: number;
  opts?: InitOpts;
}

interface InitScriptFunction {
  (args: InitScriptFunctionArgs): void;
}

const initScript: InitScriptFunction = ({ id, version, opts }): void => {
  const isDebug = opts?.debug || false;

  const hotjarScriptCode = `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${id},hjsv:${version},hjdebug:${isDebug}};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  const isAppended = appendScript({ text: hotjarScriptCode, id: "hotjar-init-script", nonce: opts?.nonce });

  if (!isAppended) {
    throw Error("Failed to initialize Hotjar tracking script.");
  }
};

export { initScript, execute, HotjarCommandType };
