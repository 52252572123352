import { Command } from "@lookiero/messaging.js";

class EditVisibility extends Command {
  public readonly transactionId: string;
  public readonly productVariants: string[];
  public readonly visibility: string;
  public readonly reason: string;

  public constructor(transactionId: string, productVariants: string[], visibility: string, reason: string) {
    super();
    this.transactionId = transactionId;
    this.productVariants = productVariants;
    this.visibility = visibility;
    this.reason = reason;
  }

  public messageName(): string {
    return "EditVisibility";
  }
}

export default EditVisibility;
