import { QueryHandler } from "@lookiero/messaging.js";
import ProductAndGroup from "../../model/ProductAndGroup";
import ProductView from "../../model/ProductView";
import ListProductsForProperty from "./ListProductsForGroupPartials";

class ListProductsForGroupPartialsHandler implements QueryHandler<ListProductsForProperty> {
  private readonly view: ProductView;

  public constructor(view: ProductView) {
    this.view = view;
  }

  public handle({ partials }: ListProductsForProperty): Promise<Record<string, ProductAndGroup[]>> {
    return this.view.listForGroupPartials(partials);
  }
}

export default ListProductsForGroupPartialsHandler;
