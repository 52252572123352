import { Query } from "@lookiero/messaging.js";

class DownloadMedia extends Query {
  public mediaId: string;

  public constructor(mediaId: string) {
    super();
    this.mediaId = mediaId;
  }

  public messageName(): string {
    return "DownloadMedia";
  }
}

export default DownloadMedia;
