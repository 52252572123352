import { Query } from "@lookiero/messaging.js";

class ViewProductByGroup extends Query {
  public readonly group: string;

  public constructor(group: string) {
    super();
    this.group = group;
  }

  public messageName(): string {
    return "ViewProductByGroup";
  }
}

export default ViewProductByGroup;
