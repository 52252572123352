import Market from "@src/core/projection/market/model/Market";
import MarketView from "@src/core/projection/market/model/MarketView";
import UnAuthorizedEmployeeError from "@src/shared/domain/employee/model/UnAuthorizedEmployeeError";
import HttpClient from "../../../../../shared/delivery/application/HttpClient";

class HttpMarketView implements MarketView {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<Market[]> {
    try {
      const response = await this.httpClient.post("/search-markets", { per_page: -1 });

      if (!response.ok) {
        throw new Error("obtener los mercados del servicio");
      }

      const { result } = await response.json();
      return result;
    } catch (error) {
      throw error instanceof UnAuthorizedEmployeeError ? error : new Error("obtener los mercados del servicio");
    }
  }
}

export default HttpMarketView;
