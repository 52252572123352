import { QueryHandler } from "@lookiero/messaging.js";
import FashionLabsProduct from "../../model/FashionLabsProduct";
import FashionLabsProductView from "../../model/FashionLabsProductView";
import ViewProductByGroup from "./ViewProductByGroup";

class ViewProductByGroupHandler implements QueryHandler<ViewProductByGroup> {
  private readonly view: FashionLabsProductView;

  public constructor(view: FashionLabsProductView) {
    this.view = view;
  }

  public async handle({ group }: ViewProductByGroup): Promise<FashionLabsProduct | undefined> {
    return await this.view.byGroup(group);
  }
}

export default ViewProductByGroupHandler;
