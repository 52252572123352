import { CommandHandler } from "@lookiero/messaging.js";
import OrderRepository from "../../model/OrderRepository";
import AddOrderItem from "./AddOrderItem";
import Order from "../../model/Order";

class AddOrderItemHandler implements CommandHandler<AddOrderItem> {
  private repository: OrderRepository;

  public constructor(repository: OrderRepository) {
    this.repository = repository;
  }

  public async execute({ orderId, orderItemId, quantity }: AddOrderItem): Promise<void> {
    const addInstance = Order.addOrderItem(orderId, orderItemId, quantity);
    return this.repository.addItem(addInstance);
  }
}

export default AddOrderItemHandler;
