import * as Sentry from "@sentry/browser";
import { SeverityLevel } from "@sentry/types";
import Logger from "@src/core/application/logger/model/Logger";
import LoggerDomainEvent from "@src/core/application/logger/model/LoggerDomainEvent";
import LoggerError from "@src/core/application/logger/model/LoggerError";

const SENTRY_LOGGER_LAYER = "layer";

interface SentryLoggerParameters {
  readonly environment: string;
  readonly release: string;
  readonly publicKey: string;
  readonly projectId: string;
}

class SentryLogger implements Logger {
  private readonly release: string;

  public constructor({ environment, release, publicKey, projectId }: SentryLoggerParameters) {
    this.release = release;

    if (environment && release && publicKey && projectId) {
      Sentry.init({
        environment,
        release,
        dsn: `https://${publicKey}@sentry.io/${projectId}`,
        defaultIntegrations: false,
      });
    }
  }

  public error({ layer, error, info }: LoggerError): string {
    Sentry.setTag(SENTRY_LOGGER_LAYER, layer);
    if (info) {
      Sentry.setExtras(info);
    }
    const eventId = Sentry.captureException(error);

    return eventId;
  }

  public event({ layer, event }: LoggerDomainEvent): void {
    Sentry.captureEvent({
      release: this.release,
      event_id: event.id(),
      level: "info" as SeverityLevel,
      timestamp: event.ocurredOn() / 1000,
      message: event.name(),
      tags: {
        [SENTRY_LOGGER_LAYER]: layer,
      },
      // TODO: remove any usage
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      extra: event as any,
    });
  }

  public report(id: string): void {
    Sentry.showReportDialog({ eventId: id });
  }
}

export default SentryLogger;
