import { CommandHandler } from "@lookiero/messaging.js";
import Media from "../../model/Media";
import MediaRepository from "../../model/MediaRepository";
import RemoveMedia from "./RemoveMedia";

class RemoveMediaHandler implements CommandHandler<RemoveMedia> {
  private repository: MediaRepository;

  public constructor(repository: MediaRepository) {
    this.repository = repository;
  }

  public execute({ mediaId }: RemoveMedia): Promise<void> {
    const media = new Media(mediaId);

    return this.repository.remove(media);
  }
}

export default RemoveMediaHandler;
