import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";
import OrderCreated from "@src/core/domain/order/model/OrderCreated";

class CreateNotificationWhenOrderCreated implements ProcessManager<OrderCreated> {
  private readonly commandBus!: CommandBus;

  public async process(): Promise<void> {
    this.commandBus.dispatch(
      new CreateNotification({
        level: NotificationLevel.SUCCESS,
        content: "Pedido creado correctamente",
      }),
    );
  }
}

export default CreateNotificationWhenOrderCreated;
