import { CommandHandler } from "@lookiero/messaging.js";
import Authenticator from "../../model/Autheticator";
import EmployeeRepository from "../../model/EmployeeRepository";
import Login from "./Login";

class LoginHandler implements CommandHandler<Login> {
  private readonly repository: EmployeeRepository;
  private readonly authenticator: Authenticator;

  constructor(repository: EmployeeRepository, authenticator: Authenticator) {
    this.repository = repository;
    this.authenticator = authenticator;
  }

  public async execute({ credentials }: Login): Promise<void> {
    return await this.authenticator.authenticate(credentials);
  }
}

export default LoginHandler;
