import { CommandHandler } from "@lookiero/messaging.js";
import CreateFeatureValue from "./CreateFeatureValue";
import FeatureValueRepository from "../../model/FeatureValueRepository";
import FeatureValue from "../../model/FeatureValue";

class CreateFeatureValueHandler implements CommandHandler<CreateFeatureValue> {
  private repository: FeatureValueRepository;

  constructor(repository: FeatureValueRepository) {
    this.repository = repository;
  }

  public execute(command: CreateFeatureValue): Promise<void> {
    const featureValue = new FeatureValue(command);
    this.repository.create(featureValue);

    return Promise.resolve();
  }
}

export default CreateFeatureValueHandler;
